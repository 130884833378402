<template>
	<button type="button" @click="onSelect(closer)"
		:class="['btn', isSelected ? `btn-${color}` : `btn-outline-${color}`]">
		{{ closerName }}
		<br>
		<span class="span-tipo">
			{{ tipo }}
		</span>
	</button>
</template>
<script>
export default {
	name: "CloserItem",
	props: {
		closer: {
			type: Object,
			required: true
		},
		onSelect: {
			type: Function,
			default: () => { }
		},
		isSelected: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		closerName() {
			const names = this.closer.nome.split(' ');

			return names.length > 1 ? `${names[0]} ${names[names.length - 1]}` : names[0];
		},
		tipo() {
			return this.closer.tipo === 1 ? 'INTERNO' : 'REPRESENTANTE';
		},
		color() {
			return this.closer.tipo === 1 ? 'primary' : 'dark';
		}
	}

}
</script>
<style lang="scss" scoped>
.btn {
	width: 180px;
	text-transform: uppercase;
	padding: 10px;
	font-size: 12px;

	.span-tipo {
		font-size: 9px;
		font-weight: bold !important;
	}
}
</style>