<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <nenhum-registro-encontrado :hidden="series.length > 0" :height="200" />
      <div class="chart" :hidden="series.length == 0">
        <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import NenhumRegistroEncontrado from "@/components/NenhumRegistroEncontrado.vue";
import { randomInt } from "@/utils/number";
import Chart from "chart.js/auto";
import * as uuid from 'uuid';
import api from "@/services/api";
import moment from "moment";

export default {
  name: "VendaTipoFaturamentoCard",
  components: {
    NenhumRegistroEncontrado,
  },
  props: {
    title: {
      type: String,
      default: "Sales by Age",
    },
    mes: {
      type: [String, Date],
      default: "",
    },
    tipo: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.chartId = `chart-pie_${uuid.v4()}`;
  },
  data() {
    return {
      chartId: "chart-pie",
      series: {
        type: Array,
        default: () => [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const mes = moment(this.mes).format('YYYY-MM-DD');

      api.get(`/metas/tipo-faturamento-closer`, {
        params: {
          mes: mes,
          tipo: this.tipo,
        },
      }).then((response) => {
        this.series = response.data;
        this.creatChart();
      });

    },
    creatChart() {

      const random = randomInt(100, 300);

      setTimeout(() => {
        var ctx = document.getElementById(this.chartId).getContext("2d");

        let chartStatus = Chart.getChart(this.chartId);

        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

        const labels = this.series.map((item) => this.filters.firstAndLastName(item.label));
        const colors = this.series.map((serie, index) => this.getSerieColor(index));
        const data = this.series.map((item) => Number(item.value));

        new Chart(ctx, {
          type: "bar",
          data: {
            labels,
            datasets: [
              {
                label: "Vendas",
                data,
                weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: colors,
                fill: false,
                maxBarThickness: 35,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#9ca2b7",
                  padding: 10,
                },
              },
            },
          },
        });
      }, random);
    },
    getSerieColor(index) {
      const colors = ['#5e72e4', '#11cdef', '#2dce89', '#FBD38D', '#344767', '#f5365c'];

      return `${colors[index]}`;
    },
  },
  watch: {
    mes: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
