<template>
    <div id="modal-selecionar-implantador" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog mt-lg-10  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">
                        Selecionar Implantador
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>
                                Pesquisar
                            </label>
                            <input class="form-control text-uppercase" v-model="searchImplantadores"
                                placeholder="ID, Nome">
                        </div>
                    </div>
                    <div class="table-pesquisa">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Nome
                                    </th>
                                    <th>
                                        Ação
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="implantador of implantadoresFiltered" :key="implantador.id">
                                    <td>
                                        <span class="my-2 text-xs">
                                            {{ implantador.id ?? '***' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="my-4 text-xs text-bold">
                                            {{ implantador.nome.toUpperCase().substr(0, 30) }}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary btn-xs"
                                            @click="selectImplantador(implantador)" data-bs-dismiss="modal">
                                            Selecionar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api';

export default {
    name: "ModalSelecionarImplantador",
    props: {
        onSelectImplantador: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            searchImplantadores: '',
            implantadores: [],
            implantadoresFiltered: [],
        }
    },
    mounted() {
        this.fetchImplantadores();
    },
    methods: {
        clear() {
            this.searchImplantadores = '';
        },
        fetchImplantadores() {
            api.get(`/implantadores`)
                .then(({ data }) => {
                    this.implantadores = data;
                    this.implantadoresFiltered = data;
                })
                .catch(e => console.log(e));
        },
        filterImplantadores() {
            const search = this.searchImplantadores.toLowerCase();

            let implantadores = [
                ...this.implantadores
            ];

            if (search) {
                implantadores = implantadores.filter(item =>                    
                    (item.nome ?? '').toLowerCase().indexOf(search) > -1
                );
            }

            this.implantadoresFiltered = implantadores;
        },
        selectImplantador(implantador) {
            this.onSelectImplantador(implantador);
        }
    },
    watch: {
        searchImplantadores: function () {
            this.filterImplantadores();
        }
    }

}
</script>
<style lang="scss" scoped>
.table-pesquisa {
    height: 400px;
    max-height: 400px;
    overflow: scroll;
}
</style>