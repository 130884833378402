<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Upsells</h5>
              </div>
              <!-- <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Upsell', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      +&nbsp; Novo Upsell
                    </button>
                  </router-link>                  
                </div>
              </div> -->
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="upsells-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Cliente</th>
                    <th class="text-center">Data</th>
                    <th class="text-center">Operação</th>
                    <th>Closer</th>
                    <th class="text-right">Valor</th>
                    <th class="text-right">Valor Consultoria</th>
                    <th class="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="upsell of upsells" :key="upsell.id">
                    <td>
                      <span class="my-2 text-xs text-uppercase text-bold">
                        {{ upsell.cliente ?  upsell.cliente.nomeFantasia ?? upsell.cliente.razaoSocial : 'Cliente não localizado'}}
                      </span>
                    </td>
                    <td class="text-uppercase text-center">
                      <span class="my-2 text-sm text-bold text-dark">
                        {{ filters.formatDate(upsell.data) }}
                      </span>
                    </td>
                    <td class="text-center">
                      <badge-operacao :operacao="upsell.operacao" />
                    </td>
                    <td>
                      <span class="my-2 text-xs text-bold text-dark text-bold">
                        {{ filters.firstAndLastName(upsell.closer?.nome) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-bold text-dark text-bold">
                        {{ filters.currency(upsell.valor) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="my-2 text-sm text-bold text-dark text-bold">
                        {{ filters.currency(upsell.valorConsultoria) }}
                      </span>
                    </td>
                    <td class="text-center">
                      <router-link :to="{
                        name: 'Upsell', params: {
                          id: upsell.id
                        }
                      }" class="btn btn-xs btn-primary">
                        Editar
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeOperacao from "./../components/BadgeOperacao.vue";
import api from '@/services/api'

export default {
  name: "Upsells",
  components: {
    BadgeOperacao
  },
  data() {
    return {
      upsells: [],
      upsellsFiltered: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/upsells`)
        .then(response => {
          const { data } = response;

          this.upsells = data;
          this.upsellsFiltered = data;
        })
        .catch(e => console.log(e));
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `upsells-${this.vertical}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    },
    filterModules() {
      const { status, search } = this;

      let items = [
        ...this.upsells
      ];

      if (status != 'T') {
        items = this.upsells.filter(item => status == 'A' ? item.ativo : !item.ativo);
      }

      if (search) {
        items = items.filter(item => item.search.toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      this.upsellsFiltered = items;

    }
  },
  watch: {
    search: function () {
      this.filterModules();
    }
  }
};
</script>
../../../services/api