<template>
  <span :class="['badge', `badge-${size}`, 'badge-primary']">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeOrigemCancelamento",
  props: {
    origem: {
      type: [String, Number],
      default: 0
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
      origens: [
        'NÃO INFORMADO',
        'SOLICITAÇÃO',
        'INADIMPLÊNCIA',
      ]
    }
  },
  computed: {
    label() {
      return isNaN(this.origem) ? this.origem : this.origens[this.origem];
    }
  }
}
</script>
