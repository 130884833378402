<template>
  <button :class="['btn', selected ? 'btn-primary' : 'btn-outline-primary', 'btn-comissao-closer']">
    <div class="d-flex justify-content-between align-items-center">
      <p class="text-sm mb-1 text-uppercase font-weight-bold">
        {{ filters.firstAndLastName(closer.nome) }}
      </p>
      <span class="font-weight-bold text-xs">
        {{ closer.vendas.percentualMeta }}%
        <!-- {{ filters.thousandsToInt(closer.vendas.total) }} -->
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: "ComissaoCloserCard",
  props: {
    closer: {
      type: Object,
      default: () => { },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-comissao-closer {
  margin-right: 8px;
  padding: 1em;
  width: 200px;
  height: 48px;
  line-height: 48px;
}
</style>