<template>
  <div class="card my-2 mx-2">
    <div class="pt-4 pb-3 text-center card-header">
      <span class="text-uppercase font-weight-bold text-dark overflow-text">
        {{ modulo.descricao }}
      </span>
      <p class="mb-0 text-sm text-muted overflow-text">
        <small>
          {{ modulo.modulo }}
        </small>
      </p>
    </div>
    <div class="pt-0 text-center card-body">
      <div class="d-flex justify-content-between">
        <label>Status</label>
        <badge-status :ativo="modulo.ativo" />
      </div>
      <hr class="mt-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Valor Mínimo</label>
        <h6 class="mb-0 font-weight-bolder">
          {{ filters.currency(modulo.valorMinimo) }}
        </h6>
      </div>
      <hr class="mt-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Clientes Ativos</label>
        <h6 class="mb-0 font-weight-bolder">
          {{ modulo.clientes ?? 0 }}
        </h6>
      </div>
      <hr class="mt-2 mb-4 horizontal dark">
      <router-link :to="{
          name: 'Modulo', params: {
            id: modulo.id
          }
        }" class="mt-3 mb-0 btn btn-md btn-icon d-lg-block bg-dark text-white">
        Editar
        <!-- <i class="fas fa-arrow-right ms-1"></i> -->
      </router-link>

    </div>
  </div>
</template>

<script>
import BadgeStatus from "./BadgeStatus.vue";
export default {
  name: "ModuloCard",
  components: {
    BadgeStatus,
  },
  props: {
    modulo: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {
      specifications: [
        {
          label: 'Java',
          includes: true
        }
      ]
    }
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
