<template>
    <div class="row">
        <div class="col-md-8">
            <label>Pesquisar</label>
            <input class="form-control" v-model="value" :placeholder="placeholder ?? 'Pesquisar módulo'">
        </div>
        <div class="col-md-4">
            <label>&nbsp;</label>
            <div class="d-flex justify-content-between">
                <argon-button color="success" size="md" :full-width="true"
                    :variant="status == 'A' ? 'contained' : 'outline'" class="me-2" @click="onClick('A')">
                    Ativos
                </argon-button>
                <argon-button color="danger" size="md" :full-width="true" :variant="status == 'I' ? 'contained' : 'outline'"
                    class="me-2" @click="onClick('I')">
                    Inativos
                </argon-button>
                <argon-button color="primary" size="md" :full-width="true"
                    :variant="status == 'T' ? 'contained' : 'outline'" @click="onClick('T')">
                    Todos
                </argon-button>
            </div>
        </div>
    </div>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    emits: ['update:modelValue'],
    components: {
        ArgonButton
    },
    props: {
        placeholder: {
            type: String,
            default: null
        },
        setStatus: {
            type: Function,
            required: true
        },
        status: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: ''
        },
    },
    methods: {
        onClick(value) {
            this.setStatus(value);
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
<style lang="">
    
</style>