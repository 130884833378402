<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <form role="form" @submit.prevent="onSubmit">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div>
                  <h6 class="mb-0">{{ id == 'new' ? 'Nova soliticação' : 'Editar soliticação' }}</h6>
                  <p class="mb-0 text-sm">Criar/Editar solicitação de cancelamento</p>
                </div>
                <argon-button type="button" v-if="allowDelete" @click="deleteCancelamento()" color="danger" size="xs"
                  variant="outline" class="my-4 mb-2">
                  EXCLUIR
                </argon-button>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-1">
                <div class="col-md-8">
                  <label>Cliente</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-group-disabled" disabled placeholder="Selecionar..."
                      :value="clienteLabel" />
                    <button type="button" class="input-group-text" data-bs-toggle="modal"
                      data-bs-target="#modal-selecionar-cliente" @click="openModalSelecionarCliente()">
                      Selecionar &nbsp; <i class="fas fa-users" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.cliente.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>Data da Solicitação</label>
                  <datepicker v-model="v$.cancelamento.data.$model" locale="pt-BR" :month-change-on-scroll="false"
                    format="dd/MM/yyyy" auto-apply :enable-time-picker="false" />
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.data.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>Data Ult. Contato</label>
                  <datepicker v-model="v$.cancelamento.dataUltimoContato.$model" locale="pt-BR"
                    :month-change-on-scroll="false" format="dd/MM/yyyy" auto-apply :enable-time-picker="false" />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4">
                  <label>Motivo</label>
                  <select class="form-control" v-model="v$.cancelamento.motivoCancelamento.$model">
                    <option v-for="(motivo, index) of motivosCancalamento" :value="motivo.id" :key="index">
                      {{ motivo.descricao }}
                    </option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.motivoCancelamento.$errors"
                    :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>Origem</label>
                  <select class="form-control" v-model="v$.cancelamento.origemCancelamento.$model">
                    <option v-for="(origem, index) of origensCancelamento" :value="origem.id" :key="index">
                      {{ origem.descricao }}
                    </option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.origemCancelamento.$errors"
                    :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>Status</label>
                  <select class="form-control" v-model="v$.cancelamento.status.$model" disabled>
                    <option v-for="(status, index) of statusCancelamento" :value="status.id" :key="index">
                      {{ status.descricao }}
                    </option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.status.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-4">
                  <label>Solicitante</label>
                  <input type="text" class="form-control text-uppercase" placeholder="Solicitante"
                    v-model="v$.cancelamento.solicitante.$model" />
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.solicitante.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>Telefone</label>
                  <input type="tel" class="form-control" placeholder="(00) 00000-0000" aria-label="Telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']" v-model="v$.cancelamento.telefone.$model" />
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.telefone.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>Em Uso</label>
                  <select class="form-control" v-model="v$.cancelamento.emUso.$model">
                    <option :value="true">SIM</option>
                    <option :value="false">NÃO</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label>Depende de nós</label>
                  <select class="form-control" v-model="v$.cancelamento.dependeDeNos.$model">
                    <option :value="true">SIM</option>
                    <option :value="false">NÃO</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>Implantador</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-group-disabled" disabled placeholder="Selecionar..."
                      :value="implantadorLabel" />
                    <button type="button" class="input-group-text" data-bs-toggle="modal"
                      data-bs-target="#modal-selecionar-implantador" @click="openModalSelecionarImplantador()">
                      Selecionar &nbsp; <i class="fas fa-users" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.implantador.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>CS Responsável</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-group-disabled" disabled placeholder="Selecionar..."
                      :value="customerSuccessLabel" />
                    <button type="button" class="input-group-text" data-bs-toggle="modal"
                      data-bs-target="#modal-selecionar-customer-success" @click="openModalSelecionarCustomerSuccess()">
                      Selecionar &nbsp; <i class="fas fa-users" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.customerSuccess.$errors"
                    :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-12">
                  <label for="">Observações:</label>
                  <textarea cols="30" class="form-control text-bold" rows="5"
                    v-model="v$.cancelamento.observacoes.$model" style="resize: none;"></textarea>
                  <div class="input-errors" v-for="(error, index) of v$.cancelamento.observacoes.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <back-button />
                <submit-button />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <modal-selecionar-cliente @select-cliente="onSelectCliente" ref="modalSelecionarCliente" />
    <modal-selecionar-implantador @select-implantador="onSelectImplantador" ref="modalSelecionarImplantador" />
    <modal-selecionar-customer-success @select-customer-success="onSelectCustomerSuccess"
      ref="modalSelecionarCustomerSuccess" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import { successToast, errorToast, showConfirmWithValidatorText } from '@/utils/alerts';
import Datepicker from '@vuepic/vue-datepicker';
import api from '@/services/api'
import moment from 'moment';
import ModalSelecionarCliente from '../components/ModalSelecionarCliente.vue';
import ModalSelecionarImplantador from '../components/ModalSelecionarImplantador.vue';
import ModalSelecionarCustomerSuccess from '../components/ModalSelecionarCustomerSuccess.vue';
import BackButton from '@/components/BackButton.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import ArgonButton from '@/components/ArgonButton.vue';

export default {
  name: "Cancelamento",
  components: {
    ArgonButton,
    SubmitButton,
    BackButton,
    Datepicker,
    ModalSelecionarCliente,
    ModalSelecionarImplantador,
    ModalSelecionarCustomerSuccess,
  },
  created() {
    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  mounted() {
    this.user = this.$store.getters.getUser;
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      user: null,
      clientes: [],
      origensCancelamento: [
        { id: 0, descricao: 'SELECIONE A ORIGEM' },
        { id: 1, descricao: 'SOLICITAÇÃO' },
        { id: 2, descricao: 'INADIMPLÊNCIA' },
        { id: 3, descricao: 'ALTERAÇÃO CONTRATUAL' },
      ],
      motivosCancalamento: [
        { id: 0, descricao: 'SELECIONE O MOTIVO' },
        { id: 1, descricao: 'PRODUTO' },
        { id: 2, descricao: 'CLIENTE' },
        { id: 3, descricao: 'FINANCEIRO' },
        { id: 4, descricao: 'COMERCIAL' },
        { id: 5, descricao: 'SUPORTE' },
        { id: 6, descricao: 'CONSULTORIA' },
      ],
      statusCancelamento: [
        { id: null, descricao: 'SELECIONE O STATUS' },
        { id: 0, descricao: 'NOVA SOLICITAÇÃO' },
        { id: 1, descricao: 'EM TRATATIVA' },
        { id: 2, descricao: 'AGUARD. DISTRATO' },
        { id: 3, descricao: 'REVERTIDO' },
        { id: 4, descricao: 'CANCELADO' },
      ],
      cancelamento: {
        id: null,
        cliente: null,
        data: moment().toDate(),
        solicitante: '',
        origemCancelamento: null,
        motivoCancelamento: null,
        dataUltimoContato: null,
        telefone: '',
        observacoes: '',
        emUso: true,
        dependeDeNos: true,
        implantador: null,
        customerSuccess: null,
        status: 0,
      }
    }
  },
  validations() {
    return {
      cancelamento: {
        data: {
          required: helpers.withMessage('Data é obrigatória', required),
        },
        cliente: {
          required: helpers.withMessage('Cliente é obrigatório', required),
        },
        solicitante: {
          required: helpers.withMessage('Solicitante é obrigatório', required),
        },
        origemCancelamento: {
          required: helpers.withMessage('Origem é obrigatória', minValue(1)),
        },
        motivoCancelamento: {
          required: helpers.withMessage('Motivo é obrigatório', minValue(1)),
        },
        dataUltimoContato: {},
        telefone: {
          required: helpers.withMessage('Telefone é obrigatório', required),
        },
        observacoes: {
          required: helpers.withMessage('Observações é obrigatória', required),
        },
        emUso: {},
        dependeDeNos: {},
        implantador: {
          required: helpers.withMessage('Implantador é obrigatório', required),
        },
        customerSuccess: {
          required: helpers.withMessage('Customer Success é obrigatório', required),
        },
        status: {},
      },
    }
  },
  methods: {
    openModalSelecionarCliente() {
      this.$refs.modalSelecionarCliente.clear();
    },
    openModalSelecionarImplantador() {
      this.$refs.modalSelecionarImplantador.clear();
    },
    openModalSelecionarCustomerSuccess() {
      this.$refs.modalSelecionarCustomerSuccess.clear();
    },
    fetchData() {
      const { id } = this;

      api.get(`/cancelamentos/${id}`)
        .then(({ data }) => {

          this.cancelamento = data;

        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      const { data } = this.cancelamento

      if (moment(data).isAfter(moment())) {
        errorToast('Data da solicitação não pode ser maior que a data atual.');
        return;
      }

      try {
        const payload = {
          ...this.cancelamento
        }

        await (this.id == 'new' ?
          api.post('/cancelamentos', payload) :
          api.patch(`/cancelamentos/${this.id}`, payload));

        successToast('Solicitação de cancelamento salva com sucesso.');

        console.log('this.$router', this.$router);
        this.$router.go(-1);

      } catch (e) {
        const { data } = e.response;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
    onSelectCliente(cliente) {
      this.cancelamento.cliente = cliente;
    },
    onSelectImplantador(implantador) {
      this.cancelamento.implantador = implantador;
    },
    onSelectCustomerSuccess(customerSuccess) {
      this.cancelamento.customerSuccess = customerSuccess;
    },
    async deleteCancelamento() {
      const confirm = await showConfirmWithValidatorText(
        'Deseja realmente excluir essa solicitação de cancelamento? Essa ação não poderá ser desfeita!',
        'Atenção');

      if (!confirm) {
        return;
      }

      try {
        await api.delete(`/cancelamentos/${this.id}`);
        successToast('Solicitação de cancelamento excluída com sucesso.');
        this.$router.go(-1);
      } catch (e) {
        const { message } = e.data;
        errorToast(message)

      }
    }
  },
  computed: {
    clienteLabel() {
      const { cliente } = this.cancelamento;
      const nomeFantasia = cliente ? cliente.nomeFantasia : '';
      const razaoSocial = cliente ? cliente.razaoSocial : '';

      return (nomeFantasia || razaoSocial).toUpperCase();
    },
    implantadorLabel() {
      const { implantador } = this.cancelamento;

      return implantador ? implantador.nome.toUpperCase() : '';
    },
    customerSuccessLabel() {
      const { customerSuccess } = this.cancelamento;

      return customerSuccess ? customerSuccess.nome.toUpperCase() : '';
    },
    allowDelete() {
      return this.cancelamento.id > 0 && this.cancelamento.status == 0 && this.user?.tipo == 2;
    }
  }
};
</script>
