<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-4 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Planos</h5>
                <p class="mb-0 text-sm">
                  Planos Disponíveis
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{
                    name: 'Plano', params: {
                      id: 'new'
                    }
                  }" class="mt-1 mb-0 btn btn-outline-success btn-sm mt-sm-0">
                    +&nbsp; Novo Plano
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-2 card-body">
            <div v-if="planos.length == 0">
              <h4 class="mt-2 font-weight-bold">
                Nenhum Plano Cadastrado
              </h4>
            </div>            
            <div class="row" v-else>
              <div v-for="plano in planos" class="mb-4 col-lg-3 col-md-3 col-sm-6 mb-lg-0" :key="plano.id">
                <plano-card :plano="plano" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanoCard from "./components/PlanoCard.vue";
import api from './../../services/api';

export default {
  name: "Planos",
  components: {
    PlanoCard
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      planos: [],
    }
  },
  methods: {
    fetchData() {

      api.get(`/planos`).then(response => {
        const { data } = response;

        this.planos = data;

      }).catch(e => console.log(e));
    },
  },
};
</script>