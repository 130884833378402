<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Clientes Ativos" :value="clientes.length" :icon="{
          component: 'fas fa-check',
          background: 'bg-gradient-primary',
          shape: 'rounded-circle'
        }" />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Ticket Médio" :value="filters.currencyWithPrefix(ticketMedio)" :icon="{
          component: 'fas fa-coins',
          background: 'bg-gradient-info',
          shape: 'rounded-circle'
        }" />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Maior Ticket" :value="filters.currencyWithPrefix(maiorTicket)" :icon="{
          component: 'fas fa-money-bill-alt',
          background: 'bg-gradient-success',
          shape: 'rounded-circle'
        }" :has-details="true" data-bs-toggle="modal" data-bs-target="#modal-maior-ticket" />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="Menor Ticket" :value="filters.currencyWithPrefix(menorTicket)" :icon="{
          component: 'fas fa-hand-holding-usd',
          background: 'bg-gradient-dark',
          shape: 'rounded-circle'
        }" :has-details="true" data-bs-toggle="modal" data-bs-target="#modal-menor-ticket" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Clientes</h5>
                <p class="mb-0 text-sm">
                  Visualizando contratos ativos do tipo
                  <strong class="text-uppercase">
                    {{ vertical }}
                  </strong>
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Pesquisar Cliente</label>
                <input class="form-control text-uppercase" v-model="search" placeholder="Nome Fantasia, CPF/CNPJ">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6" v-for="cliente of clientesFiltered" :key="cliente.id">
                <cliente-card :cliente="cliente" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-maior-ticket" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Cliente com Maior Ticket
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-between">
              <label>ID:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMaiorTicket.id }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Razão Social:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMaiorTicket.razaoSocial }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Nome Fantasia:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMaiorTicket.nomeFantasia }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Documento:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.formatDocument(clienteMaiorTicket.documento) }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Porte:</label>
              <badge-plano :plano="clienteMaiorTicket.plano" :porte="clienteMaiorTicket.porte" />
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Tipo de Faturamento:</label>
              <badge-tipo-faturamento :tipo-faturamento="clienteMaiorTicket.tipoFaturamento" />
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Valor:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.currencyWithPrefix(clienteMaiorTicket.valor) }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Cidade:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMaiorTicket.cidade }} - {{ clienteMaiorTicket.uf }}
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-menor-ticket" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Cliente com Maior Ticket
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-between">
              <label>ID:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMenorTicket.id }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Razão Social:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMenorTicket.razaoSocial }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Nome Fantasia:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMenorTicket.nomeFantasia }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Documento:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.formatDocument(clienteMenorTicket.documento) }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Porte:</label>
              <badge-plano :plano="clienteMenorTicket.plano" :porte="clienteMenorTicket.porte" />
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Tipo de Faturamento:</label>
              <badge-tipo-faturamento :tipo-faturamento="clienteMenorTicket.tipoFaturamento" />
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Valor:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.currencyWithPrefix(clienteMenorTicket.valor) }}
              </span>
            </div>
            <hr class="mt-2 mb-2 horizontal dark">
            <div class="d-flex justify-content-between">
              <label>Cidade:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ clienteMenorTicket.cidade }} - {{ clienteMenorTicket.uf }}
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import BadgePlano from "./components/BadgePlano.vue";
import BadgeTipoFaturamento from "./components/BadgeTipoFaturamento.vue";
import ClienteCard from '@/views/pages/Clientes/components/ClienteCard.vue'
import api from './../../services/api';

export default {
  name: "ClientesVertical",
  components: {
    MiniStatisticsCard,
    ClienteCard,
    BadgeTipoFaturamento,
    BadgePlano,
  },
  created() {
    const { vertical } = this.$route.params;

    this.vertical = vertical;

    this.fetchData();
  },
  data() {
    return {
      vertical: '',
      clientes: [],
      search: '',
      clientesFiltered: [],
      clienteMaiorTicket: {},
      clienteMenorTicket: {},
      ticketMedio: 0,
      maiorTicket: 0,
      menorTicket: 0
    }
  },
  methods: {
    fetchData() {
      api.get(`/clientes/vertical/${this.vertical}`)
        .then(response => {
          const { data } = response;

          this.clientes = data;
          this.filterClientes();
          this.setTickets();
        })
        .catch(e => console.log(e));
    },
    filterClientes() {
      const { search } = this;

      let items = [
        ...this.clientes
      ];

      if (search) {
        items = items.filter(item => (item.nomeFantasia ?? '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (item.razaoSocial ?? '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (item.documento ?? '').toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      this.clientesFiltered = items;

    },
    setTickets() {
      let total = 0;
      let menorTicket = -1;
      let maiorTicket = 0;
      this.clienteMaiorTicket = {};
      this.clienteMenorTicket = {};

      for (const cliente of this.clientes) {
        const { valor, tipoFaturamento } = cliente;

        let valorMensal = valor;

        if (tipoFaturamento == 2) {
          valorMensal = valorMensal / 12;
        }

        total += valorMensal;

        if (menorTicket == -1 || valorMensal < menorTicket) {
          menorTicket = valorMensal;
          this.clienteMenorTicket = cliente;
        }

        if (valorMensal > maiorTicket) {
          maiorTicket = valorMensal
          this.clienteMaiorTicket = cliente;
        }        
      }


      this.ticketMedio = total / this.clientes.length;
      this.maiorTicket = maiorTicket;
      this.menorTicket = menorTicket;
    },
    printData() {
      this.dataTable.print();
    },
    formatFloat(value) {
      return this.filters.currency(value);
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `clientes-${this.vertical}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    }
  },
  watch: {
    search: function () {
      this.filterClientes();
    },
  }
};
</script>