<template>
    <span :class="['badge', `badge-${tipoContrato.toLowerCase()}`, `badge-${size}`]">
        {{ tipoContrato ==
            'ALTERACAO_VENCIMENTO' ?
            `ALTERAÇÃO
        DE VENCIMENTO` : tipoContrato
        }}
    </span>
</template>
<script>
export default {
    name: "BadgeTipoContrato",
    props: {
        tipoContrato: {
            type: String,
            default: "GANHO"
        },
        size: {
            type: String,
            default: "sm"
        }
    }
}
</script>
<style lang="scss">
.badge.badge-ganho {
    background-color: rgb(217, 245, 222);
    color: rgb(30, 126, 46);
}

.badge.badge-upsell {
    background-color: rgb(213, 255, 215);
    color: rgba(0, 0, 0, 0.611);
}

.badge.badge-virada {
    background-color: rgb(239, 197, 255);
    color: rgb(162, 0, 255);
}

.badge.badge-fechamento {
    background-color: rgb(255, 200, 250);
    color: rgb(252, 3, 231);
}

.badge.badge-downsell {
    background-color: rgb(255, 246, 225);
    color: rgba(0, 0, 0, 0.611);
}

.badge.badge-desconto {
    background-color: rgb(255, 228, 207);
    color: rgb(255, 115, 0);
}

.badge.badge-alteracao_vencimento {
    background-color: rgb(215, 234, 252);
    color: rgb(0, 102, 255);
}

.badge.badge-distrato {
    background-color: rgb(248, 209, 209);
    color: rgb(255, 0, 0);
}

.badge.badge-undefined {
    background-color: rgb(221, 221, 221);
    color: black;
}
</style>