<template>
	<div>
		<div class="row">
			<div class="col-12">
				<total-contratos-ativos-card :total="contratosAtivos.total" :on-change-date="onChangeDate"
					:current-month="currentMonth" />
			</div>
		</div>
		<div class="row card-verticais">
			<div class="mt-2 col-md-6">
				<vertical-total-card :total="contratosAtivos.hotel" description="Hotel" tipo="hotel" />
			</div>
			<div class="mt-2 col-md-6">
				<vertical-total-card :total="contratosAtivos.motel" description="Motel" tipo="motel" />
			</div>
			<div class="mt-2 col-md-6">
				<vertical-total-card :total="contratosAtivos.restaurante" description="Restaurante"
					tipo="restaurante" />
			</div>
			<div class="mt-2 col-md-6">
				<vertical-total-card :total="contratosAtivos.condominio" description="Condomínio" tipo="condominio" />
			</div>
		</div>
	</div>
</template>
<script>
import VerticalTotalCard from "@/examples/Cards/VerticalTotalCard.vue";
import TotalContratosAtivosCard from "./TotalContratosAtivosCard.vue";
import api from './../../../services/api'
import * as moment from 'moment'

export default {
	components: {
		VerticalTotalCard,
		TotalContratosAtivosCard
	},
	data() {
		return {
			currentMonth: moment()
				.startOf('month')
				.toDate(),

			contratosAtivos: {
				total: {},
				hotel: {},
				motel: {},
				condominio: {},
				restaurante: {}
			},
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		onChangeDate(newMonth) {
			this.currentMonth = newMonth;

			this.fetchData();
		},
		fetchData() {
			const dataReferencia = moment(this.currentMonth).format('YYYY-MM-DD');

			api.get('/dashboard-gerencial/contratos', {
				params: {
					data: dataReferencia
				}
			}).then(response => {
				const { data } = response;

				this.contratosAtivos = data;

			}).catch(e => console.log(e));
		},
	}
}
</script>
<style lang="scss" scoped></style>