<template>
    <div id="modal-avaliacao-cliente" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog mt-lg-10  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">
                        Avaliação do Cliente
                    </h5>
                    <badge-status-nps :status="avaliacao?.status" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-9">
                            <label>Empresa</label>
                            <input type="text" class="form-control" :value="avaliacao?.empresa" disabled />
                        </div>
                        <div class="col-md-3">
                            <label>Nota</label>
                            <input :value="avaliacao?.avaliacao" :class="classAvaliacao" disabled />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <label>Usuário</label>
                            <input type="text" class="form-control" :value="avaliacao?.usuario" disabled />
                        </div>

                        <div class="col-md-3">
                            <label>Data Avaliação</label>
                            <input :value="filters.formatDate(avaliacao?.data)"
                                class="form-control text-bold text-center " disabled />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <label for="">Observações do cancelamento:</label>
                            <textarea cols="30" class="form-control text-bold " rows="5" :value="avaliacao?.observacao"
                                style="resize: none;" readonly>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BadgeStatusNps from "@/views/dashboards/Nps/components/BadgeStatusNps.vue";

export default {
    name: "ModalAvaliacaoCliente",
    components: {
        BadgeStatusNps
    },
    props: {
        avaliacao: {
            type: Object,
            default: () => {
                return {
                    empresa: '',
                    usuario: '',
                    data: '',
                    observacao: ''
                }
            }
        }
    },
    computed: {
        classAvaliacao() {
            return {
                'form-control': true,
                'font-weight-bold': true,
                'text-lg': true,
                'text-center': true,
                'bg-success': this.avaliacao?.avaliacao > 8,
                'bg-warning': this.avaliacao?.avaliacao >= 6 && this.avaliacao?.avaliacao <= 8,
                'bg-danger': this.avaliacao?.avaliacao < 6,
                'text-white': this.avaliacao?.status != 'neutro'
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.table-pesquisa {
    height: 400px;
    max-height: 400px;
    overflow: scroll;
}
</style>