<template>
  <div class="card mb-2">
    <div class="pt-2 pb-2 text-center card-header">
      <span class="text-uppercase font-weight-bold text-dark overflow-text">
        {{ cliente?.nomeFantasia ?? cliente?.razaoSocial }}
      </span>
      <h6 class="mt-0 font-weight-bold text-sm">
        {{ filters.currencyWithPrefix(cliente?.valor) }}
      </h6>
    </div>
    <div class="pt-1 text-center card-body">
      <div class="d-flex justify-content-between">
        <label>Status</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-status-cancelamento :status="cancelamento.status" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Motivo</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-motivo-cancelamento :motivo="cancelamento.motivoCancelamento" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Vertical</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-vertical :vertical="cliente?.vertical" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tipo de Faturamento</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tipo-faturamento :tipo-faturamento="cliente?.tipoFaturamento" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Plano</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-plano :plano="cliente?.plano" :porte="cliente?.porte" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-1 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tempo de Contrato</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tempo-vida :tempo-vida="cancelamento.tempoContrato" size="xs" />
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Implantador</label>
        <h6 class="mb-0 font-weight-bolder text-sm">
          {{ cancelamento.implantador?.nome }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Responsável</label>
        <h6 class="mb-0 font-weight-bolder text-sm">
          {{ cancelamento.customerSuccess?.nome }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Data da Solicitação</label>
        <h6 class="mb-0 font-weight-bolder text-sm">
          {{ filters.formatDate(cancelamento.data) }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>{{ cliente?.ativo ? 'Data Contrato' : 'Data Cancelamento' }}</label>
        <h6 :class="['mb-0', 'font-weight-bolder', 'text-sm', cliente?.ativo ? 'text-navy' : 'text-danger']">
          {{ filters.formatDate(cliente?.ativo ? cliente?.dataContrato : cliente?.dataInativo) }}
        </h6>
      </div>
      <hr class="mt-1 mb-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Data Ultimo Contato</label>
        <h6 :class="['mb-0', 'font-weight-bolder', 'text-sm', cliente?.ativo ? 'text-navy' : 'text-danger']">
          {{ cancelamento.dataUltimoContato ? filters.formatDate(cancelamento.dataUltimoContato) : '***' }}
        </h6>
      </div>
      <router-link :to="{
          name: 'Cancelamento',
          params: {
            id: cancelamento.id
          }
        }" class="mt-3 mb-0 btn btn-md btn-icon d-lg-block bg-dark text-white">
        Visualizar
        <!-- <i class="fas fa-arrow-right ms-1"></i> -->
      </router-link>

    </div>
  </div>
</template>

<script>
import BadgePlano from "@/views/pages/components/BadgePlano.vue";
import BadgeVertical from "@/views/pages/components/BadgeVertical.vue";
import BadgeTipoFaturamento from "@/views/pages/components/BadgeTipoFaturamento.vue";
import BadgeStatusCancelamento from "./BadgeStatusCancelamento";
import BadgeMotivoCancelamento from "./BadgeMotivoCancelamento";
import BadgeTempoVida from "@/views/pages/components/BadgeTempoVida.vue";

export default {
  name: "CancelamentoCard",
  components: {
    BadgeStatusCancelamento,
    BadgePlano,
    BadgeVertical,
    BadgeTipoFaturamento,
    BadgeTempoVida,
    BadgeMotivoCancelamento,
  },
  props: {
    cancelamento: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {

    }
  },
  computed: {
    cliente() {
      return this.cancelamento.cliente;
    }
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;

  .card-body {
    padding: 1rem;

    label {
      text-align: left;
    }
  }

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
