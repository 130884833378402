<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Upsell Módulos</h5>
                <p class="mb-0 text-sm">
                  Lista de clientes que não possuem módulos ativos.
                </p>
              </div>
              <div class="my-auto ms-auto">
                <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                  @click="exportData()" v-if="items.length > 0">
                  Exportar CSV
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <label>Módulos</label>
                <select id="choices-multiple-remove-button" class="form-control" name="choices-multiple-remove-button"
                  multiple v-model="modulosSelecionados">
                </select>
              </div>
              <div class="col-md-2">
                <label>Módulo Contratado</label>
                <select class="form-control" v-model="moduloAtivo">
                  <option :value="true">SIM</option>
                  <option :value="false">NÃO</option>
                </select>
              </div>
            </div>

            <div class="row mt-2" v-if="modulosSelecionados.length > 0">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <button class="btn btn-sm btn-success w-100" @click="filterClientes()">
                  Visualizar Clientes
                </button>
              </div>
            </div>
            <div class="row mt-3" v-if="items.length > 0">
              <h6 class="text-center">
                Total de clientes {{ moduloAtivo ? 'com' : 'sem' }} módulos ativos - {{ items.length }}
              </h6>
              <clientes-counter-card :count="totalBasic" title="BASIC" />
              <clientes-counter-card :count="totalStarter" title="STARTER" />
              <clientes-counter-card :count="totalPro" title="PRO" />
              <clientes-counter-card :count="totalEnterprise" title="ENTERPRISE" />
            </div>
            <hr>
            <div class="row mt-2">
              <div class="table-responsive">
                <table id="clientes-modulos" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-xs" v-for="header of headers" :key="header">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cliente of items" :key="cliente.id">
                      <td class="text-xs">
                        {{ cliente.nome }}
                      </td>
                      <td class="text-xs">
                        {{ filters.formatDocument(cliente.documento) }}
                      </td>
                      <td class="text-center text-xs">
                        <BadgePlano :plano="cliente.plano" :porte="cliente.porte" />
                      </td>
                      <td v-for="moduloCliente of cliente.modulos" :key="moduloCliente.id" class="text-xs text-center">
                        <badge-sim-nao :value="moduloCliente.ativo" />
                      </td>
                      <td class="text-xs">
                        {{ cliente.cidade ?? '***' }}
                      </td>
                      <td class="text-xs text-center">
                        {{ cliente.telefone ?? '***' }}
                      </td>
                      <td class="text-xs">
                        {{ cliente.email }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientesCounterCard from "@/views/pages/Clientes/components/ClientesCounterCard.vue";
import BadgePlano from "./components/BadgePlano.vue";
import api from './../../services/api';
import { exportToCSV } from '@/utils/export';
import Choices from "choices.js";
import BadgeSimNao from "./components/BadgeSimNao.vue";

export default {
  name: "UpSellModulos",
  components: {
    ClientesCounterCard,
    BadgePlano,
    BadgeSimNao
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      moduloAtivo: false,
      modulos: [],
      headers: [],
      items: [],
      modulosSelecionados: [],
      modulo: null,
      dataTable: null,
      choices: null,
    }
  },
  methods: {
    createChoices() {
      this.choices = new Choices('#choices-multiple-remove-button', {
        removeItemButton: true,
        searchResultLimit: 5,
        renderChoiceLimit: 10,
        removeItems: true,
        allowHTML: false
      });

      const modulos = this.modulos.map(m => {
        return {
          value: m.id,
          label: m.descricao,
          selected: false
        }
      });

      this.choices.setChoices(modulos, 'value', 'label', false);
    },
    setLoading(value) {
      this.isLoading = value;
    },
    fetchData() {
      api.get(`/modulos`).then(({ data }) => {

        this.modulos = data.map(m => {
          return {
            id: m.id,
            descricao: m.descricao
          }
        });

        this.modulos.sort((a, b) => {
          if (a.descricao < b.descricao) {
            return -1;
          }
          if (a.descricao > b.descricao) {
            return 1;
          }
          return 0;
        });

        this.createChoices();

      }).catch(e => console.log(e));
    },
    async filterClientes() {
      const { moduloAtivo } = this;

      const modulosSelecionados = this.modulosSelecionados.map(m => Number(m));

      const modulos = this.modulos.filter(m => modulosSelecionados.includes(m.id));

      this.items = [];
      this.headers = [];

      this.setLoading(true);

      api.post(`/clientes/modulos`, {
        modulos,
        moduloAtivo
      }).then(({ data }) => {
        const { headers, items } = data

        this.headers = headers;
        this.items = items;
      }).catch(e => {
        console.log(e);
        this.setLoading(false);
      });

    },
    exportData() {
      const { moduloAtivo } = this
      const filename = moduloAtivo ? 'clientes-com-modulos' : 'clientes-sem-modulos';
      exportToCSV('clientes-modulos', `${filename}.csv`);
    },
  },
  computed: {
    totalStarter() {
      return this.items.filter(c => (c.porte ?? '').toUpperCase() == 'STARTER').length;
    },
    totalBasic() {
      return this.items.filter(c => (c.porte ?? '').toUpperCase() == 'BASIC').length;
    },
    totalPro() {
      return this.items.filter(c => (c.porte ?? '').toUpperCase() == 'PRO').length;
    },
    totalEnterprise() {
      return this.items.filter(c => (c.porte ?? '').toUpperCase() == 'ENTERPRISE').length;
    },
  },
  watch: {
    modulosSelecionados: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.headers = [];
        this.items = [];
      }
    }
  }

};
</script>
<style lang="scss" scoped>
.table-responsive {
  max-height: 100vh;
  overflow: scroll;
  max-width: 100%;
  overflow: scroll;
}
</style>