<template>
  <div class="py- container-fluid">
    <month-navigator v-model="mes" />
    <div class="row">
      <totais-meta-card title="Total" :meta="metaMes.meta.valorTotal" :venda="metaMes.vendas.total" :size="4" />
      <totais-meta-card title="MRR" :meta="metaMes.meta.valorMrr" :venda="metaMes.vendas.mrr" />
      <totais-meta-card title="ARR" :meta="metaMes.meta.valorArr" :venda="metaMes.vendas.arr" />
      <totais-meta-card title="Consultoria" :meta="metaMes.meta.valorConsultoria"
        :venda="metaMes?.vendas?.consultoria" />
      <totais-meta-card title="Upsell" :meta="metaMes.meta.valorUpSell" :venda="metaMes.vendas.upsell" />
    </div>
    <div class="row">
      <div class="col-md-12 mt-sm-2">
        <metas-individuais-card :mes="mes" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-sm-2">
        <venda-tipo-faturamento-card title="MRR X Closer" :mes="mes" :tipo="1" />
      </div>
      <div class="col-md-4 mt-sm-2">
        <venda-tipo-faturamento-card title="ARR X Closer" :mes="mes" :tipo="2" />
      </div>
      <div class="col-md-4 mt-sm-2">
        <venda-consultoria-card :mes="mes" />
      </div>
    </div>
  </div>
</template>

<script>
import VendaTipoFaturamentoCard from "./components/VendaTipoFaturamentoCard.vue";
import MetasIndividuaisCard from "./components/MetasIndividuaisCard.vue";
import MonthNavigator from "@/components/MonthNavigator.vue";
import TotaisMetaCard from "./components/TotaisMetaCard.vue";
import VendaConsultoriaCard from "./components/VendaConsultoriaCard.vue";
import moment from "moment";
import api from "@/services/api";

export default {
  name: "Metas",
  components: {
    VendaConsultoriaCard,
    MetasIndividuaisCard,
    VendaTipoFaturamentoCard,
    TotaisMetaCard,
    MonthNavigator,
  },
  data() {
    return {
      mes: moment().startOf('month').toDate(),
      metaMes: {
        meta: {
          data: '',
          id: null,
          metaClosers: [],
          valorArr: 0,
          valorChurn: 0,
          valorConsultoria: 0,
          valorMrr: 0,
          valorUpsell: 0,
          valorTotal: 0
        },
        vendas: {
          mrr: 0,
          arr: 0,
          consultoria: 0,
          upsell: 0
        }
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const mes = moment(this.mes).format('YYYY-MM-DD');

      api.get(`/metas/mes`, {
        params: {
          mes
        }
      }).then(({ data }) => {
        this.metaMes = data;
      });
    },
    onUpSellClick() {
      const mes = moment(this.mes).format('YYYY-MM');

      this.$router.push({
        name: 'UpSells Mensal',
        params: { mes }
      });
    }
  },
  computed: {
    totalVendasMes() {
      return this.metaMes.vendas.mrr + (this.metaMes.vendas.arr / 12);
    }
  },
  watch: {
    mes: function () {
      this.fetchData();
    }
  }
};
</script>
