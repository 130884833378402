import store from "./../store";

const routes = {
    DASHBOARD_PRODUTO: 'Produto',
    DASHBOARD_ONBOARDING: 'Onboarding',
    DASHBOARD_GERENCIAL: 'Gerencial',
    DASHBOARD_COMERCIAL: 'Comercial',
    DASHBOARD_FINANCEIRO: 'Financeiro',
    DASHBOARD_MARKETING: 'Marketing',
    DASHBOARD_RETENCAO: 'Retenção',
    DASHBOARD_CANCELAMENTOS: 'Dashboard Cancelamentos',
    DASHBOARD_CUSTOMER_SUCCESS: 'Dashboard Customer Success',
    DASHBOARD_IMPLANTACAO: 'Implantação',
    DASHBOARD_NPS: 'Nps',
    DASHBOARD_INADIMPLENCIA: 'Inadimplência',
    PAGES_CLIENTES: 'Clientes',
    PAGES_CLIENTE: 'Cliente',
    PAGES_IMPLANTACOES: 'Implantações',
    PAGES_MODULOS: 'Módulos',
    PAGES_MODULO: 'Módulo',
    PAGES_PLANOS: 'Planos',
    PAGES_PLANO: 'Plano',
    PAGES_UPSELLS_MENSAL: 'UpSells Mensal',
    PAGES_NOTAS_VERSAO: 'Notas de Versão',
    PAGES_NOTA_VERSAO: 'Nota de Versão',
    PAGES_RECEITAS_MENSAL: 'Receitas Mensal',
    PROCESSOS_UPSELLS: 'Upsells',
    PROCESSOS_UPSELL: 'Upsell',
    PROCESSOS_RETENCOES: 'Retenções',
    PROCESSOS_RETENCAO: 'Retenção',
    PROCESSOS_CANCELAMENTOS: 'Cancelamentos',
    PROCESSOS_CANCELAMENTO: 'Cancelamento',
    PROCESSOS_KANBAN_CANCELAMENTO: 'Kanban Cancelamentos',
    PROCESSOS_UPSELL_MODULOS: 'Upsell Modulos',
}

const roles = {
    ROLE_ADMIN: 2,
    ROLE_PRODUTO: 1,
    ROLE_CLOSER: 3,
    ROLE_FINANCEIRO: 4,
    ROLE_MARKETING: 5,
    ROLE_GERENTE_ATENDIMENTO: 6,
    ROLE_CUSTOMER_SUCCESS: 7,
    ROLE_IMPLANTADOR: 8,
};

const ROLES_PRODUTO = [
    routes.DASHBOARD_PRODUTO,
    routes.DASHBOARD_CANCELAMENTOS,
    routes.DASHBOARD_NPS,
    routes.DASHBOARD_ONBOARDING,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
    routes.PAGES_NOTAS_VERSAO,
    routes.PAGES_NOTA_VERSAO,
];

const ROLES_CLOSER = [
    routes.DASHBOARD_COMERCIAL,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
    routes.PAGES_UPSELLS_MENSAL,
    routes.PROCESSOS_UPSELLS,
    routes.PROCESSOS_UPSELL,
    routes.PROCESSOS_UPSELL_MODULOS
];

const ROLES_FINANCEIRO = [
    routes.DASHBOARD_FINANCEIRO,
    routes.DASHBOARD_ONBOARDING,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
    routes.PAGES_RECEITAS_MENSAL,
    routes.PROCESSOS_UPSELLS,
    routes.PROCESSOS_UPSELL,
    routes.DASHBOARD_CANCELAMENTOS,
    routes.DASHBOARD_INADIMPLENCIA,
];

const ROLES_MARKETING = [
    routes.DASHBOARD_MARKETING,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
];

const ROLES_GERENTE_ATENDIMENTO = [
    routes.DASHBOARD_ONBOARDING,
    routes.DASHBOARD_RETENCAO,
    routes.DASHBOARD_CUSTOMER_SUCCESS,
    routes.DASHBOARD_CANCELAMENTOS,
    routes.DASHBOARD_NPS,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
    routes.PROCESSOS_UPSELLS,
    routes.PROCESSOS_UPSELL,
    routes.PROCESSOS_RETENCOES,
    routes.PROCESSOS_RETENCAO,
    routes.PROCESSOS_CANCELAMENTOS,
    routes.PROCESSOS_CANCELAMENTO,
    routes.PROCESSOS_KANBAN_CANCELAMENTO
];

const ROLES_ROLE_CUSTOMER_SUCCESS = [
    routes.DASHBOARD_ONBOARDING,
    routes.DASHBOARD_CUSTOMER_SUCCESS,
    routes.DASHBOARD_CANCELAMENTOS,
    routes.DASHBOARD_RETENCAO,
    routes.DASHBOARD_NPS,
    routes.PAGES_CLIENTES,
    routes.PAGES_CLIENTE,
    routes.PROCESSOS_UPSELLS,
    routes.PROCESSOS_UPSELL,
    routes.PROCESSOS_RETENCOES,
    routes.PROCESSOS_RETENCAO,
    routes.PROCESSOS_CANCELAMENTOS,
    routes.PROCESSOS_CANCELAMENTO,
    routes.PROCESSOS_KANBAN_CANCELAMENTO
];

const ROLES_IMPLANTACAO = [
    routes.DASHBOARD_ONBOARDING,
    routes.DASHBOARD_IMPLANTACAO,
    routes.PAGES_CLIENTES,
    routes.PAGES_IMPLANTACOES,
];

export const canAccessRoute = (to) => {
    const user = store.getters.getUser;

    if (!user) {
        return false;
    }

    if (user.tipo == roles.ROLE_ADMIN) {
        return true;
    }

    switch (user.tipo) {
        case roles.ROLE_PRODUTO:
            return ROLES_PRODUTO.includes(to.name);
        case roles.ROLE_CLOSER:
            return ROLES_CLOSER.includes(to.name);
        case roles.ROLE_FINANCEIRO:
            return ROLES_FINANCEIRO.includes(to.name);
        case roles.ROLE_MARKETING:
            return ROLES_MARKETING.includes(to.name);
        case roles.ROLE_GERENTE_ATENDIMENTO:
            return ROLES_GERENTE_ATENDIMENTO.includes(to.name);
        case roles.ROLE_CUSTOMER_SUCCESS:
            return ROLES_ROLE_CUSTOMER_SUCCESS.includes(to.name);
        case roles.ROLE_IMPLANTADOR:
            return ROLES_IMPLANTACAO.includes(to.name);
    }

    return false;
}