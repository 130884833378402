<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div class="d-flex" :class="'flex-row-reverse justify-content-between'">
        <div class="text-center shadow icon icon-shape" :class="[
        typeof icon === 'object'
          ? `${icon.background} ${icon.shape}`
          : 'border-radius-md',
        '',
      ]">
          <i class="text-lg opacity-10" :class="typeof icon === 'string' ? icon : icon.component"
            aria-hidden="true"></i>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <h5 class="mb-0 text-uppercase font-weight-bolder">
              {{ title }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div
          :class="['card-semaforo-totais w-100 d-flex align-items-center justify-content-between me-2  border-dark border-radius-md p-2', isStatusSelected(totais.statusVerde) ? 'border border-2' : 'border-dashed border-1']"
          @click="onSetStatus(totais.statusVerde)">
          <badge-semaforo cor="VERDE" />          
          <div class="text-uppercase font-weight-bolder">
            {{ totais.VERDE }}
          </div>
        </div>
        <div
          :class="['card-semaforo-totais w-100 d-flex align-items-center justify-content-between me-2  border-dark border-radius-md p-2', isStatusSelected(totais.statusAmarelo) ? 'border border-2' : 'border-dashed border-1']"
          @click="onSetStatus(totais.statusAmarelo)">
          <badge-semaforo cor="AMARELO" />          
          <div class="text-uppercase font-weight-bolder">
            {{ totais.AMARELO }}
          </div>
        </div>
        <div
          :class="['card-semaforo-totais w-100 d-flex align-items-center justify-content-between me-2  border-dark border-radius-md p-2', isStatusSelected(totais.statusVermelho) ? 'border border-2' : 'border-dashed border-1']"
          @click="onSetStatus(totais.statusVermelho)">
          <badge-semaforo cor="VERMELHO" />          
          <div class="text-uppercase font-weight-bolder">
            {{ totais.VERMELHO + totais.SEM_SINAL }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeSemaforo from "@/views/pages/components/BadgeSemaforo.vue";

export default {
  name: "SemaforoSituacoes",
  components: {
    BadgeSemaforo
  },
  props: {
    title: {
      type: String,
      required: true
    },
    totais: {
      type: Object,
      default: () => { }
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      shape: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
        shape: " border-radius-md",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
    onSetStatus: {
      type: Function,
      required: true,
    },
    currentStatus: {
      type: String,
      required: true,
    },

  },
  methods: {
    isStatusSelected(status) {
      return this.currentStatus == status;
    },
  },
  computed: {

  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.9px solid #212529;
}

.card-semaforo-totais {  
  cursor: pointer;
}
</style>