<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Closers</h5>
                <div class="d-flex justify-content-start mt-2">
                  <button :class="['btn', ativos == true ? 'btn-success' : 'btn-outline-success', 'btn-sm', 'me-2']"
                    type="button" name="button" @click="setFiltroAtivos(true)">
                    Ativos
                  </button>
                  <button :class="['btn', ativos == false ? 'btn-danger' : 'btn-outline-danger', 'btn-sm']"
                    type="button" name="button" @click="setFiltroAtivos(false)">
                    Inativos
                  </button>
                </div>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Closer', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      <i class="fas fa-plus"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <table id="upsells-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Nome</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="closer of closersFiltered" :key="closer.id">
                  <td>
                    <span class="my-2 text-xs text-uppercase">
                      {{ closer.nome }}
                    </span>
                  </td>
                  <td class="text-uppercase text-center">
                    <span class="my-2 text-sm text-bold text-dark">
                      <badge-status :ativo="closer.ativo" />
                    </span>
                  </td>
                  <td class="text-center">
                    <badge-tipo-closer :tipo="closer.tipo" />
                  </td>
                  <td class="text-center">
                    <router-link :to="{
                      name: 'Closer', params: {
                        id: closer.id
                      }
                    }" class="btn btn-xs btn-primary">
                      Editar
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import BadgeStatus from '../components/BadgeStatus.vue';
import BadgeTipoCloser from '../components/BadgeTipoCloser.vue';

export default {
  name: "Closers",
  components: {
    BadgeStatus,
    BadgeTipoCloser,
  },
  data() {
    return {
      closers: [],
      closersFiltered: [],
      ativos: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/closers`)
        .then(response => {
          const { data } = response;

          this.closers = data;
          this.filterClosers();
        })
        .catch(e => console.log(e));
    },
    setFiltroAtivos(value) {
      this.ativos = value == this.ativos ? null : value;

      this.filterClosers();
    },
    filterClosers() {
      this.closersFiltered = this.closers.filter(closer => {
        if (this.ativos !== null) {
          return closer.ativo == this.ativos;
        }

        return true;
      });
    }
  }

};
</script>
