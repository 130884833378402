<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <form role="form" @submit.prevent="onSubmit">
            <h6 class="mb-0">{{ id == 'new' ? 'Novo plano' : 'Editar Plano' }}</h6>
            <p class="mb-0 text-sm">Criar/Editar plano</p>
            <hr class="my-3 horizontal dark" />
            <div class="row">
              <div class="col-md-8">
                <label>Descrição</label>
                <argon-input id="name" type="text" placeholder="Descrição" aria-label="Descrição"
                  v-model="v$.plano.descricao.$model" text-case="upper" />
                <div class="input-errors" v-for="(error, index) of v$.plano.descricao.$errors" :key="index">
                  <div class="text-danger text-xs">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-md-2">
                <label>Status</label>
                <select class="form-control mb-3" v-model="v$.plano.ativo.$model">
                  <option :value="true">ATIVO</option>
                  <option :value="false">INATIVO</option>
                </select>
              </div>
              <div class="col-md-2">
                <label>Valor Sugerido</label>
                <money3 v-model="v$.plano.valorSugerido.$model" class="form-control text-right" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <label>Módulo</label>
                <select class="form-control" :class="moduloError ? 'is-invalid' : ''" v-model="planoModulo.modulo">
                  <option :value="null">Selecione um módulo...</option>
                  <option v-for="modulo of modulos" :key="modulo.id" :value="modulo">
                    {{ modulo.descricao }}
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <label>Obrigatório</label>
                <select class="form-control" v-model="planoModulo.obrigatorio">
                  <option :value="true">SIM</option>
                  <option :value="false">NÃO</option>
                </select>
              </div>
              <div class="col-md-2">
                <label>&nbsp;</label>
                <div class="d-flex">
                  <argon-button color="primary" size="md" :full-width="true" variant="contained" @click="addModulo()">
                    Adicionar
                  </argon-button>
                </div>
              </div>
            </div>
            <div v-if="plano.modulos.length > 0">
              <div class="table-responsive">
                <table id="products-list" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>Módulo</th>
                      <th>Valor</th>
                      <th class="text-center">Obrigatório</th>
                      <th class="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(modulo, index) in plano.modulos" :key="index">
                      <td>
                        <h6 class="my-auto ms-3">
                          {{ modulo.modulo.descricao }}
                        </h6>
                      </td>
                      <td>
                        <div class="d-flex justify-content-center align-items-center">
                          {{ filters.currency(modulo.modulo.valorMinimo) }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="my-auto form-check">
                            <input id="customCheck6" class="form-check-input" type="checkbox"
                              v-model="modulo.obrigatorio" />
                          </div>
                        </div>
                      </td>
                      <td class="text-sm">
                        <div class="d-flex justify-content-center align-items-center">
                          <argon-button color="danger" size="xs" variant="contained" @click="removeModulo(modulo, index)">
                            Excluir
                          </argon-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class=" d-flex justify-content-between">
              <router-link :to="{ name: 'Planos' }">
                <argon-button type="button" color="dark" class="my-4 mb-2">
                  Voltar
                </argon-button>
              </router-link>
              <argon-button type="submit" color="success" variant="gradient" class="my-4 mb-2"
                v-if="plano.modulos.length > 0">
                SALVAR
              </argon-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import api from './../../services/api'
import { successToast, showConfirm, errorToast } from '@/utils/alerts';

export default {
  name: "Plano",
  components: {
    ArgonInput,
    ArgonButton,
  },
  created() {
    this.fetchModulos();

    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      modulos: [],
      moduloError: false,
      planoModulo: {
        modulo: null,
        obrigatorio: false
      },
      plano: {
        id: null,
        modulos: [],
        descricao: '',
        valorSugerido: 0,
        ativo: true,
      }
    }
  },
  validations() {
    return {
      plano: {
        descricao: {
          required: helpers.withMessage('Descrição é obrigatória', required),
        },
        ativo: {

        },
        valorSugerido: {

        },
        modulos: {}
      },
    }
  },
  methods: {
    fetchModulos() {
      api.get(`/modulos`)
        .then(({ data }) => {
          this.modulos = data;
        })
        .catch(e => console.log(e));
    },
    fetchData() {
      const { id } = this;

      api.get(`/planos/${id}`)
        .then(({ data }) => {
          this.plano = data;
        })
        .catch(e => console.log(e));
    },
    setModuloError() {
      this.moduloError = true;

      setTimeout(() => {
        this.moduloError = false
      }, 5000);
    },
    resetPlanoModulo() {
      this.planoModulo = {
        modulo: null,
        obrigatorio: false
      }
    },
    addModulo() {
      const { modulo, obrigatorio } = this.planoModulo;

      if (!modulo) {
        this.setModuloError();
        return;
      }

      if (this.plano.modulos.some(item => item.modulo.id == modulo.id)) {
        errorToast('Esse módulo ja está na lista');
        return;
      }

      this.plano.modulos.push({
        id: null,
        modulo,
        obrigatorio
      });

      this.resetPlanoModulo();
    },
    async removeModulo(modulo, index) {
      const confirm = await showConfirm(`${modulo.modulo.descricao}`, 'Confirma exclusão do módulo?');
      console.log(confirm)
      if (!confirm) {
        return
      }

      this.plano.modulos.splice(index, 1);
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.plano
        }

        const response = await (this.id == 'new' ?
          api.post('/planos', payload) :
          api.patch(`/planos/${this.id}`, payload));

        const { data } = response;

        successToast('Módulo salvo com sucesso.');

        console.log(data);

        this.$router.push({ name: 'Planos' });
        //window.location.reload();

      } catch (e) {
        console.log(e);
        // const { data } = e.response;
        // const message = data ? data.message : e.message;        
      }
    },
  },
  watch: {
    'planoModulo.modulo': function () {
      this.moduloError = false;
    }
  }
};
</script>
