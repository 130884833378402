<template>
    <div>
        <div class="position-absolute w-100 min-height-300 top-0">
            <div class="page-header min-height-300 bg-success"></div>
        </div>
        <div class="py-2 container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header pb-0">
                            <div class="d-flex justify-content-between align-content-center">
                                <h5 class="mb-0">
                                    Situação da Carteira
                                </h5>
                                <h5 class="mb-0">
                                    <small class="text-xs">Referência:</small>
                                    {{ filters.formatDateTime(semaforo.createdAt, 'DD/MM/YY HH:mm') }}
                                </h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-header px-2 pt-2 pb-0">
                                            <div class="card-title text-center">
                                                <h6>
                                                    Vertival
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-md-6" v-for="tipoEmpresa of tiposEmpresa"
                                                    :key="tipoEmpresa.id">
                                                    <button type="button"
                                                        :class="['btn', tipoEmpresa.isSelected() ? 'btn-dark' : 'btn-outline-dark', 'btn-sm', 'w-100', 'mb-2']"
                                                        @click="tipoEmpresa.onClick()">
                                                        {{ tipoEmpresa.title }} ({{ tipoEmpresa.count() }})
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card">
                                        <div class="card-header px-2 pt-2 pb-0">
                                            <div class="card-title text-center">
                                                <h6>
                                                    Status
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-md-4" v-for="status of statusEmpresas" :key="status.id">
                                                    <button type="button"
                                                        :class="['btn', status.isSelected() ? 'btn-dark' : 'btn-outline-dark', 'w-100', 'mb-2', 'btn-sm']"
                                                        @click="status.onClick()">
                                                        {{ status.title }} ({{ status.count() }})
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row" v-if="tipoEmpresaSelecionado != 4">
                                <div class="col-md-4">
                                    <semaforo-situacoes-card title="Reservas" :icon="icons.reserva"
                                        :totais="totais.reservas" @set-status="setFilterStatusReserva"
                                        :current-status="statusReserva" />
                                </div>
                                <div class="col-md-4">
                                    <semaforo-situacoes-card title="Hospedagem" :icon="icons.hospedagem"
                                        :totais="totais.hospedagem" @set-status="setFilterStatusHospedagem"
                                        :current-status="statusHospedagem" />
                                </div>
                                <div class="col-md-4">
                                    <semaforo-situacoes-card title="Acesso" :icon="icons.acesso" :totais="totais.acesso"
                                        @set-status="setFilterStatusAcesso" :current-status="statusAcesso" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <label>Pesquisar empresa({{ empresasFiltered.length }})</label>
                                    <input class="form-control" v-model="search"
                                        placeholder="Nome Fantasia, Cód Empresa">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-6" v-for="empresa of empresasFiltered" :key="empresa.id">
                                    <empresa-semaforo-card :empresa="empresa" :on-click="setEmpresaSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-info-semaforo" class="modal fade" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog mt-lg-10">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="ModalLabel" class="modal-title">
                            Informações da Empresa
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-between">
                            <label>Cód Da Empresa:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ empresaSelected.empresaId }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Nome Fantasia:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ empresaSelected.nomeFantasia }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Data Ultima Reserva:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ filters.formatDate(empresaSelected.dataUltimaReserva) }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Data Ultima Hospedagem:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ filters.formatDate(empresaSelected.dataUltimaHospedagem) }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Data Ultimo Acesso:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ filters.formatDate(empresaSelected.dataUltimoAcesso) }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>NPS(Votos):</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ empresaSelected.votos }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>NPS(Média):</label>
                            <span class="font-weight-bold text-primary text-sm">
                                {{ empresaSelected.mediaNps }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Tempo Contrato:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                <badge-tempo-vida :tempo-vida="empresaSelected.tempoContrato" size="xs" />
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <label>Status:</label>
                            <span class="font-weight-bold text-primary text-sm">
                                <badge-status-empresa :status="empresaSelected.status" size="sm" />
                            </span>
                        </div>

                        <h6 class="text-center" v-if="!empresaSelected.infoContato">
                            Aguarde carregando dados adicionais da empresa...
                        </h6>
                        <template v-else>
                            <hr class="mt-2 horizontal dark">
                            <div class="d-flex justify-content-between">
                                <label>Responsável:</label>
                                <span class="font-weight-bold text-primary text-sm">
                                    {{ empresaSelected.infoContato.responsavel ?? '***' }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label>Telefone:</label>
                                <span class="font-weight-bold text-primary text-sm">
                                    {{ empresaSelected.infoContato.telefone1 ?? '***' }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label>Telefone 2:</label>
                                <span class="font-weight-bold text-primary text-sm">
                                    {{ empresaSelected.infoContato.telefone2 ?? '***' }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label>Email:</label>
                                <span class="font-weight-bold text-primary">
                                    {{ empresaSelected.infoContato.email ?? '***' }}
                                </span>
                            </div>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SemaforoSituacoesCard from "./../../../examples/Cards/SemaforoSituacoesCard.vue";
import EmpresaSemaforoCard from "@/views/pages/components/EmpresaSemaforoCard.vue";
import BadgeTempoVida from "@/views/pages/components/BadgeTempoVida.vue";
import BadgeStatusEmpresa from "@/views/pages/components/BadgeStatusEmpresa.vue";
import { mapMutations } from "vuex";
import api from './../../../services/api'

export default {
    name: "Semaforo",
    components: {
        SemaforoSituacoesCard,
        EmpresaSemaforoCard,
        BadgeTempoVida,
        BadgeStatusEmpresa
    },
    data() {
        return {
            statusReserva: '',
            statusHospedagem: '',
            statusAcesso: '',
            statusSelecionado: 'F',
            tipoEmpresaSelecionado: 2,
            tiposEmpresa: [
                {
                    id: 1,
                    title: 'HOTEL',
                    count: () => this.totalTipo(2),
                    isSelected: () => this.tipoEmpresaSelecionado == 2,
                    onClick: () => this.setTipoEmpresaSelecionado(2)
                },
                {
                    id: 2,
                    title: 'RESTAURANTE',
                    count: () => this.totalTipo(4),
                    isSelected: () => this.tipoEmpresaSelecionado == 4,
                    onClick: () => this.setTipoEmpresaSelecionado(4)
                },
            ],
            statusEmpresas: [
                {
                    id: 1,
                    title: 'FATURADO',
                    count: () => this.totalStatus('F'),
                    isSelected: () => this.statusSelecionado == 'F',
                    onClick: () => this.setStatusSelecionado('F')
                },
                {
                    id: 2,
                    title: 'IMPLANTAÇÃO',
                    count: () => this.totalStatus('I'),
                    isSelected: () => this.statusSelecionado == 'I',
                    onClick: () => this.setStatusSelecionado('I')
                },
                {
                    id: 3,
                    title: 'CONGELADO',
                    count: () => this.totalStatus('C'),
                    isSelected: () => this.statusSelecionado == 'C',
                    onClick: () => this.setStatusSelecionado('C')
                },
            ],
            semaforo: {
                id: null,
                data: null,
                createdAt: null
            },
            empresas: [],
            empresasFiltered: [],
            icons: {
                reserva: {
                    component: 'fas fa-bookmark',
                    background: 'bg-gradient-primary',
                    shape: 'rounded-circle'
                },
                hospedagem: {
                    component: 'fas fa-hotel',
                    background: 'bg-gradient-success',
                    shape: 'rounded-circle'
                },
                acesso: {
                    component: 'fas fa-user',
                    background: 'bg-gradient-info',
                    shape: 'rounded-circle'
                }
            },
            totais: {
                reservas: {
                    VERDE: 0,
                    AMARELO: 0,
                    VERMELHO: 0,
                    SEM_SINAL: 0,
                    statusVerde: 'RV',
                    statusAmarelo: 'RA',
                    statusVermelho: 'RR',
                },
                hospedagem: {
                    VERDE: 0,
                    AMARELO: 0,
                    VERMELHO: 0,
                    SEM_SINAL: 0,
                    statusVerde: 'HV',
                    statusAmarelo: 'HA',
                    statusVermelho: 'HR',
                },
                acesso: {
                    VERDE: 0,
                    AMARELO: 0,
                    VERMELHO: 0,
                    SEM_SINAL: 0,
                    statusVerde: 'AV',
                    statusAmarelo: 'AA',
                    statusVermelho: 'AR',
                },
            },
            search: '',
            empresaSelected: {}
        };
    },
    created() {
        this.$store.state.hideConfigButton = true;
        this.setFullScreenLayout();
        this.fetchData();
    },
    beforeUnmount() {
        this.$store.state.hideConfigButton = false;
        this.toggleDefaultLayout();
    },
    methods: {
        ...mapMutations(["toggleDefaultLayout", 'setFullScreenLayout']),
        fetchData() {
            api.get(`/semaforo`)
                .then(response => {
                    const { data, id, empresas, createdAt } = response.data;

                    this.semaforo = {
                        id,
                        data,
                        createdAt
                    };

                    this.empresas = empresas;
                    this.filterEmpresas();
                    this.setStatusEmpresas();
                })
                .catch(e => console.log(e));
        },
        totalTipo(tipo) {
            return (this.empresas ?? []).filter(item => item.tipo == tipo).length;
        },
        totalStatus(status) {
            const { tipoEmpresaSelecionado } = this;
            const items = {
                F: ['F'],
                I: ['I', 'A'],
                C: ['G', 'B', 'S'],
            };
            return (this.empresas ?? []).filter(item => items[status].includes(item.status) && (tipoEmpresaSelecionado ? tipoEmpresaSelecionado == item.tipo : true)).length;
        },
        setTipoEmpresaSelecionado(tipo) {
            this.tipoEmpresaSelecionado = tipo == this.tipoEmpresaSelecionado ? 0 : tipo;
            this.statusSelecionado = '';

            this.filterEmpresas();
        },
        setStatusSelecionado(status) {
            this.statusSelecionado = status == this.statusSelecionado ? '' : status;
            this.filterEmpresas();
        },
        setStatusEmpresas() {
            const { empresas } = this;

            for (const empresa of empresas) {
                const { statusReserva, statusHospedagem, statusAcesso } = empresa;

                this.totais.reservas[statusReserva]++;
                this.totais.hospedagem[statusHospedagem]++;
                this.totais.acesso[statusAcesso]++;
            }
        },
        setFilterStatusReserva(status) {
            this.statusReserva = status == this.statusReserva ? '' : status;

            this.filterEmpresas();
        },
        setFilterStatusHospedagem(status) {
            this.statusHospedagem = status == this.statusHospedagem ? '' : status;

            this.filterEmpresas();
        },
        setFilterStatusAcesso(status) {
            this.statusAcesso = status == this.statusAcesso ? '' : status;

            this.filterEmpresas();
        },
        filterEmpresas() {
            const { search, statusAcesso, statusReserva, statusHospedagem, statusSelecionado, tipoEmpresaSelecionado } = this;

            const fields = {
                RV: 'statusReserva',
                RA: 'statusReserva',
                RR: 'statusReserva',
                HV: 'statusHospedagem',
                HA: 'statusHospedagem',
                HR: 'statusHospedagem',
                AV: 'statusAcesso',
                AA: 'statusAcesso',
                AR: 'statusAcesso',
            };

            const cores = {
                RV: 'VERDE',
                RA: 'AMARELO',
                RR: 'VERMELHO',
                HV: 'VERDE',
                HA: 'AMARELO',
                HR: 'VERMELHO',
                AV: 'VERDE',
                AA: 'AMARELO',
                AR: 'VERMELHO',
            };

            let empresas = [
                ...this.empresas
            ];

            if (search) {
                empresas = empresas.filter(item =>
                    item.nomeFantasia.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    item.empresaId.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
                );
            }

            if (statusAcesso) {
                const field = fields[statusAcesso];

                const cor = cores[statusAcesso];

                empresas = empresas.filter(item => {
                    if (cor == 'VERMELHO') {
                        return item[field] == cor || item[field] == 'SEM_SINAL';
                    }

                    return item[field] == cor;
                });
            }

            if (statusHospedagem) {
                const field = fields[statusHospedagem];

                const cor = cores[statusHospedagem];

                empresas = empresas.filter(item => {
                    if (cor == 'VERMELHO') {
                        return item[field] == cor || item[field] == 'SEM_SINAL';
                    }

                    return item[field] == cor;
                });
            }

            if (statusReserva) {
                const field = fields[statusReserva];

                const cor = cores[statusReserva];

                empresas = empresas.filter(item => {
                    if (cor == 'VERMELHO') {
                        return item[field] == cor || item[field] == 'SEM_SINAL';
                    }

                    return item[field] == cor;
                });
            }

            if (statusSelecionado) {
                const items = {
                    F: ['F'],
                    I: ['I', 'A'],
                    C: ['G', 'B', 'S'],
                };
                empresas = empresas.filter(item => items[statusSelecionado].includes(item.status));
            }

            if (tipoEmpresaSelecionado) {
                empresas = empresas.filter(item => item.tipo == tipoEmpresaSelecionado);
            }

            this.empresasFiltered = empresas;

        },
        setEmpresaSelected(empresa) {
            this.empresaSelected = empresa;

            if (!this.empresaSelected.infoContato) {
                this.getInfoContato();
            }
        },
        async getInfoContato() {
            const { empresaId } = this.empresaSelected;

            if (!empresaId) {
                return;
            }

            try {
                const response = await api.post('/public/contato-empresa', {
                    empresa: empresaId
                });

                const { data } = response;

                this.empresaSelected.infoContato = data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    watch: {
        search: function () {
            this.filterEmpresas();
        }
    }
};
</script>