export const tipoLancamentoReceita = (lancamento) => {
    const { tipo, tipo_mf } = lancamento;

    let label = 'TITULO EM ABERTO';

    if (tipo == 'LANCAMENTO') {
        label = tipo_mf == 'BX' ? 'BAIXA TÍTULO' : 'JUROS/TAXAS';
    }

    return (label ?? '').toUpperCase();
}
