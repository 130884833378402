<template>
  <div class="py-2 container-fluid">
    <div class="container">
      <div class="tab-content tab-space">
        <div id="cadastro" class="tab-pane active">
          <div class="row">
            <div class="mx-auto col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between">
                    <back-button class="me-2" />
                    <div class="text-center">
                      <h6 class="mb-0">{{ id == 'new' ? 'Novo cliente' : 'Editar Cliente' }}</h6>
                      <p class="mb-0 text-sm">{{ cliente.nomeFantasia }}</p>
                    </div>
                    <submit-button @click="onSubmit" v-if="isAdmin" />
                  </div>
                </div>
                <div class="card-body">
                  <nav-bar-cadastro-cliente :cliente="cliente" :active-tab="activeTabIndex"
                    :on-click="onNavButtonClick" />
                  <hr class="mt-4 mb-2 dark">
                  <div v-show="activeTabIndex == 0">
                    <form role="form" @submit.prevent="onSubmit">
                      <div class="row">
                        <div class="col-md-8">
                          <label>Razão Social</label>
                          <argon-input id="name" type="text" placeholder="RAZÃO SOCIAL" aria-label="Descrição"
                            v-model="v$.cliente.razaoSocial.$model" text-case="upper" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.razaoSocial.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Data Fechamento</label>
                          <datepicker v-model="v$.cliente.dataContrato.$model" minimum-view="day"
                            class="form-control text-bold text-center date-input" :locale="locale"
                            input-format="dd/MM/yyyy" placeholder="DD/MM/AAAA" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.dataContrato.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <label>Nome Fantasia</label>
                          <argon-input id="email" type="text" placeholder="NOME FANTASIA" aria-label="Cliente"
                            v-model="v$.cliente.nomeFantasia.$model" text-case="upper" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.nomeFantasia.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Tipo de Faturamento</label>
                          <select class="form-control" v-model="v$.cliente.tipoFaturamento.$model">
                            <option value="">Selecione...</option>
                            <option v-for="tipoFaturamento of tiposFaturamento" :key="tipoFaturamento.id"
                              :value="tipoFaturamento.id">
                              {{ tipoFaturamento.descricao }}
                            </option>
                          </select>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.tipoFaturamento.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>CPF/CNPJ</label>
                          <input type="tel" v-model="v$.cliente.documento.$model" class="form-control text-left" min="0"
                            v-mask="['###.###.###-##', '##.###.###/####-##']" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.documento.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label>Situação do Contrato</label>
                          <select class="form-control" v-model="v$.cliente.status.$model">
                            <option value="0">IMPLANTAÇÃO</option>
                            <option value="1">FATURADO</option>
                            <option value="2">BLOQUEADO</option>
                            <option value="3">CONGELADO</option>
                            <option value="4">CANCELADO</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label>Plano</label>
                          <select class="form-control" v-model="v$.cliente.plano.$model">
                            <option :value="null">Selecione...</option>
                            <option v-for="plano of planos" :key="plano.id" :value="plano">
                              {{ plano.descricao.toUpperCase() }}
                            </option>
                          </select>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.plano.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Vertical</label>
                          <select class="form-control" v-model="v$.cliente.vertical.$model">
                            <option value="">SELECIONE</option>
                            <option v-for="vertical in verticais" :value="vertical.id" :key="vertical.id">
                              {{ vertical.descricao }}
                            </option>
                          </select>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.vertical.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Quant. Usuário</label>
                          <input type="number" v-model="v$.cliente.quantidadeUsuarios.$model"
                            class="form-control text-center" min="0" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.quantidadeUsuarios.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Quant. UHs</label>
                          <input type="number" v-model="v$.cliente.uhs.$model" class="form-control text-center"
                            min="0" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.uhs.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label>Valor de Consultoria</label>
                          <money3 v-model="v$.cliente.valorConsultoria.$model" class="form-control text-right" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.valorConsultoria.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Valor do contrato</label>
                          <money3 v-model="v$.cliente.valorContrato.$model" class="form-control text-right" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.valorContrato.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Valor com desconto</label>
                          <money3 v-model="v$.cliente.valorComDesconto.$model" class="form-control text-right" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.valorContrato.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Valor Atual</label>
                          <money3 v-model="v$.cliente.valor.$model" class="form-control text-right"
                            :readonly="!isInsert" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.valor.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label>Contrato Modelo Novo</label>
                          <select class="form-control" v-model="v$.cliente.contratoModeloNovo.$model">
                            <option :value="false">NÃO</option>
                            <option :value="true">SIM</option>
                          </select>
                        </div>
                        <div class="col-md-3">
                          <label>Módulos Conferidos</label>
                          <select class="form-control" v-model="v$.cliente.modulosConferidos.$model">
                            <option :value="false">NÃO</option>
                            <option :value="true">SIM</option>
                          </select>
                        </div>
                        <div class="col-md-3">
                          <label for="">Closer</label>
                          <select class="form-control" v-model="v$.cliente.closer.$model">
                            <option :value="null">SELECIONE</option>
                            <option v-for="closer in closers" :value="closer" :key="closer.id">{{ closer.nome }}
                            </option>
                          </select>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.closer.$errors" :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label>Status</label>
                          <select class="form-control" v-model="v$.cliente.ativo.$model">
                            <option :value="true">ATIVO</option>
                            <option :value="false">INATIVO</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label>Telefone</label>
                          <input type="tel" class="form-control" placeholder="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']" v-model="v$.cliente.telefone.$model" />
                        </div>
                        <div class="col-md-3">
                          <label>Celular</label>
                          <input type="tel" class="form-control" placeholder="Celular"
                            v-mask="['(##) ####-####', '(##) #####-####']" v-model="v$.cliente.celular.$model" />
                        </div>
                        <div class="col-md-3">
                          <label for="">Cidade</label>
                          <input type="tel" class="form-control" disabled :value="cidadeLabel" />
                        </div>
                        <div class="col-md-3">
                          <label>Movidesk ID</label>
                          <input type="text" class="form-control" placeholder="Movidesk ID"
                            v-model="v$.cliente.movideskOrganizacaoId.$model" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label>ID Cliente Conexa</label>
                          <input type="number" class="form-control font-weight-bolder text-primary"
                            v-model="v$.cliente.conexaClienteId.$model" :disabled="!isInsert" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.conexaClienteId.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>ID Contrato Conexa</label>
                          <input type="number" class="form-control font-weight-bolder text-primary"
                            v-model="v$.cliente.conexaContratoId.$model" :disabled="!isInsert" />
                          <div class="input-errors" v-for="(error, index) of v$.cliente.conexaContratoId.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label>Empresa Conexa</label>
                          <select class="form-control font-weight-bolder text-primary"
                            v-model="v$.cliente.conexaUnidadeId.$model" :disabled="!isInsert">
                            <option value="">SELECIONE</option>
                            <option :value="3">BITZ</option>
                            <option :value="4">BS</option>
                          </select>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.conexaUnidadeId.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label>Observações</label>
                          <textarea class="form-control" cols="30" rows="5"
                            v-model="v$.cliente.observacoes.$model"></textarea>
                          <div class="input-errors" v-for="(error, index) of v$.cliente.observacoes.$errors"
                            :key="index">
                            <div class="text-danger text-xs">{{ error.$message }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="!cliente.ativo">
                        <hr class="mt-4 mb-2 dark">
                        <h6 class="text-center">
                          Informações de Cancelamento
                        </h6>
                        <div class="row">
                          <div class="col-md-3">
                            <label>Data Cancelamento</label>
                            <datepicker v-model="v$.cliente.dataInativo.$model" minimum-view="day"
                              class="form-control text-bold text-center date-input" :locale="locale"
                              input-format="dd/MM/yyyy" placeholder="DD/MM/AAAA" />
                            <div class="input-errors" v-for="(error, index) of v$.cliente.dataInativo.$errors"
                              :key="index">
                              <div class="text-danger text-xs">{{ error.$message }}</div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <label>Motivo</label>
                            <select class="form-control" v-model="v$.cliente.motivoCancelamento.$model">
                              <option value="0">SELECIONE UM MOTIVO</option>
                              <option value="1">PRODUTO</option>
                              <option value="2">CLIENTE</option>
                              <option value="3">FINANCEIRO</option>
                              <option value="4">COMERCIAL</option>
                              <option value="5">SUPORTE</option>
                              <option value="6">CONSULTORIA</option>
                            </select>
                            <div class="input-errors" v-for="(error, index) of v$.cliente.motivoCancelamento.$errors"
                              :key="index">
                              <div class="text-danger text-xs">{{ error.$message }}</div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label>Origem</label>
                            <select class="form-control" v-model="v$.cliente.origemCancelamento.$model">
                              <option value="0">SELECIONE A ORIGEM</option>
                              <option value="1">SOLICITAÇÃO</option>
                              <option value="2">INADIMPLÊNCIA</option>
                              <option value="3">ALTERAÇÃO CONTRATUAL</option>
                            </select>
                            <div class="input-errors" v-for="(error, index) of v$.cliente.origemCancelamento.$errors"
                              :key="index">
                              <div class="text-danger text-xs">{{ error.$message }}</div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label>Responsável</label>
                            <input type="text" class="form-control" :value="cliente.usuarioCancelamento"
                              placeholder="Responsável" disabled />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <label>Observações do Cancelamento</label>
                            <textarea class="form-control" cols="30" rows="5"
                              v-model="v$.cliente.observacoesCancelamento.$model"></textarea>
                            <div class="input-errors"
                              v-for="(error, index) of v$.cliente.observacoesCancelamento.$errors" :key="index">
                              <div class="text-danger text-xs">{{ error.$message }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div v-show="activeTabIndex == 1">
                    <div class="d-flex justify-content-center">
                      <h6 class="text-center">Módulos Contratados {{ cliente.modulos.length }}/{{ modulos.length }} -
                        Total: {{ filters.currency(totalModulos) }}
                      </h6>
                    </div>
                    <div class="row" v-show="isAdmin">
                      <div class="col-md-8">
                        <label>
                          Módulo
                          <span class="text-primary" v-if="clienteModulo.modulo">
                            (Valor sugerido: {{ filters.currency(clienteModulo.modulo.valorMinimo) }})
                          </span>
                        </label>
                        <select class="form-control" :class="moduloError ? 'is-invalid' : ''"
                          v-model="clienteModulo.modulo">
                          <option :value="null">Selecione um módulo...</option>
                          <option v-for="modulo of modulos" :key="modulo.id" :value="modulo">
                            {{ modulo.descricao }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <label>Valor do Contrato</label>
                        <money3 v-model="clienteModulo.valor" class="form-control text-center" />
                      </div>
                      <div class="col-md-2">
                        <label>&nbsp;</label>
                        <div class="d-flex">
                          <argon-button color="primary" size="md" :full-width="true" variant="contained"
                            @click="addModulo()">
                            Adicionar
                          </argon-button>
                        </div>
                      </div>
                    </div>
                    <div v-if="cliente.modulos.length > 0">
                      <div class="table-responsive">
                        <table id="products-list" class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>Módulo</th>
                              <th>Valor</th>
                              <th v-if="isAdmin" class="text-center">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(modulo, index) in cliente.modulos" :key="index">
                              <td>
                                <h6 class="my-auto text-xs">
                                  {{ modulo.modulo.descricao }}
                                </h6>
                              </td>
                              <td>
                                <div class="d-flex justify-content-center align-items-center text-xs text-bold">
                                  <money3 v-model="modulo.valor" class="form-control text-center form-control-sm"
                                    :disabled="!isAdmin" />
                                </div>
                              </td>
                              <td class="text-sm text-center" v-if="isAdmin">
                                <argon-button color="danger" size="xs" @click="removeModulo(modulo, index)">
                                  Excluir
                                </argon-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                  <div v-show="activeTabIndex == 2">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="text-center">Bases do Cliente</h6>
                      </div>
                      <span class="font-weight-bold text-dark" v-if="isAdmin">
                        <argon-button color="primary" size="sm" :full-width="true" variant="contained"
                          @click="openSelecionarEmpresaHotelModal()" data-bs-toggle="modal"
                          data-bs-target="#modal-selecionar-empresa">
                          Adicionar
                          <i class="fas fa-plus"></i>
                        </argon-button>
                      </span>
                    </div>
                    <div v-if="cliente.empresas.length > 0">
                      <table id="products-list" class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th>Empresa</th>
                            <th>Nome Fantasia</th>
                            <th>Status</th>
                            <th v-if="isAdmin" class="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(empresa, index) in cliente.empresas" :key="index">
                            <td>
                              <h4 class="my-auto text-xs font-weight-bolder">
                                {{ empresa.empresaId }}
                              </h4>
                            </td>
                            <td>
                              <h6 class="my-auto text-xs">
                                {{ getDescricaoEmpresa(empresa.empresaId) }}
                              </h6>
                            </td>
                            <td>
                              <h6 class="my-auto">
                                <badge-status :ativo="getStatusEmpresa(empresa.empresaId)" size="xs" />                                
                              </h6>
                            </td>
                            <td class="text-xs text-center" v-if="isAdmin">
                              <argon-button color="danger" size="xs" @click="removeEmpresa(empresa, index)">
                                Excluir
                              </argon-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-show="activeTabIndex == 3">
                    <div class="row">
                      <div class="mx-auto col-md-12">
                        <timeline-historico-cliente title="Histórico de alterações do cliente" :cliente="cliente" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-selecionar-empresa" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Selecionar Base
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>
                  Pesquisar
                </label>
                <input class="form-control text-uppercase" v-model="searchEmpresaHotel"
                  placeholder="ID, Nome Fantasia, CPF/CNPJ">
              </div>
            </div>
            <div class="table-pesquisa">
              <div class="row">
                <empresa-hotel-card v-for="empresa in empresasHotelFiltered" :key="empresa.id" :empresa="empresa"
                  @select="selectEmpresaHotel" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal" ref="closeModalButton">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { successToast, errorToast, showConfirm } from '@/utils/alerts';
import { isValidDocument } from '@/utils/document';
import Datepicker from 'vue3-datepicker'
import { ptBR } from 'date-fns/locale';
import setNavPills from "@/assets/js/nav-pills.js";
import TimelineHistoricoCliente from './components/TimelineHistoricoCliente.vue';
import api from '@/services/api'
import BackButton from '@/components/BackButton.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import NavBarCadastroCliente from './components/NavBarCadastroCliente.vue';
import EmpresaHotelCard from './components/EmpresaHotelCard.vue';
import BadgeStatus from '../components/BadgeStatus.vue';
import * as moment from 'moment';

export default {
  name: "Cliente",
  components: {
    ArgonInput,
    ArgonButton,
    Datepicker,
    TimelineHistoricoCliente,
    BackButton,
    SubmitButton,
    NavBarCadastroCliente,
    EmpresaHotelCard,
    BadgeStatus
  },
  created() {
    this.fetchModulos();
    this.fetchPlanos();
    this.fetchClosers();
    this.fetchEmpresasHotel();

    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  mounted() {
    setNavPills();
    this.user = this.$store.getters.getUser;
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      activeTabIndex: 0,
      user: null,
      locale: ptBR,
      planos: [],
      closers: [],
      modulos: [],
      moduloError: false,
      searchContratoFinanceiro: '',
      searchEmpresaHotel: '',
      contratoFinanceiro: null,
      contratosFinanceiro: [],
      contratosFinanceiroFiltered: [],
      empresaHotel: null,
      empresasHotel: [],
      empresasHotelFiltered: [],
      verticais: [
        { id: 1, descricao: 'MOTEL' },
        { id: 2, descricao: 'HOTEL' },
        { id: 3, descricao: 'CONDOMÍNIO' },
        { id: 4, descricao: 'RESTAURANTE' },
      ],
      tiposFaturamento: [
        { id: 1, descricao: 'MRR - Mensal' },
        { id: 2, descricao: 'ARR - Anual' },
        { id: 3, descricao: 'TRR - Trimestral' },
        { id: 4, descricao: 'SRR - Semestral' },
      ],
      clienteModulo: {
        modulo: null,
        valor: 0.0
      },
      cliente: {
        id: null,
        ativo: true,
        modulos: [],
        empresas: [],
        bairro: '',
        cep: '',
        cidade: '',
        closer: null,
        closerName: '',
        dataContrato: null,
        dataInativo: null,
        documento: '',
        email: '',
        endereco: '',
        telefone: '',
        celular: '',
        nomeFantasia: '',
        plano: null,
        porte: '',
        razaoSocial: '',
        status: 0,
        tipoFaturamento: 1,
        uf: '',
        uhs: 1,
        valor: 0,
        valorComDesconto: 0,
        valorContrato: 0,
        vertical: 2,
        hotelEmpresaId: null,
        zelloContratoId: null,
        conexaClienteId: null,
        conexaUnidadeId: 3,
        conexaContratoId: null,
        origemCancelamento: 0,
        motivoCancelamento: 0,
        observacoesCancelamento: '',
        observacoes: '',
        contratoModeloNovo: false,
        modulosConferidos: false,
        quantidadeUsuarios: 1,
        valorConsultoria: 0,
        movideskOrganizacaoId: null,
        usuarioCancelamento: '',
      }
    }
  },
  validations() {
    const { ativo } = this.cliente;

    const validate = {
      cliente: {
        razaoSocial: {
          required: helpers.withMessage('Razão Social é obrigatória', required),
        },
        nomeFantasia: {
          required: helpers.withMessage('Nome Fantasia é obrigatório', required),
        },
        documento: {
          required: helpers.withMessage('Documento é obrigatório', required),
        },
        ativo: {
          required: helpers.withMessage('Status é obrigatório', required),
        },
        status: {
          required: helpers.withMessage('Situação é obrigatória', required),
        },
        valor: {
          required: helpers.withMessage('Valor é obrigatório', required),
        },
        valorContrato: {
          required: helpers.withMessage('Valor é obrigatório', required),
        },
        valorComDesconto: {},
        valorConsultoria: {},
        telefone: {},
        celular: {},
        tipoFaturamento: {
          required: helpers.withMessage('Tipo de faturamento é obrigatório', required),
        },
        plano: {
          required: helpers.withMessage('Plano é obrigatório', required),
        },
        dataContrato: {
          required: helpers.withMessage('Data do contrato é obrigatória', required),
        },
        quantidadeUsuarios: {
          minValue: helpers.withMessage('Quantidade de usuário é obrigatória', minValue(1)),
        },
        uhs: {
          minValue: helpers.withMessage('Quantidade de Uhs é obrigatória', minValue(1)),
        },
        vertical: {
          required: helpers.withMessage('Vertical é obrigatória', required),
        },
        closer: {
          required: helpers.withMessage('Closer é obrigatório', required),
        },
        closerName: {},
        dataInativo: {},
        origemCancelamento: {},
        motivoCancelamento: {},
        observacoesCancelamento: {},
        observacoes: {},
        zelloContratoId: {},
        hotelEmpresaId: {},
        contratoModeloNovo: {},
        modulosConferidos: {},
        movideskOrganizacaoId: {},
        conexaClienteId: {},
        conexaContratoId: {},
        conexaUnidadeId: {},
      },
    };

    if (!ativo) {
      validate.cliente.dataInativo = {
        minValue: helpers.withMessage('Data de Cancelamento é obrigatória', required)
      }
      validate.cliente.origemCancelamento = {
        minValue: helpers.withMessage('Origem é obrigatória', minValue(1))
      }
      validate.cliente.motivoCancelamento = {
        minValue: helpers.withMessage('Motivo é obrigatório', minValue(1))
      }
      validate.cliente.observacoesCancelamento = {
        minValue: helpers.withMessage('Observações do cancelamento são obrigatórias', required)
      }
    }

    if (this.id == 'new') {
      validate.cliente.conexaClienteId = {
        minValue: helpers.withMessage('Cliente Conexa é obrigatório', minValue(1)),
        required: helpers.withMessage('Cliente Conexa é obrigatório', required)
      }
      validate.cliente.conexaContratoId = {
        minValue: helpers.withMessage('Contrato Conexa é obrigatório', minValue(1)),
        required: helpers.withMessage('Contrato Conexa é obrigatório', required)
      }

      validate.cliente.conexaUnidadeId = {
        required: helpers.withMessage('Unidade Conexa é obrigatório', required)
      }
    }

    return validate;
  },
  methods: {
    onNavButtonClick(index) {
      this.activeTabIndex = index;
    },
    openSelecionarContratosModal() {
      this.searchContratoFinanceiro = '';
    },
    selectContratoFinanceiro(contrato) {
      this.contratoFinanceiro = contrato;
      this.cliente.zelloContratoId = contrato.id;
    },
    openSelecionarEmpresaHotelModal() {
      this.searchEmpresaHotel = '';
    },
    selectEmpresaHotel(empresa) {
      if (this.cliente.empresas.some(item => item.empresaId == empresa.id)) {
        errorToast('Essa empresa ja está na lista');
        return;
      }

      this.cliente.empresas.push({
        id: null,
        empresaId: empresa.id
      });

      this.$refs.closeModalButton.click();
    },
    fetchModulos() {
      api.get(`/modulos`)
        .then(({ data }) => {
          this.modulos = data;
        })
        .catch(e => console.log(e));
    },
    fetchClosers() {
      api.get(`/closers`)
        .then(({ data }) => {
          this.closers = data;
        })
        .catch(e => console.log(e));
    },
    fetchPlanos() {
      api.get(`/planos`)
        .then(({ data }) => {
          const planos = data.map(item => {
            delete item.modulos;
            return item;
          })
          this.planos = planos;
        })
        .catch(e => console.log(e));
    },
    setClienteEmpresaHotel() {
      const { hotelEmpresaId } = this.cliente;

      if (hotelEmpresaId && !this.empresaHotel) {
        const empresa = this.empresasHotel.find(item => item.id == hotelEmpresaId);

        if (empresa) {
          this.empresaHotel = empresa;
        }
      }
    },
    fetchEmpresasHotel() {
      api.get(`/clientes/empresas-hotel`)
        .then(({ data }) => {
          this.empresasHotel = data;
          this.empresasHotelFiltered = data;

          this.setClienteEmpresaHotel();
        })
        .catch(e => console.log(e));
    },
    fetchData() {
      const { id } = this;

      api.get(`/clientes/${id}`)
        .then(({ data }) => {
          this.cliente = {
            ...data,
            dataContrato: moment(data.dataContrato).toDate(),
            dataInativo: moment(data.dataInativo).toDate()
          };

          this.setClienteEmpresaHotel();
        })
        .catch(e => console.log(e));
    },
    setModuloError() {
      this.moduloError = true;

      setTimeout(() => {
        this.moduloError = false
      }, 5000);
    },
    resetPlanoModulo() {
      this.clienteModulo = {
        modulo: null,
        valor: 0.0
      }
    },
    addModulo() {
      const { modulo, valor } = this.clienteModulo;

      if (!modulo) {
        this.setModuloError();
        return;
      }

      if (this.cliente.modulos.some(item => item.modulo.id == modulo.id)) {
        errorToast('Esse módulo ja está na lista');
        return;
      }

      this.cliente.modulos.push({
        id: null,
        modulo,
        valor
      });

      this.resetPlanoModulo();
    },
    async removeModulo(modulo, index) {
      const confirm = await showConfirm(`${modulo.modulo.descricao}`, 'Confirma exclusão do módulo?');

      if (!confirm) {
        return
      }

      this.cliente.modulos.splice(index, 1);
    },
    async removeEmpresa(empresa, index) {
      const nomeFantasia = this.getDescricaoEmpresa(empresa.empresaId);
      const confirm = await showConfirm(`${empresa.empresaId} - ${nomeFantasia}`, 'Confirma exclusão da base?');

      if (!confirm) {
        return
      }

      this.cliente.empresas.splice(index, 1);
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      const { documento } = this.cliente;

      if (!isValidDocument(documento)) {
        errorToast('Documento inválido')
        return;
      }

      try {
        const payload = {
          ...this.cliente
        }

        await (this.id == 'new' ?
          api.post('/clientes', payload) :
          api.patch(`/clientes/${this.id}`, payload));

        successToast('Cliente salvo com sucesso.');

        this.$router.push({ name: 'Clientes' });

      } catch (e) {
        const { data } = e;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
    filterEmpresasHotel() {
      const search = this.searchEmpresaHotel.toLowerCase();

      let empresas = [
        ...this.empresasHotel
      ];

      if (search) {
        empresas = empresas.filter(item => (item.search).toString().toLowerCase().indexOf(search) > -1);
      }

      this.empresasHotelFiltered = empresas;
    },
    back() {
      this.$router.back();
    },
    getDescricaoEmpresa(id) {
      const empresa = this.empresasHotel.find(item => item.id == id);

      return empresa ? empresa.nome_fantasia : '';
    },
    getStatusEmpresa(id) {
      const empresa = this.empresasHotel.find(item => item.id == id);

      return empresa ? empresa.ativo : 0;
    },
  },
  computed: {
    isInsert() {
      return this.id == 'new';
    },
    empresaHotelLabel() {
      const { empresaHotel } = this;

      return empresaHotel ? `${empresaHotel.id} - ${empresaHotel.nome_fantasia} - ${empresaHotel.cidade}` : '';
    },
    totalModulos() {
      return this.cliente.modulos.reduce((acc, item) => acc + Number(item.valor), 0);
    },
    isAdmin() {
      return this.user?.tipo == 2 || this.user?.id == 15;
    },
    cidadeLabel() {
      const { cidade, uf } = this.cliente;

      return `${cidade} - ${uf}`.toUpperCase();
    }
  },
  watch: {
    'clienteModulo.modulo': function (newModulo) {
      this.moduloError = false;
      if (newModulo) {
        this.clienteModulo.valor = newModulo.valorMinimo;
      }
    },
    searchEmpresaHotel: function () {
      this.filterEmpresasHotel();
    }
  }
};
</script>
<style lang="scss" scoped>
textarea {
  resize: none;
}

.table-pesquisa {
  height: 480px;
  max-height: 480px;
  overflow: scroll;
  padding: 0.5em 0;
}

.contrato-financeiro {
  background-color: #fff !important;
  border-right: 1px solid #d2d6da !important;
  font-weight: bold;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  cursor: pointer;
  border-left: 1px solid #d2d6da !important;
  border-right: 1px solid #d2d6da !important;
}

#modal-selecionar-empresa {
  .modal-dialog {
    .modal-body {
      padding: 0px 12px;
    }
  }
}
</style>
