<template>
  <span :class="['badge', 'badge-sm', color]">
    {{ label }}
  </span>
</template>

<script>
import moment from 'moment';

export default {
  name: "BadgeTipoReceita",
  props: {
    vencimento: {
      type: [String, Date],
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    label() {
      const { vencimento } = this;

      const diff = moment().diff(moment(vencimento), 'days');

      return `${diff} dias`;
    },
    color() {
      const { vencimento } = this;

      const diff = moment().diff(moment(vencimento), 'days');

      if (diff > 0) {
        return 'badge-secondary';
      }

      return 'badge-success';
    }
  }
}
</script>
