<template>
  <div class="card">
    <router-link :to="`/admin/pages/clientes-vertical/${tipo.toLowerCase()}`">
      <button type="button" class="btn-info-card btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm"
        data-bs-toggle="tooltip" data-bs-placement="bottom" title data-bs-original-title="See which ads perform better">
        <i class="fas fa-info" aria-hidden="true"></i>
      </button>
    </router-link>
    <div class="text-center card-body">
      <h2 class="text-gradient" :class="`text-${color}`">
        <span :id="id" :countTo="total.quantidade"></span>
      </h2>
      <h5 class="mb-0 font-weight-bolder">{{ filters.currencyWithPrefix(total.total) }}</h5>
      <h3 class="mb-0 text-sm text-bold">{{ description }}</h3>
    </div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
import * as uuid from 'uuid';

export default {
  name: "VerticalTotalCard",
  props: {
    description: {
      type: String,
      default: "",
    },
    tipo: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "success",
    },
    total: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: `count_${uuid.v4()}`,
    }
  },
  methods: {
    animate() {
      const element = document.getElementById(this.id);

      if (element) {
        const countUp = new CountUp(
          this.id,
          document.getElementById(this.id).getAttribute("countTo"),
        );

        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
      } else {
        console.log('Erro ao recuperar o elemento');
      }
    }
  },
  watch: {
    'total.quantidade': function (newValue) {
      if (newValue) {
        setTimeout(() => {
          this.animate();
        }, 200);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-info-card {
  position: absolute;
  margin: 8px;
  right: 0;
  top: 0;
  z-index: 100;
}

.card {
  .card-body {
    padding: 0.5rem;
  }
}
</style>
