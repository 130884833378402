<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
  <side-nav v-if="showSideNav" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />    
  </main>
</template>
<script>
import SideNav from "@/examples/SideNavs/SideNav.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {
    SideNav,
    Navbar,
    AppFooter
  },
  mounted() {    
    this.setSidebarLayout();
  },
  methods: {
    ...mapMutations(['setSidebarLayout']),
  },
  computed: {
    ...mapState([
      "layout",
      "showSideNav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  }
};
</script>
