<template>
  <div class="py-2 container-fluid">
    <month-navigator v-model="mes" />
    <div class="row">
      <totais-vendas-card title="Vendas" :count="total" />
      <totais-vendas-card title="Clientes" :count="quantidade" />
      <totais-vendas-card title="Ticket Médio" :count="ticketMedio" />
      <totais-vendas-card title="UpSell" :count="upSell" :on-click="onUpSellClick" />
    </div>
    <div class="row">
      <div class="col-md-6 mt-sm-2">
        <pizza-vendas-card title="Vendas X Closer" :series="vendas.closers" />
      </div>
      <div class="col-md-6 mt-sm-2">
        <pizza-vendas-card title="Valor X Closer" :series="seriesCloserValor" :is-float-value="true" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 mt-sm-2">
        <vendas-porte-card title="Vendas X Porte" :series="vendas.portes" />
      </div>
      <div class="col-lg-6 col-md-6 mt-sm-2">
        <evolucao-ticket-medio-card title="Ticket Médio(12 meses)" :series="vendas.ticketMedio" />
      </div>
    </div>
  </div>
</template>

<script>
import VendasPorteCard from "./components/VendasPorteCard.vue";
import TotaisVendasCard from "./components/TotaisVendasCard.vue";
import PizzaVendasCard from "./components/PizzaVendasCard.vue";
import MonthNavigator from "@/components/MonthNavigator.vue";
import EvolucaoTicketMedioCard from "./components/EvolucaoTicketMedioCard.vue";
import moment from "moment";
import api from "@/services/api";

export default {
  name: "Comercial",
  components: {
    PizzaVendasCard,
    VendasPorteCard,
    TotaisVendasCard,
    MonthNavigator,
    EvolucaoTicketMedioCard
  },
  data() {
    return {
      mes: moment().startOf('month').toDate(),
      vendas: {
        closers: [],
        portes: [],
        ticketMedio: [],
        totais: {
          quantidade: 0,
          total: 0,
          ticketMedio: 0,
        },
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const mes = moment(this.mes).format('YYYY-MM-DD');

      api.get(`/dashboard-comercial/vendas`, {
        params: {
          mes
        }
      }).then(({ data }) => {

        this.vendas = data;
      });
    },
    onUpSellClick() {
      const mes = moment(this.mes).format('YYYY-MM');

      this.$router.push({
        name: 'UpSells Mensal',
        params: { mes }
      });
    }
  },
  computed: {
    total() {
      const { totais } = this.vendas;

      return this.filters.currencyWithPrefix(totais.total);
    },
    quantidade() {
      const { totais } = this.vendas;

      return totais.quantidade.toLocaleString('pt-BR');
    },
    ticketMedio() {
      const { totais } = this.vendas;
      return this.filters.currencyWithPrefix(totais.ticketMedio);
    },
    upSell() {
      const { totais } = this.vendas;

      return this.filters.currencyWithPrefix(totais.upSell);
    },
    seriesCloserValor() {
      const { closers } = this.vendas;

      return closers.map(({ label, valor }) => ({
        label,
        value: valor.toFixed(2)
      }));
    },
  },
  watch: {
    mes: function () {
      this.fetchData();
    }
  }
};
</script>
