<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <nenhum-registro-encontrado :hidden="series.length > 0" :height="200" />
      <div class="chart" :hidden="series.length == 0">
        <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import NenhumRegistroEncontrado from "@/components/NenhumRegistroEncontrado.vue";
import Chart from "chart.js/auto";
import * as uuid from 'uuid';

export default {
  name: "EvolucaoTicketMedioCard",
  components: {
    NenhumRegistroEncontrado,
  },
  props: {
    title: {
      type: String,
      default: "Revenue",
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.chartId = `chart-pie_${uuid.v4()}`;
  },
  data() {
    return {
      chartId: "chart-line",
    };
  },
  methods: {
    createChart() {
      var lineChart = document.getElementById(this.chartId).getContext("2d");
      var gradientStroke1 = lineChart.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(94, 114, 228,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors
      // Line chart

      let chartStatus = Chart.getChart(this.chartId);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const labels = this.series.map((serie) => serie.label);
      const values = this.series.map((serie) => serie.value);

      new Chart(lineChart, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              label: "Ticket Médio",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 2,
              pointBackgroundColor: "#4BB543 ",
              borderColor: "#4BB543 ",
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              backgroundColor: gradientStroke1,
              fill: true,
              data: values,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      });
    },
  },
  watch: {
    series: {
      handler() {
        this.createChart();
      },
      deep: true,
    },
  },
};
</script>
