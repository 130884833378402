<template>
    <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-success btn-xs mb-0" @click="previous()">
            <i class="fas fa-chevron-left"></i> Ant
        </button>
        <h6 class="mb-2 text-uppercase">
            {{ filters.formatDate(currentMonth, 'MMMM - YYYY') }}
        </h6>
        <button type="button"
            :class="['btn', isNextVisible ? 'btn-outline-success' : 'btn-outline-secondary', 'btn-xs mb-0']"
            :disabled="!isNextVisible" @click="next()">
            Próx <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</template>
<script>
import * as moment from 'moment';

export default {
    props: {
        currentMonth: {
            type: Date,
            default: moment().toDate()
        },
        onChangeDate: {
            type: Function,
            required: true,
        },
    },
    methods: {
        next() {
            const newMonth = moment(this.currentMonth).add(1, 'month').toDate();

            this.onChangeDate(newMonth);
        },
        previous() {
            const newMonth = moment(this.currentMonth).subtract(1, 'month').toDate();

            this.onChangeDate(newMonth);
        },
    }
    ,
    computed: {
        isNextVisible() {
            const { currentMonth } = this;

            const dateNow = moment()
                .startOf('month')
                .toDate();

            const isNextVisible = moment(dateNow).isAfter(currentMonth);

            return isNextVisible;
        }
    }

}
</script>
<style lang="">
    
</style>