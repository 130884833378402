<template>
  <div class="py-2 container-fluid">
    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-6 col-sm-12 mt-sm-2">
        <mini-statistics-card title="Módulos" :value="filters.currencyWithPrefix(modulos)" :icon="{
          component: 'fas fa-coins',
          background: 'bg-gradient-primary',
          shape: 'rounded-circle'
        }" />
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-2 mt-sm-2">
        <mini-statistics-card title="Consultoria" :value="filters.currencyWithPrefix(consultoria)" :icon="{
          component: 'fas fa-user-tie',
          background: 'bg-gradient-info',
          shape: 'rounded-circle'
        }" />
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-2 mt-sm-2">
        <mini-statistics-card title="Total" :value="filters.currencyWithPrefix(total)" :icon="{
          component: 'fas fa-dollar-sign',
          background: 'bg-gradient-success',
          shape: 'rounded-circle'
        }" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">          
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="contratos-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Documento</th>
                    <th>Data</th>
                    <th>Faturamento</th>
                    <th>Valor</th>
                    <th>Consultoria</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contrato of contratos" :key="contrato.id">
                    <td>
                      <span class="my-2 text-xs">
                        {{ contrato.id }}
                      </span>
                    </td>
                    <td class="text-uppercase">
                      <span class="my-2 text-xs">
                        {{ contrato.nome }}
                      </span>
                    </td>
                    <td>
                      <span class="my-2 text-xs">
                        {{ filters.formatDocument(contrato.documento) }}
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-primary">
                        {{ contrato.data_contrato }}
                      </span>
                    </td>
                    <td v-if="categoria == 'churn'">
                      <span class="badge badge-danger">
                        {{ contrato.data_cancelamento }}
                      </span>
                    </td>
                    <td>
                      <badge-tipo-faturamento :tipo-faturamento="contrato.tipo_faturamento" />
                    </td>
                    <td class="text-right text-dark text-bold">
                      {{ filters.currency(contrato.valor) }}
                    </td>
                    <td class="text-right text-dark text-bold">
                      {{ filters.currency(contrato.valor_consultoria) }}
                    </td>
                    <td class="text-center text-dark text-bold">
                      <router-link :to="{
                        name: 'Upsell', params: {
                          id: contrato.id
                        }
                      }" class="btn btn-xs btn-primary">
                        Editar
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import BadgeTipoFaturamento from "./components/BadgeTipoFaturamento.vue";
import { DataTable } from "simple-datatables";
import api from './../../services/api';

export default {
  name: "ProductsList",
  components: {
    MiniStatisticsCard,
    BadgeTipoFaturamento
  },
  created() {
    const { mes } = this.$route.params;

    this.mes = mes;

    this.fetchData();
  },
  data() {
    return {
      categoria: 'upsell',
      mes: '',
      contratos: [],
      dataTable: null,
    }
  },
  methods: {
    fetchData() {
      const { categoria, mes } = this;

      api.get(`/dashboard-gerencial/categorias`, {
        params: {
          categoria,
          mes
        }
      }).then(response => {
        const { data } = response;

        this.contratos = data;

      }).catch(e => console.log(e));
    },
    setDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }

      this.dataTable = new DataTable("#contratos-list", {
        searchable: true,
        fixedHeight: false,
        paging: true,
        language: 'pt-BR',
        perPage: 10,
        labels: {
          perPage: "Registros por página",
          info: "Visualizando {start} até {end} de {rows} registros",
          placeholder: "Pesquisar",
        },
      });

    },
    exportData() {
      const { categoria, mes } = this;
      const type = 'csv';

      let data = {
        type: type,
        filename: `contratos-${categoria}-${mes}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    }
  },
  computed: {
    modulos() {
      let total = 0;
      total = this.contratos.reduce((value, item) => {
        return value += Number(item.valor);
      }, 0);

      return total;
    },
    consultoria() {
      let total = 0;
      total = this.contratos.reduce((value, item) => {
        return value += Number(item.valor_consultoria);
      }, 0);

      return total;
    },
    total() {
      let total = 0;
      total = this.contratos.reduce((value, item) => {
        return value += Number(item.valor) + Number(item.valor_consultoria);
      }, 0);

      return total;
    }
  },
  watch: {
    contratos: function (newValue, oldValue) {
      if (newValue != oldValue) {
        setTimeout(() => {
          this.setDataTable();
        }, 50);
      }
    }
  }
};
</script>