<template>
  <div class="mb-3 timeline-block">
    <span class="timeline-step p-3" :class="`${$parent.dark ? 'bg-dark' : 'bg-'}${color}`">
      <i :class="`text-${icon.color} text-gradient ${icon.component}`"></i>
    </span>
    <div class="timeline-content">
      <h6 class="mb-0 text-sm" :class="$parent.dark ? 'text-white' : 'text-dark'">
        <div class="d-flex justify-content-between">
          {{ filters.formatDateTime(dateTime, 'DD/MM/YYYY HH:mm') }}
          <span class="text-dark text-xs">
            Usuário: <strong>{{ usuario ?? 'Automação' }}</strong>
          </span>
        </div>
      </h6>
      <div v-for="(item, index) of items" :key="index" class="mt-2 mb-2 text-sm"
        :class="$parent.dark ? 'text-white' : 'text-dark'">
        <timeline-item-descricao :descricao="item.descricao" />

      </div>

      <span v-for="(badge, index) of badges" :key="index" :class="`badge badge-sm bg-gradient-${icon.color} me-1`">
        {{ badge }}
      </span>
    </div>
  </div>
</template>
<script>
import TimelineItemDescricao from "./TimelineItemDescricao.vue";

export default {
  name: "TimelineItem",
  components: {
    TimelineItemDescricao,
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
      component: String,
      color: String,
    },
    title: {
      type: String,
      required: true,
    },
    dateTime: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    badges: {
      type: Array,
      default: () => [],
    },

  },
  computed: {
    usuario() {
      const { items } = this;
      return items.length ? items[0].usuario : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 100%;
  }
}
</style>
