<template>
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex justify-content-between align-content-center">
                            <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']"
                                @click="previous()" :disabled="!isPreviousActive">
                                <i class="fas fa-chevron-left"></i> Anterior
                            </button>
                            <h6 class="text-uppercase text-lg">
                                {{ title }}
                            </h6>
                            <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']"
                                @click="next()" :disabled="!isNextActive">
                                Próximo <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "MonthNavigator",
    emits: ['update:modelValue'],
    props: {
        color: {
            type: String,
            default: "primary",
        },
        modelValue: {
            type: Number,
            default: 1,
        },
        title: {
            type: String,
            default: "***",
        },
        min: {
            type: Number,
            default: 1,
        },
        max: {
            type: Number,
            default: null,
        },
    },
    methods: {
        next() {
            const value = this.modelValue + 1;

            if (this.max != null && value > this.max) {
                return;
            }

            this.$emit('update:modelValue', value);
        },
        previous() {
            const value = this.modelValue - 1;

            if (this.min != null && value < this.min) {
                return;
            }

            this.$emit('update:modelValue', value);
        },
    },
    computed: {
        value: {
            get() {
                return this.currentMonth;
            },
        },
        isNextActive() {
            return this.max == null || this.modelValue < this.max;
        },
        isPreviousActive() {
            return this.min == null || this.modelValue > this.min;
        },
    }
}
</script>
<style lang="scss" scoped>
h6 {
    margin-bottom: 0 !important;
}
</style>