<template>
    <div class="card card-zonas-nps">
        <div class="pb-2 mt-0 card-body">
            <img src="@/assets/img/zona-pontuacao-nps.png" alt="Zona de Pontuação" class="img-fluid" />
        </div>
    </div>
</template>
<script>

export default {
    name: "NpsZonaPontuacaoCard",
    props: {
        percentual: {
            type: [String, Number],
            default: "",
        },
    },
    computed: {
        value() {
            return this.percentual ?? 0;
        },
    },
}
</script>
<style lang="scss" scoped>
.card-zonas-nps {
    height: 290px;

    img {
        height: 270px;
        image-rendering: auto;
    }
}
</style>