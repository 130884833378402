<template>
  <div class="card mb-3">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-1 overflow-text">
            {{ cliente.nomeFantasia }}
          </h6>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-between">
        <div class="overflow-text">
          <i class="far fa-calendar-alt me-1" aria-hidden="true"></i>
          <small>
            {{ filters.formatDate(onboarding.createdAt, 'DD MMMM YYYY HH:mm') }}
          </small>
        </div>
        <div class="overflow-text text-right">
          <small class="text-primary font-weight-bold">{{ cliente.closer?.nome ?? cliente.closerName }}</small>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="col-md-12 d-flex justify-content-between">
        <badge-vertical :vertical="cliente.vertical" size="sm" />
        <badge-plano :plano="cliente.plano" size="sm" />
      </div>
      <h6 class="mb-1 mt-0 text-center">
      </h6>
      <ul class="list-group">
        <onboarding-card-item :title="`Painel - ${cliente.id}`" :logs="onboarding.painelMeta" :start="cliente.createdAt"
          :end="onboarding.createdAt" :status="true" @on-log-click="onLogClick" />
        <onboarding-card-item title="Módulos" :start="onboarding.createdAt" :end="onboarding.modulosData"
          :status="onboarding.modulosStatus" :logs="onboarding.modulosMeta" @on-log-click="onLogClick" />
        <onboarding-card-item title="Movidesk" :start="onboarding.createdAt" :end="onboarding.movideskData"
          :status="onboarding.movideskStatus" :logs="onboarding.movideskMeta" @on-log-click="onLogClick" />
        <onboarding-card-item :title="`Zello - ${cliente.zelloContratoId}`" :start="onboarding.createdAt" :end="onboarding.zelloData"
          :status="onboarding.zelloStatus" :logs="onboarding.zelloMeta" @on-log-click="onLogClick"
          :id="onboarding.id" />
        <onboarding-card-item :title="`Hotel - ${cliente.hotelEmpresaId}`" :start="onboarding.createdAt" :end="onboarding.hotelData"
          :status="onboarding.hotelStatus" :logs="onboarding.hotelMeta" @on-log-click="onLogClick"
          :id="onboarding.id" />
        <onboarding-card-item :title="`Conexa Cliente - ${cliente.conexaClienteId}`" :start="onboarding.createdAt" :end="onboarding.conexaClienteData"
          :status="onboarding.conexaClienteStatus" :logs="onboarding.conexaClienteMeta" @on-log-click="onLogClick"
          :id="onboarding.id" />
        <onboarding-card-item :title="`Conexa Contrato - ${cliente.conexaContratoId}`" :start="onboarding.createdAt" :end="onboarding.conexaContratoData"
          :status="onboarding.conexaContratoStatus" :logs="onboarding.conexaContratoMeta" @on-log-click="onLogClick"
          :id="onboarding.id" />
      </ul>
    </div>
  </div>
</template>

<script>
import BadgeVertical from "@/views/pages/components/BadgeVertical.vue";
import BadgePlano from "@/views/pages/components/BadgePlano.vue";
import OnboardingCardItem from "./OnboardingCardItem.vue";

export default {
  name: "OnboardingCard",
  emits: ['on-log-click'],
  components: {
    OnboardingCardItem,
    BadgeVertical,
    BadgePlano,
  },
  props: {
    onboarding: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {

    }
  },
  methods: {
    onLogClick(data) {
      this.$emit('on-log-click', {
        cliente: this.cliente,
        ...data
      });
    }
  },
  computed: {
    cliente() {
      return this.onboarding.cliente;
    },
  }
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;

  .card-body {
    padding: 1rem;

    label {
      text-align: left;
    }
  }
}
</style>
