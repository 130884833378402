<template>
  <div class="card my-2" @click="onButtonClick()">
    <div class="pt-4 pb-3 text-center card-header">
      <span class="text-uppercase font-weight-bold text-dark overflow-text">
        {{ empresa.nomeFantasia }}
      </span>
      <p class="mb-0 text-sm text-muted overflow-text">
        <small>
          {{ empresa.empresaId }}
        </small>
      </p>
    </div>
    <div class="pt-0 text-center card-body">
      <div class="d-flex justify-content-between">
        <label>Reserva</label>
        <badge-semaforo :cor="empresa.statusReserva" />
      </div>
      <hr class="mt-0 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Hospedagem</label>
        <h6 class="mb-1 font-weight-bolder">
          <badge-semaforo :cor="empresa.statusHospedagem" />
        </h6>
      </div>
      <hr class="m-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Acesso</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-semaforo :cor="empresa.statusAcesso" />
        </h6>
      </div>
      <hr class="m-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>NPS</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-semaforo :cor="getCorNps(empresa.mediaNps)" />
        </h6>
      </div>
      <hr class="m-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Vertival</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-vertical :vertical="empresa.tipo" size="sm" />
        </h6>
      </div>
      <hr class="m-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Status</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-status-empresa :status="empresa.status" size="sm" />
        </h6>
      </div>
      <hr class="m-2 horizontal dark">
      <div class="d-flex justify-content-between">
        <label>Tempo de Contrato</label>
        <h6 class="mb-0 font-weight-bolder">
          <badge-tempo-vida :tempo-vida="empresa.tempoContrato" size="sm" />
        </h6>
      </div>

      <button class="mb-0 btn btn-md btn-icon d-lg-block bg-dark text-white text-center" data-bs-toggle="modal"
        data-bs-target="#modal-info-semaforo" ref="buttonOpenModal" style="display: none !important">
      </button>      
    </div>
  </div>

</template>

<script>
import BadgeSemaforo from "@/views/pages/components/BadgeSemaforo.vue";
import BadgeTempoVida from "@/views/pages/components/BadgeTempoVida.vue";
import BadgeStatusEmpresa from "@/views/pages/components/BadgeStatusEmpresa.vue";
import BadgeVertical from "@/views/pages/components/BadgeVertical.vue";

export default {
  name: "EmpresaSemaforoCard",
  components: {
    BadgeSemaforo,
    BadgeTempoVida,
    BadgeStatusEmpresa,
    BadgeVertical
  },
  props: {
    empresa: {
      type: Object,
      required: true,
      default: () => { }
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      specifications: [
        {
          label: 'Java',
          includes: true
        }
      ]
    }
  },
  methods: {
    getCorNps(nps) {
      return nps < 7 ? 'VERMELHO' : nps < 9 ? 'AMARELO' : 'VERDE';
    },
    onButtonClick() {
      this.$refs.buttonOpenModal.click();
      this.onClick(this.empresa);
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;
  cursor: pointer;

  label {
    margin-bottom: 0 !important;
  }


  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
