<template>
    <div id="modal-selecionar-customer-success" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog mt-lg-10  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">
                        Selecionar Customer Success
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>
                                Pesquisar
                            </label>
                            <input class="form-control text-uppercase" v-model="searchCustomerSuccesses"
                                placeholder="ID, Nome">
                        </div>
                    </div>
                    <div class="table-pesquisa">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Nome
                                    </th>
                                    <th>
                                        Ação
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="customerSuccess of customerSuccessesFiltered" :key="customerSuccess.id">
                                    <td>
                                        <span class="my-2 text-xs">
                                            {{ customerSuccess.id ?? '***' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="my-4 text-xs text-bold">
                                            {{ customerSuccess.nome.toUpperCase().substr(0, 30) }}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary btn-xs"
                                            @click="selectCustomerSuccess(customerSuccess)" data-bs-dismiss="modal">
                                            Selecionar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api';

export default {
    name: "ModalSelecionarCustomerSuccess",
    props: {
        onSelectCustomerSuccess: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            searchCustomerSuccesses: '',
            customerSuccesses: [],
            customerSuccessesFiltered: [],
        }
    },
    mounted() {
        this.fetchCustomerSuccesses();
    },
    methods: {
        clear() {
            this.searchCustomerSuccesses = '';
        },
        fetchCustomerSuccesses() {
            api.get(`/customer-successes`)
                .then(({ data }) => {
                    this.customerSuccesses = data;
                    this.customerSuccessesFiltered = data;
                })
                .catch(e => console.log(e));
        },
        filterCustomerSuccesses() {
            const search = this.searchCustomerSuccesses.toLowerCase();

            let customerSuccesses = [
                ...this.customerSuccesses
            ];

            if (search) {
                customerSuccesses = customerSuccesses.filter(item =>                    
                    (item.nome ?? '').toLowerCase().indexOf(search) > -1
                );
            }

            this.customerSuccessesFiltered = customerSuccesses;
        },
        selectCustomerSuccess(customerSuccess) {
            this.onSelectCustomerSuccess(customerSuccess);
        }
    },
    watch: {
        searchCustomerSuccesses: function () {
            this.filterCustomerSuccesses();
        }
    }

}
</script>
<style lang="scss" scoped>
.table-pesquisa {
    height: 400px;
    max-height: 400px;
    overflow: scroll;
}
</style>