<template>
  <span :class="['badge', `badge-${size}`, `badge-${ativo ? 'success' : 'danger'}`]">
    {{ ativo ? 'ATIVO' : 'INATIVO' }}
  </span>
</template>
<script>
export default {
  name: "BadgeStatus",
  props: {
    ativo: {
      type: [Boolean, Number],
      default: true
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>
