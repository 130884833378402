<template>
  <argon-badge :size="size" variant="gradient" :color="color">
    {{ label }}
  </argon-badge>
</template>
<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "BadgeMotivoCancelamento",
  components: {
    ArgonBadge,
  },
  props: {
    motivo: {
      type: [Number],
      default: 1
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      const labels = [
        'A DEFINIR',
        'PRODUTO',
        'CLIENTE',
        'FINANCEIRO',
        'COMERCIAL',
        'SUPORTE',
        'CONSULTORIA',
      ];      

      return labels[this.motivo];
    },
    color() {
      const colors = ['secondary', 'primary', 'info', 'warning', 'danger', 'light', 'dark'];      

      return colors[this.motivo];
    }
  }
}
</script>
