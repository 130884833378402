<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">{{ id == 'new' ? 'Novo módulo' : 'Editar Módulo' }}</h6>
          <p class="mb-0 text-sm">Criar/Editar módulo</p>
          <hr class="my-3 horizontal dark" />
          <form role="form" @submit.prevent="onSubmit">
            <div class="row mb-1">
              <div class="col-md-8">
                <label>Descrição</label>
                <argon-input id="name" type="text" placeholder="Descrição" aria-label="Descrição"
                  v-model="v$.modulo.descricao.$model" text-case="upper" />
                <div class="input-errors" v-for="(error, index) of v$.modulo.descricao.$errors" :key="index">
                  <div class="text-danger text-xs">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Status</label>
                <select class="form-control mb-3" v-model="v$.modulo.ativo.$model">
                  <option :value="true">ATIVO</option>
                  <option :value="false">INATIVO</option>
                </select>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-6">
                <label>Identificação do módulo</label>
                <argon-input id="email" type="text" placeholder="modulo_hotel_identificação" aria-label="Módulo"
                  v-model="v$.modulo.modulo.$model" text-case="lower" />
                <div class="input-errors" v-for="(error, index) of v$.modulo.modulo.$errors" :key="index">
                  <div class="text-danger text-xs">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label>ID Conexa</label>
                <input v-model="v$.modulo.conexaId.$model" class="form-control text-right" type="number" min="0" />
                <div class="input-errors" v-for="(error, index) of v$.modulo.conexaId.$errors" :key="index">
                  <div class="text-danger text-xs">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Valor Mínimo</label>
                <money3 v-model="v$.modulo.valorMinimo.$model" class="form-control text-right" />
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <router-link :to="{ name: 'Modulos' }">
                <argon-button type="button" color="dark" class="my-4 mb-2">
                  Voltar
                </argon-button>
              </router-link>
              <argon-button type="submit" color="success" variant="gradient" class="my-4 mb-2">
                SALVAR
              </argon-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import api from './../../services/api'
import { errorToast, successToast } from '@/utils/alerts';

export default {
  name: "Modulo",
  components: {
    ArgonInput,
    ArgonButton,
  },
  created() {
    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      modulo: {
        id: null,
        modulo: '',
        descricao: '',
        valorMinimo: 0,
        conexaId: 0,
        ativo: true,
      }
    }
  },
  validations() {
    return {
      modulo: {
        modulo: {
          required: helpers.withMessage('Identificação é obrigatória', required),
        },
        descricao: {
          required: helpers.withMessage('Descrição é obrigatória', required),
        },
        ativo: {

        },
        valorMinimo: {

        },
        conexaId: {
          required: helpers.withMessage('ID Conexa é obrigatório', required),
          minValue: helpers.withMessage('ID Conexa inválido', minValue(1)),
        }
      },
    }
  },
  methods: {
    fetchData() {
      const { id } = this;

      api.get(`/modulos/${id}`)
        .then(({ data }) => {
          this.modulo = data;

          if (!this.modulo.conexaId) {
            this.modulo.conexaId = 0;
          }
        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.modulo
        }

        await (this.id == 'new' ?
          api.post('/modulos', payload) :
          api.patch(`/modulos/${this.id}`, payload));

        successToast('Módulo salvo com sucesso.');

        this.$router.push({ name: 'Modulos' });
      } catch (e) {
        const { data } = e;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  },
};
</script>
