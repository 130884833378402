export const MONEY_CONFIG = {
    thousands: '.',
    decimal: ',',
    precision: 2,
}

export const PERCENTAGE_CONFIG = {
    precision: 0,
    min: 0,
    max: 100,
}

export const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}