<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <form role="form" @submit.prevent="onSubmit">
            <div class="card-header">
              <h6 class="mb-0">{{ id == 'new' ? 'Nova Nota de Versão' : 'Editar Nota de Versão' }}</h6>
            </div>
            <div class="card-body">
              <div class="row mb-1">
                <div class="col-md-9">
                  <label>Versão</label>
                  <input type="tel" placeholder="2024.05.001" class="form-control" v-model="v$.notaVersao.versao.$model"
                    v-mask="['####.##.###']" />
                  <div class="input-errors" v-for="(error, index) of v$.notaVersao.versao.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>Liberada</label>
                  <select class="form-control" v-model="v$.notaVersao.liberada.$model">
                    <option :value="true">SIM</option>
                    <option :value="false">NÃO</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-9">
                  <label>Descrição</label>
                  <input type="text" placeholder="Descrição" class="form-control"
                    v-model="v$.notaVersao.descricao.$model" />
                  <div class="input-errors" v-for="(error, index) of v$.notaVersao.descricao.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>Data Prevista</label>
                  <datepicker v-model="v$.notaVersao.dataPrevista.$model" locale="pt-BR" :month-change-on-scroll="false"
                    format="dd/MM/yyyy" auto-apply :enable-time-picker="false" />
                  <div class="input-errors" v-for="(error, index) of v$.notaVersao.dataPrevista.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-md-12">
                  <label>Link Para Notas de Versão</label>
                  <input type="text" placeholder="Link Notas" class="form-control"
                    v-model="v$.notaVersao.link.$model" />
                  <div class="input-errors" v-for="(error, index) of v$.notaVersao.dataPrevista.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <back-button />
                <submit-button />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { successToast, errorToast } from '@/utils/alerts';
import BackButton from '@/components/BackButton.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import Datepicker from '@vuepic/vue-datepicker';
import { ptBR } from 'date-fns/locale';
import api from '@/services/api'

export default {
  name: "NotaVersao",
  components: {
    BackButton,
    SubmitButton,
    Datepicker
  },
  created() {
    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      locale: ptBR,
      notaVersao: {
        id: null,
        descricao: '',
        link: '',
        versao: '',
        liberada: false,
        dataPrevista: null,
      }
    }
  },
  validations() {
    return {
      notaVersao: {
        descricao: {
          required: helpers.withMessage('Nome é obrigatória', required),
        },
        link: {
          required: helpers.withMessage('Ativo é obrigatório', required),
        },
        versao: {
          required: helpers.withMessage('Versão é obrigatória', required),
        },
        liberada: {
          required: helpers.withMessage('Liberada é obrigatório', required),
        },
        dataPrevista: {
          required: helpers.withMessage('Data Prevista é obrigatória', required),
        },
      }
    }
  },
  methods: {
    fetchData() {
      const { id } = this;

      api.get(`/notas-versao/${id}`)
        .then(({ data }) => {

          this.notaVersao = data;
        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.notaVersao
        }

        await (this.id == 'new' ?
          api.post('/notas-versao', payload) :
          api.patch(`/notas-versao/${this.id}`, payload));

        successToast('Nota de versão salva com sucesso.');

        this.$router.push({ name: 'Notas de Versão' });

      } catch (e) {
        const { data } = e;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  }
};
</script>
