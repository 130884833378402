<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex justify-content-between align-content-center">
                            <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']"
                                @click="previous()">
                                <i class="fas fa-chevron-left"></i> Anterior
                            </button>
                            <span class="text-uppercase text-lg label-month text-dark">
                                {{ filters.formatDate(modelValue, 'MMMM - YYYY') }}
                            </span>
                            <button type="button" :class="['btn', `btn-outline-${color}`, 'btn-sm mb-0']"
                                @click="next()" :disabled="!isNextActive">
                                Próximo <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    name: "MonthNavigator",
    emits: ['update:modelValue'],
    props: {
        color: {
            type: String,
            default: "primary",
        },
        modelValue: {
            type: Date,
            default: moment().startOf('month').toDate(),
        },
        maxDate: {
            type: Date,
            default: moment().startOf('month').toDate(),
        },
    },
    methods: {
        next() {
            const value = moment(this.modelValue).add(1, 'month').toDate();
            this.$emit('update:modelValue', value);
        },
        previous() {
            const value = moment(this.modelValue).subtract(1, 'month').toDate();
            this.$emit('update:modelValue', value);
        },
    },
    computed: {
        value: {
            get() {
                return this.currentMonth;
            },
        },
        isNextActive() {
            return moment(this.modelValue).isBefore(this.maxDate);
        },
    }
}
</script>
<style lang="scss" scoped>
.label-month {
    line-height: 2.125rem;
    font-weight: 600;
}
</style>