<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mt-4 col-lg-7 ms-auto mt-sm-2">
        <contratos-ativos />
      </div>
      <div class="mt-2 col-lg-5 col-md-12 mt-sm-2 card-vendas-mensal">
        <vendas-mensal-card />
      </div>
    </div>
    <div class="row">
      <div class="mt-2 col-lg-12 col-md-12 mt-sm-2 card-vendas-anual">
        <gerencial-anual-chart-card />
      </div>
    </div>
  </div>
</template>
<script>

import VendasMensalCard from "./components/VendasMensalCard.vue";
import GerencialAnualChartCard from "./components/GerencialAnualChartCard.vue";
import ContratosAtivos from "./components/ContratosAtivos.vue";
import introJs from 'intro.js'

export default {
  name: "DashboardGerencial",
  components: {
    GerencialAnualChartCard,
    VendasMensalCard,
    ContratosAtivos
  },
  data() {
    return {
      storageId: 'intro-gerencial-viewed'
    };
  },
  mounted() {    
    const introViewed = localStorage.getItem(this.storageId);

    if (!introViewed) {
      this.initIntroJs();
    }
  },
  methods: {
    initIntroJs() {
      introJs().setOptions({
        nextLabel: 'Próximo',
        prevLabel: 'Anterior',
        doneLabel: 'Pronto',
        showProgress: true,
        showBullets: false,
        showStepNumbers: true,
        buttonClass: 'btn btn-success',
        steps: [
          {
            element: document.querySelector('.card-contratos-ativos'),
            intro: 'Aqui esta o total de contratos ativos',
            title: 'Contratos Ativos'
          },
          {
            element: document.querySelector('.card-verticais'),
            intro: 'Aqui temos o total de contratos por vertical',
            title: 'Contratos por Vertical'
          },
          {
            element: document.querySelector('.btn-info-card'),
            intro: 'Aqui temos os detalhes por vertical',
            title: 'Detalhes por Vertical'
          },
          {
            element: document.querySelector('.card-vendas-mensal'),
            intro: 'Aqui está o movimento mensal',
            title: 'Movimento mensal'
          },
          {
            element: document.querySelector('.card-vendas-anual'),
            intro: 'Aqui está o movimento anual',
            title: 'Movimento Anual'
          },
        ]
      }).oncomplete(() => {
        localStorage.setItem(this.storageId, true);
      }).start();
    }
  }
};
</script>
<style lang="scss" scoped >
.introjs-tooltiptext {
  color: darkblue !important;
}
</style>