<template>
    <div class="row">
        <totais-churn-item-card title="Total Churn" :count="total" :percentage="{
            color: 'success',
            value: '+0%',
            label: 'since last month',
        }" />
        <totais-churn-item-card title="Clientes" :count="quantidade" :percentage="{
            color: 'success',
            value: '+0%',
            label: 'since last month',
        }" />
        <totais-churn-item-card title="Ticket Médio" :count="ticketMedio" :percentage="{
            color: 'secondary',
            value: '+0%',
            label: 'since last month',
        }" />
    </div>
</template>
<script>
import TotaisChurnItemCard from './TotaisChurnItemCard.vue';

export default {
    name: 'TotaisChurns',
    components: {
        TotaisChurnItemCard
    },
    props: {
        totais: {
            type: Object,
            default: () => ({
                total: 0,
                quantidade: 0,
                ticketMedio: 0
            })
        }
    },
    computed: {
        total() {
            return this.filters.currencyWithPrefix(this.totais.total);
        },
        quantidade() {
            return this.totais.quantidade.toLocaleString('pt-BR');
        },
        ticketMedio() {
            return this.filters.currencyWithPrefix(this.totais.ticketMedio);
        }
    }

}
</script>
<style lang="scss"></style>