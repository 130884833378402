<template>
    <div class="row">
        <totais-churn-item-card title="Solicitado" :count="total" />
        <totais-churn-item-card title="Clientes" :count="quantidade" />
        <totais-churn-item-card title="Revertidos" :count="revertidos" />
        <totais-churn-item-card title="Cancelados" :count="cancelados" />
    </div>
</template>
<script>
import TotaisChurnItemCard from './TotaisChurnItemCard.vue';

export default {
    name: 'TotaisChurns',
    components: {
        TotaisChurnItemCard
    },
    props: {
        totais: {
            type: Object,
            default: () => ({
                total: 0,
                quantidade: 0,
                cancelados: 0,
                revertidos: 0
            })
        }
    },
    computed: {
        total() {
            return this.filters.currencyWithPrefix(this.totais.total);
        },
        quantidade() {
            return this.totais.quantidade.toLocaleString('pt-BR');
        },
        revertidos() {
            return this.filters.currencyWithPrefix(this.totais.revertidos);
        },
        cancelados() {
            return this.filters.currencyWithPrefix(this.totais.cancelados);
        }
    }

}
</script>