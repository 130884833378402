<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100 aaaa">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card" v-if="isLoading">
              <div class="card-header text-center pt-4">
                <h5>Aguarde, validando token...</h5>
              </div>
              <div class="card-body text-center p-4">
                <i class="fas fa-circle-notch fa-spin fa-8x text-dark"></i>
              </div>
            </div>
            <div v-else>
              <div class="card" v-if="isValidToken">
                <div class="card-header text-center pt-4">
                  <h5>Reset Password</h5>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="onSubmit" autocomplete="off">
                    <div class="mb-1">
                      <label>Código de verificação</label>
                      <argon-input type="text" placeholder="000000" aria-label="Código" v-model="v$.user.codigo.$model"
                        :max-length="6" size="lg" text-case="upper" align="center" />
                      <div class="input-errors" v-for="(error, index) of v$.user.codigo.$errors" :key="index">
                        <div class="text-danger text-xs">{{ error.$message }}</div>
                      </div>
                    </div>
                    <div class="mb-1">
                      <label>Nova Senha</label>
                      <argon-input id="password" type="password" placeholder="Password" aria-label="Password"
                        v-model="v$.user.password.$model" autocomplete="new-password" />
                      <div class="input-errors" v-for="(error, index) of v$.user.password.$errors" :key="index">
                        <div class="text-danger text-xs">{{ error.$message }}</div>
                      </div>
                    </div>
                    <div class="mb-1">
                      <label>Repetir Senha</label>
                      <argon-input id="password-confirm" type="password" placeholder="Password" aria-label="Password"
                        v-model="v$.user.passwordConfirm.$model" />
                      <div class="input-errors" v-for="(error, index) of v$.user.passwordConfirm.$errors" :key="index">
                        <div class="text-danger text-xs">{{ error.$message }}</div>
                      </div>
                    </div>
                    <div class="text-center">
                      <argon-button type="submit" full-width variant="gradient" color="info" class="my-4 mb-2">
                        ENVIAR
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card" v-else>
                <div class="card-header text-center pt-4">
                  <h6>
                    Token inválido. Por favor, solicite um novo link.
                  </h6>
                </div>
                <div class="card-body text-center p-4">
                  <i class="fas fa-exclamation-triangle fa-8x text-danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { mapMutations } from "vuex";
import { successToast, errorToast } from '@/utils/alerts';
import api from '@/services/api';

export default {
  name: "VerificationBasic",
  components: {
    ArgonButton,
    ArgonInput,
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      isLoading: false,
      isValidToken: true,
      token: '',
      user: {
        codigo: '',
        password: '',
        passwordConfirm: '',
      }
    }
  },
  validations() {
    return {
      user: {
        codigo: {
          required: helpers.withMessage('Código é obrigatório', required),
        },
        password: {
          require: helpers.withMessage('Senha é obrigatório', required),
          min: helpers.withMessage('Senha deve ter no mínimo 6 caracteres', minLength(6)),
        },
        passwordConfirm: {
          require: helpers.withMessage('Confirmar senha é obrigatório', required),
          sameAs: helpers.withMessage('As senhas não conferem', sameAs(this.user.password))
        },
      },
    }
  },
  created() {
    this.setFullScreenLayout();

    const { token } = this.$route.params;

    this.token = token;

    //this.validateToken();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout', 'setFullScreenLayout']),
    validateToken() {
      this.isValidToken = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.isValidToken = false;
      }, 2000);
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const { user } = this;

        const response = await api.post('/auth/reset-password', user);

        const { message } = response.data;

        successToast(message);

        this.$router.push({ name: 'Signin' });

      } catch (e) {

        const { message } = e.data;

        errorToast(message ?? 'Erro ao enviar email de recuperação de senha');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.aaaa {
  background-image: url('./../../../../public/verification-basic.jpg');
}
</style>
