<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" style="background-image: url('reset-basic.jpg')">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-header text-center pt-4 pb-1">
                <h4 class="font-weight-bolder mb-1">Reset password</h4>
                <p class="mb-0 text-sm">
                  Você receberá um email com um link para redefinir sua senha.
                </p>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="onSubmit">
                  <argon-input id="email" type="email" placeholder="Email" aria-label="Email"
                    v-model="v$.email.$model" />
                  <div class="input-errors" v-for="(error, index) of v$.email.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                  <div class="text-center">
                    <argon-button color="dark" variant="gradient" class="my-4 mb-2" full-width type="submit">
                      Enviar
                    </argon-button>
                  </div>
                </form>
                <div class="mb-2 position-relative text-center">
                  <p class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                    ou
                  </p>
                </div>
                <div class="text-center">
                  <RouterLink :to="{ name: 'Signin' }">
                    <argon-button color="primary" variant="gradient" class="my-2 mb-2" full-width>
                      Voltar para login
                    </argon-button>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import api from '@/services/api';
import { successToast, errorToast } from '@/utils/alerts';

export default {
  name: "ResetBasic",
  components: {
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      email: '',
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email é obrigatório', required),
        email: helpers.withMessage('Email inválido', email),
      },
    }
  },
  created() {
    this.setFullScreenLayout();
    body.classList.remove("bg-gray-200");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-200");
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout', 'setFullScreenLayout']),
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const { email } = this;

        const response = await api.post('/auth/forgot-password', {
          email,
        });

        const { message } = response.data;

        successToast(message);

        this.$router.push({ name: 'Signin' });

      } catch (e) {        
        const { message } = e.data;

        errorToast(message ?? 'Erro ao enviar email de recuperação de senha');
      }
    }
  },
};
</script>
