<template>
  <div class="py-2 container-fluid">
    <month-navigator v-model="mes" />
    <div class="row">
      <totais-inadimplencia-card title="Titulos" :count="quantidade" />
      <totais-inadimplencia-card title="Total" :count="total" />
    </div>
    <div class="row mt-2" v-if="items.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="d-lg-flex">
              <h6 class="mb-0">Clientes Inadimplentes</h6>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                  @click="exportData()">
                  Exportar CSV
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="timeline timeline-one-side" :data-timeline-axis-style="'dotted'">
              <timeline-titulo-pendente v-for="item of items" :key="item.data" :title="item.label" :previsto="true"
                :icon="{ component: 'fas fa-calendar', color: 'danger' }" :items="item.items" />
            </div>
            <div class="table-responsive" :hidden="true">
              <table id="clientes-inadimplentes" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Cliente</th>
                    <th>Data</th>
                    <th>Operação</th>
                    <th>Atraso</th>
                    <th class="text-center">
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item of items" :key="item.id">
                    <tr v-for="it of item.items" :key="it.id">
                      <td>
                        {{ it.pessoa }}
                      </td>
                      <td>
                        {{ it.data }}
                      </td>
                      <td class="text-center">
                        {{ it.operacao.toUpperCase() }}
                      </td>
                      <td class="text-left">
                        <badge-tempo-vencido :vencimento="it.data" />
                      </td>
                      <td class="text-right text-bg-dark text-bold">
                        {{ filters.currencyWithPrefix(it.valor) }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TotaisInadimplenciaCard from "./components/TotaisInadimplenciaCard.vue";
import MonthNavigator from "@/components/MonthNavigator.vue";
import TimelineTituloPendente from "./components/TimelineTituloPendente.vue";
import BadgeTempoVencido from "./components/BadgeTempoVencido.vue";
import { exportToCSV } from "@/utils/export";
import api from "@/services/api";
import moment from "moment";

export default {
  name: "Comercial",
  components: {
    TimelineTituloPendente,
    TotaisInadimplenciaCard,
    BadgeTempoVencido,
    MonthNavigator,
  },
  data() {
    return {
      mes: moment().startOf('month').toDate(),
      items: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const mes = moment(this.mes).format('YYYY-MM-DD');

      api.get(`/dashboard-financeiro/inadimplencia-mensal`, {
        params: {
          mes
        }
      }).then(({ data }) => {
        this.items = data;
      });
    },
    exportData() {
      const filename = `inadimplencia-${moment(this.mes).format('YYYY-MM')}.csv`;

      exportToCSV('clientes-inadimplentes', filename);
    },
  },
  computed: {
    total() {
      let total = 0;

      this.items.forEach(item => {
        total += item.items.reduce((acc, { valor }) => acc + Number(valor), 0);
      });

      return this.filters.currencyWithPrefix(total);
    },
    quantidade() {
      let total = 0;

      this.items.forEach(item => {
        total += item.items.length;
      });

      return total.toString();

    },
  },
  watch: {
    mes: function () {
      this.fetchData();
    }
  }
};
</script>
