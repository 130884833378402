<template>
  <span :class="['badge', `badge-${size}`, 'badge-primary']">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeVertical",
  props: {
    vertical: {
      type: [String, Number],
      default: 2
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
      verticais: [
        'MOTEL',
        'HOTEL',
        'CONDOMÍNIO',
        'RESTAURANTE',
      ]
    }
  },
  computed: {
    label() {
      return isNaN(this.vertical) ? this.vertical : this.verticais[this.vertical - 1];
    }
  }
}
</script>
