<template>
  <main class="main-content mt-0">
    <div class="page-header min-vh-100" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/illustrations/404.svg') + ')',
    }">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="display-1 text-bolder text-primary">Error 403</h1>
            <h2>NÃO AUTORIZADO</h2>
            <p class="lead">
              VOCÊ NÃO TEM ACESSO A ESSE RECURSO
            </p>
            <argon-button color="dark" variant="gradient" class="mt-4" @click="back()">VOLTAR</argon-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
export default {
  name: "Erro403",
  components: {
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.setFullScreenLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout", 'setFullScreenLayout']),
    back() {
      this.$router.go(-1);
    }
  },
};
</script>
