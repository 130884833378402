<template>
  <argon-badge size="xs" :color="color">
    {{ label }}
  </argon-badge>
</template>
<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "BadgeStatusCancelamento",
  components: {
    ArgonBadge,
  },
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    label() {
      return this.status.toUpperCase();
    },
    color() {
      const colors = {
        detrator: 'danger',
        neutro: 'warning',
        promotor: 'success'
      };

      return colors[this.status];
    }
  }
}
</script>
