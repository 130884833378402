<template>
  <div class="form-group">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input :id="id" :type="type" class="form-control" :class="getClasses(size, success, error, textCase, align)"
        :name="name" :placeholder="placeholder" :required="isRequired" v-model="value" autocomplete="new-password"
        :maxlength="maxLength" />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArgonInput",
  emits: ['update:modelValue'],
  props: {
    textCase: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 255
    }
  },
  data() {
    return {
    }
  },
  methods: {
    getClasses: (size, success, error, textCase, align) => {

      let sizeValue, isValidValue, inputTextCase, textAlign, textSize;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      if (['upper', 'lower'].includes(textCase)) {
        inputTextCase = `text-${textCase}case`
      }

      if (['left', 'center', 'right'].includes(align)) {
        textAlign = `text-${align}`
      }

      textSize = `text-${size}`

      return `${sizeValue} ${isValidValue} ${inputTextCase} ${textAlign} ${textSize}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
