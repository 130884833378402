<template>
  <div class="card">
    <div class="pt-4 pb-3 text-center card-header">
      <h4 class="text-uppercase font-weight-bold text-dark">
        {{ plano.descricao }}
      </h4>
      <h5 class="mt-2 font-weight-bold">
        {{ filters.currency(plano.valorSugerido) }}
      </h5>
    </div>
    <div class="pt-0 card-body text-lg-start">
      <div class="modulos">
        <div v-if="plano.modulos.length == 0" class="max-height">
          Nenhum módulo cadastrado
        </div>
        <div v-for="(modulo, index) of plano.modulos" :key="index" class="p-2 d-flex justify-content-between">
          <div class="overflow-text">
            {{ modulo.modulo.descricao }}
          </div>
          <div class="text-center shadow icon icon-shape icon-xs rounded-circle"
            :class="modulo.obrigatorio ? 'bg-gradient-success' : 'bg-gradient-secondary'">
            <i :class="`fas ${modulo.obrigatorio ? 'fa-check' : 'fa-minus'} opacity-10`"></i>
          </div>
        </div>
      </div>
      <router-link :to="{
        name: 'Plano', params: {
          id: plano.id
        }
      }" class="mt-3 mb-0 btn btn-icon btn-dark w-100">
        Editar
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanoCard",
  props: {
    plano: {
      type: Object,
      required: true
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;
  height: 420px;
  max-height: 420px;

  .card-body {
    padding: 1rem;

    .max-height{
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modulos {
      height: 220px;
      max-height: 220px;
      overflow: auto;
    }
  }

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
