<template>
    <div v-if="descricao.indexOf('|') < 0">
        <strong>{{ atributo }}</strong>
    </div>
    <div v-else>
        <strong>{{ atributo }}</strong>:
        <span class="text-sm text-secondary">
            {{ de }}
        </span>
        &nbsp;
        <i class="fas fa-arrow-right text-dark"></i>
        &nbsp;
        <span class="text-sm font-weight-bold text-primary">
            {{ para }}
        </span>
    </div>
</template>
<script>
export default {
    name: "TimlineItemDescricao",
    props: {
        descricao: {
            type: String,
            default: "",
        },
    },
    mounted() {
        this.items = this.descricao.split("|");
    },
    data() {
        return {
            items: []
        }
    },
    computed: {
        atributo() {
            return this.items[0];
        },
        de() {
            return this.items[1];
        },
        para() {
            return this.items[2];
        }

    }
}
</script>
<style lang="">

</style>