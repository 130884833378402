<template>
    <div v-if="item.meta > 0" @click="toggle()">
        <div class="d-flex justify-content-center align-items-center meta-progress">
            <template v-if="viewMetaPercentage">
                <span class="me-2 text-xs">{{ percentage }}%</span>
                <argon-progress :color="color" :percentage="percentage" />
            </template>
            <template v-else>
                <span class="badge badge-primary">
                    {{ filters.thousandsToInt(item.valor) }}/{{ filters.thousandsToInt(item.meta) }}
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import ArgonProgress from '@/components/ArgonProgress.vue';

export default {
    components: {
        ArgonProgress
    },
    name: 'ProgressBarMeta',
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            viewMetaPercentage: true,
        }
    },
    methods: {
        toggle() {
            this.viewMetaPercentage = !this.viewMetaPercentage;

            if (!this.viewMetaPercentage) {
                setTimeout(() => {
                    this.viewMetaPercentage = true
                }, 5000);
            }
        }
    },
    computed: {
        percentage() {
            const { meta, valor } = this.item;

            let percentage = 0;

            if (meta > 0) {
                percentage = (valor * 100) / meta;
            }

            return Math.trunc(percentage);
        },
        color() {
            const percentage = this.percentage;

            if (percentage > 80) {
                return 'success';
            }

            if (percentage > 60) {
                return 'info';
            }

            if (percentage > 40) {
                return 'primary';
            }

            if (percentage > 20) {
                return 'warning';
            }

            return 'danger';
        }
    }
}
</script>
<style lang="scss" scoped>
.meta-progress {
    cursor: pointer;


    .progress {
        height: 6px;
    }
}
</style>