
import axios from 'axios'
import { BASE_URL } from './../environments/environments'
import router from '../router/routes'

const apiRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

apiRequest.interceptors.request.use(request => {
    const token = localStorage.getItem("access_token");

    if (request.url.indexOf('/auth') < 0) {
        request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;

})

apiRequest.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error);
        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    //do something
                    break;
                case 401:
                case 403:
                    console.log('go to login');
                    router.replace({
                        path: "/signin",
                    });
                    break;
                case 404:
                    // {
                    //     console.log(router.currentRoute.value.path);

                    //     let routeName = 'crm_page_not_found';

                    //     try {

                    //         if (router.currentRoute.value.path.indexOf('/admin') >= 0) {
                    //             routeName = 'crm_page_admin_not_found';
                    //         }
                    //     } catch (error) {
                    //         console.log(error);
                    //     }

                    //     router.push({
                    //         name: routeName,
                    //     });
                    // }
                    break;
                case 502:
                    setTimeout(() => {
                        router.replace({
                            path: "/login",
                        });
                    }, 1000);
            }
            return Promise.reject(error.response);
        }
        return Promise.reject(error);
    }
);

export default apiRequest;