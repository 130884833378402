<template>
  <span :class="['badge', `badge-${size}`, color]">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeTempoVida",
  props: {
    tempoVida: {
      type: [Number, String],
      default: '0'
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      return Number(this.tempoVida) === 1 ? '1 mês' : `${this.tempoVida} meses`;
    },
    color() {
      return Number(this.tempoVida) === 1 ? 'badge-danger' : 'badge-secondary';
    }
  }
}
</script>