import Swal from 'sweetalert2';

export const successToast = (message, title, timer) => showToast('success', message, title, timer);
export const infoToast = (message, title, timer) => showToast('info', message, title, timer);
export const warningToast = (message, title, timer) => showToast('warning', message, title, timer);
export const errorToast = (message, title, timer) => showToast('error', message, title, timer);

export const showLoading = (message) => Swal.fire({
    title: message,
    allowOutsideClick: false,
    didOpen: () => {
        Swal.showLoading();
    },
});

export const showToast = (type, message, title, timer) => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer ?? 3000,
        icon: type ?? 'success',
        //title: title ?? 'BITZ',
        text: message ?? '',
    });
}

export const showConfirm = async (message, title = 'Confirmar?') => {
    const response = await Swal.fire({
        icon: 'question',
        title: title ?? '',
        text: message ?? '',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'SIM',
        cancelButtonText: 'NÃO',
        confirmButtonColor: '#2dce89',
        confirmButtonAriaLabel: 'java'
    });

    return response.isConfirmed;
}

export const showConfirmWithValidatorText = async (message, title = 'Confirmar?', inputValidatorText = 'DELETE') => {
    const response = await Swal.fire({
        icon: 'question',
        title: title ?? '',
        text: message ?? '',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'SIM',
        cancelButtonText: 'NÃO',
        confirmButtonColor: '#2dce89',
        input: 'text',
        inputPlaceholder: `Digite "${inputValidatorText}" para confirmar`,
        inputValidator: (value) => {
            if (value.toUpperCase() !== inputValidatorText.toUpperCase()) {
                return `Texto incorreto, digite "${inputValidatorText}" para confirmar`;
            }
        }
    });

    return response.isConfirmed;
}