<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0">{{ id == 'new' ? 'Nova closer' : 'Editar closer' }}</h6>
            <p class="mb-0 text-sm">Criar/Editar closer</p>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="onSubmit">
              <div class="row mb-1">
                <div class="col-md-6">
                  <label>Nome</label>
                  <input id="nome" type="text" placeholder="Nome" aria-label="Nome" class="form-control"
                    v-model="v$.closer.nome.$model" />
                </div>
                <div class="col-md-3">
                  <label>Status</label>
                  <select class="form-control" v-model="v$.closer.ativo.$model">
                    <option :value="true">ATIVO</option>
                    <option :value="false">INATIVO</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label>Tipo</label>
                  <select class="form-control" v-model="v$.closer.tipo.$model">
                    <option :value="1">INTERNO</option>
                    <option :value="2">REPRESENTANTE</option>
                  </select>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <router-link :to="{ name: 'Closers' }">
                  <argon-button type="button" color="dark" class="my-4 mb-2">
                    Voltar
                  </argon-button>
                </router-link>
                <argon-button type="submit" color="success" variant="gradient" class="my-4 mb-2">
                  SALVAR
                </argon-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import ArgonButton from "@/components/ArgonButton.vue";
import { successToast, errorToast } from '@/utils/alerts';
import { ptBR } from 'date-fns/locale'
import api from '@/services/api'

export default {
  name: "Closer",
  components: {
    ArgonButton,
  },
  created() {
    this.id = this.$route.params.id;

    if (this.id == 'new') {
      return;
    }

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      locale: ptBR,
      closer: {
        id: null,
        nome: '',
        ativo: true,
        tipo: 1,
      }
    }
  },
  validations() {
    return {
      closer: {
        nome: {
          required: helpers.withMessage('Nome é obrigatório', required),
        },
        ativo: {
          required: helpers.withMessage('Ativo é obrigatório', required),
        },
        tipo: {
          required: helpers.withMessage('Tipo é obrigatório', required),
        },
      },
    }
  },
  methods: {
    fetchData() {
      const { id } = this;

      api.get(`/closers/${id}`)
        .then(({ data }) => {

          this.closer = data;
        })
        .catch(e => console.log(e));
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const payload = {
          ...this.closer
        }

        await (this.id == 'new' ?
          api.post('/closers', payload) :
          api.patch(`/closers/${this.id}`, payload));

        successToast('Closer salvo com sucesso.');

        this.$router.push({ name: 'Closers' });

      } catch (e) {
        console.log(e);
        const { data } = e.response;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  }
};
</script>
