<template>
    <div id="modal-selecionar-cliente" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog mt-lg-10 modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">
                        Selecionar Cliente
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>
                                Pesquisar
                            </label>
                            <input class="form-control text-uppercase" v-model="searchClientes"
                                placeholder="ID, Nome Fantasia, CPF/CNPJ">
                        </div>
                    </div>
                    <div class="table-pesquisa">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Nome Fantasia
                                    </th>
                                    <th>
                                        Documento
                                    </th>
                                    <th>
                                        Ação
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cliente of clientesFiltered" :key="cliente.id">
                                    <td>
                                        <span class="my-2 text-xs">
                                            {{ cliente.hotelEmpresaId ?? '***' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="my-2 text-xs text-bold">
                                            {{
                                (cliente.nomeFantasia ?
                                    cliente.nomeFantasia :
                                    cliente.razaoSocial)
                                    .toUpperCase()
                                    .substr(0, 30)
                            }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="my-2 text-xs">
                                            {{ filters.formatDocument(cliente.documento) }}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary btn-xs"
                                            @click="selectCliente(cliente)" data-bs-dismiss="modal">
                                            Selecionar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api';

export default {
    name: "ModalSelecionarCliente",
    props: {
        onSelectCliente: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            searchClientes: '',
            clientes: [],
            clientesFiltered: [],
        }
    },
    mounted() {
        this.fetchClientes();
    },
    methods: {
        clear() {
            this.searchClientes = '';
        },
        fetchClientes() {
            api.get(`/clientes/list`)
                .then(({ data }) => {
                    this.clientes = data;
                    this.clientesFiltered = data;
                })
                .catch(e => console.log(e));
        },
        filterClientes() {
            const search = this.searchClientes.toLowerCase();

            let clientes = [
                ...this.clientes
            ];

            if (search) {
                clientes = clientes.filter(item =>
                    (item.id ?? '').toString().toLowerCase().indexOf(search) > -1 ||
                    (item.nomeFantasia ?? '').toLowerCase().indexOf(search) > -1 ||
                    (item.razaoSocial ?? '').toLowerCase().indexOf(search) > -1 ||
                    (item.documento ?? '').toLowerCase().indexOf(search) > -1
                );
            }

            this.clientesFiltered = clientes;
        },
        selectCliente(cliente) {
            this.onSelectCliente(cliente);
        }
    },
    watch: {
        searchClientes: function () {
            this.filterClientes();
        }
    }

}
</script>
<style lang="scss" scoped>
.table-pesquisa {
    height: 400px;
    max-height: 400px;
    overflow: scroll;
}
</style>