<template>
  <argon-button :size="size" :variant="isSelected ? 'gradient' : 'outline'" :color="color" class="me-1"
    @click="onClick(status)">
    {{ label }}
  </argon-button>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "BadgeStatusCancelamento",
  components: {
    ArgonButton,
  },
  props: {
    status: {
      type: [Number],
      default: 1
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => { }
    },
    size: {
      type: String,
      default: 'xs'
    }
  },
  computed: {
    label() {
      const labels = ['NOVA SOLICITAÇÃO', 'EM TRATATIVA', 'AGUARD. DISTRATO', 'REVERTIDO', 'CANCELADO'];

      return labels[this.status];
    },
    color() {
      const colors = ['secondary', 'primary', 'warning', 'success', 'danger'];

      return colors[this.status];
    }
  }
}
</script>
