<template>
  <span :class="['badge', `badge-${size}`, color]">
    {{ label }}
  </span>
</template>
<script>
export default {
  name: "BadgeStatusEmpresa",
  props: {
    status: {
      type: String,
      default: 'F'
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    color() {
      const colors = {
        'B': 'badge-dark',
        'C': 'badge-danger',
        'F': 'badge-success',
        'I': 'badge-primary',
        'A': 'badge-primary',
        'S': 'badge-info',
        'G': 'badge-info',
      }

      return colors[this.status] ?? 'badge-secondary';
    },
    label() {
      const labels = {
        'B': 'BLOQUEADO',
        'C': 'CANCELADO',
        'F': 'FATURADO',
        'I': 'IMPLANTAÇÃO',
        'A': 'IMPLANTAÇÃO',
        'S': 'STAND BY',
        'G': 'CONGELADO',
      }

      return labels[this.status] ?? 'INDEFINIDO';
    }
  }
}
</script>
