<template>
    <li class="pb-0 border-0 list-group-item justify-content-between ps-0 border-radius-lg">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <button
                    :class="['p-3 mb-0 btn btn-icon-only btn-rounded me-3 btn-sm d-flex align-items-center justify-content-center', status ? 'btn-outline-success' : 'btn-outline-danger']">
                    <i :class="['fas ', status ? 'fa-check' : 'fa-times']" aria-hidden="true"></i>
                </button>
                <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">
                        {{ title }}
                    </h6>
                    <span class="text-xs overflow-text">
                        {{ filters.formatDate(end, 'DD MMMM YYYY, HH:mm:ss') }}
                    </span>
                </div>
            </div>
            <div :class="['btn-log', 'text-sm d-flex align-items-center text-gradient font-weight-bold ms-auto', status ? 'text-success' : 'text-danger']"
                @click="onClick()">
                <i class="fas fa-file-alt fa-lg" data-bs-toggle="modal" data-bs-target="#modal-info-onboaring"></i>
                <!-- {{ duration }} -->
            </div>
        </div>
        <div class="mt-3 mb-2"></div>
    </li>
</template>
<script>
import moment from 'moment';

export default {
    name: "OnboardingCardItem",
    emits: ['on-log-click'],
    props: {
        title: {
            type: String,
            default: ''
        },
        id: {
            type: [String, Number],
            default: ''
        },
        status: {
            type: Boolean,
            default: false
        },
        start: {
            type: [Date, String],
            default: null
        },
        end: {
            type: [Date, String],
            default: null
        },
        logs: {
            type: Object,
            default: () => { }
        },
    },
    methods: {
        onClick() {
            const { title, status, start, end, logs, id } = this;

            this.$emit('on-log-click', {
                id,
                title,
                status,
                start,
                end,
                logs
            });
        }
    },
    computed: {
        duration() {
            const diff = moment(this.end).diff(moment(this.start), 'milliseconds');

            if (diff < 0) {
                return `1ms`;
            }

            return diff > 1000 ? `${diff / 1000}s` : `${diff}ms`;
        }
    }
}
</script>
<style lang="scss">
.btn-log {
    cursor: pointer;
}
</style>