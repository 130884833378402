<template>
  <div class="mt-4 col-sm-6 mt-sm-0">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="d-flex justify-content-between">
          <div class="row">
            <div class="col-12 text-start">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">
                NPS
              </p>
              <h5 class="mb-0 font-weight-bolder">{{ value }}%</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-end">
              <p class="text-sm mb-1 text-uppercase font-weight-bold">
                CLASSIFICAÇÃO
              </p>
              <h5 :class="['mb-0 font-weight-bolder', classificacaoColor]">{{ classificacao }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NpsPercentualCard",
  props: {
    percentual: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    value() {
      return (this.percentual ?? 0).toFixed(2);
    },
    classificacao() {
      const nps = this.percentual ?? 0;

      if (nps <= 0) {
        return 'Zona Crítica';
      }

      if (nps <= 50) {
        return 'Aperfeiçoamento';
      }

      if (nps <= 75) {
        return 'Qualidade';
      }

      return 'Excelência';
    },
    classificacaoColor() {
      const nps = this.percentual ?? 0;

      if (nps <= 0) {
        return 'zona-critica';
      }

      if (nps <= 50) {
        return 'aperfeicoamento';
      }

      if (nps <= 75) {
        return 'qualidade';
      }

      return 'excelencia';
    },
  },

};
</script>

<style lang="scss" scoped>
.zona-critica {
  color: #ec7060;
}

.aperfeicoamento {
  color: #f7b901;
}

.qualidade {
  color: #4ac268;
}

.excelencia {
  color: #34ab4a;
}
</style>