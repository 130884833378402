<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-basic.jpg');
      ">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4">
                <h5>Informações Pessoais</h5>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="onSubmit">
                  <div class="mb-1">
                    <label>Nome</label>
                    <argon-input id="name" type="text" placeholder="Nome" aria-label="Name"
                      v-model="v$.user.nome.$model" />
                    <div class="input-errors" v-for="(error, index) of v$.user.nome.$errors" :key="index">
                      <div class="text-danger text-xs">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <label>Email</label>
                    <argon-input id="email" type="email" placeholder="Email" aria-label="Email"
                      v-model="v$.user.email.$model" />
                    <div class="input-errors" v-for="(error, index) of v$.user.email.$errors" :key="index">
                      <div class="text-danger text-xs">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <label>Senha</label>
                    <argon-input id="password" type="password" placeholder="Password" aria-label="Password"
                      v-model="v$.user.password.$model" autocomplete="new-password" />
                    <div class="input-errors" v-for="(error, index) of v$.user.password.$errors" :key="index">
                      <div class="text-danger text-xs">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <label>Repetir Senha</label>
                    <argon-input id="password-confirm" type="password" placeholder="Password" aria-label="Password"
                      v-model="v$.user.passwordConfirm.$model" />
                    <div class="input-errors" v-for="(error, index) of v$.user.passwordConfirm.$errors" :key="index">
                      <div class="text-danger text-xs">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label>Departamento</label>
                    <select class="form-control mb-3" v-model="v$.user.tipo.$model">
                      <option value="">Selecione uma opção</option>
                      <option v-for="tipo of tiposUsuario" :value="tipo.id" :key="tipo.id">
                        {{ tipo.name }}
                      </option>
                    </select>
                    <div class="input-errors" v-for="(error, index) of v$.user.tipo.$errors" :key="index">
                      <div class="text-danger text-xs">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="text-center">
                    <argon-button type="submit" full-width color="dark" variant="gradient" class="my-4 mb-2">
                      ENVIAR
                    </argon-button>
                  </div>
                  <p class="text-sm mt-3 mb-0">
                    Ja tem uma conta?
                    <router-link :to="{ name: 'Signin' }" class="text-dark font-weight-bolder">
                      Entrar
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import api from './../../../services/api'
import { errorToast, successToast } from '@/utils/alerts';

export default {
  name: "SignUp",
  components: {
    ArgonInput,
    ArgonButton,
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      tiposUsuario: [
        { id: 2, name: 'Administrador', },
        { id: 1, name: 'Produto', },
        { id: 3, name: 'Closer', },
        { id: 4, name: 'Financeiro', },
        { id: 5, name: 'Marketing', },
        { id: 6, name: 'Gerente Atendimento', },
        { id: 7, name: 'Customer Success', },
        { id: 8, name: 'Implantador', },
      ],
      user: {
        nome: '',
        email: '',
        password: '',
        passwordConfirm: '',
        tipo: ''
      }
    }
  },
  validations() {
    return {
      user: {
        nome: {
          required: helpers.withMessage('Nome é obrigatório', required),
        },
        email: {
          required: helpers.withMessage('Email é obrigatório', required),
          email: helpers.withMessage('Email inválido', email),
        },
        password: {
          require: helpers.withMessage('Senha é obrigatório', required),
          min: helpers.withMessage('Senha deve ter no mínimo 6 caracteres', minLength(6)),
        },
        passwordConfirm: {
          require: helpers.withMessage('Confirmar senha é obrigatório', required),
          sameAs: helpers.withMessage('As senhas não conferem', sameAs(this.user.password))
        },
        tipo: {
          require: helpers.withMessage('Departamento é obrigatório', required),
        }
      },
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.setFullScreenLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout", 'setFullScreenLayout']),
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      try {
        const response = await api.post('/auth/signup', {
          ...this.user
        });

        const { message } = response.data;

        successToast(message);

        this.$router.push({ name: 'Signin' });

      } catch (e) {
        console.log(e);
        errorToast(e.toString());
        // const { data } = e.response;
        // const message = data ? data.message : e.message;        
      }
    },
  },
};
</script>
