<template>
  <div class="col-lg-3 col-6 text-center clienes-counter-card">
    <div :class="[selected ? 'border border-2' : 'border-dashed border-1', 'border-dark', 'border-radius-md', 'py-1']">
      <div class="d-flex justify-content-between align-items-center px-2">
        <h6 class="text-success">{{ title }}</h6>
        <h6 class="font-weight-bolder">
          <span class="small">{{ prefix }}</span>
          {{ "" }}
          <countTo :start-val="0" :end-val="count" :duration="duration"></countTo>
          {{ "" }}
          <span class="small">{{ suffix }}</span>
        </h6>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to/src";

export default {
  name: "ClientesCounterCard",
  components: {
    countTo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    duration: {
      type: Number,
      default: 1500,
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style lang="scss" scoped>
.clienes-counter-card {
  cursor: pointer;

  h6 {
    margin-bottom: 0;

  }
}
</style>
