<template>
  <span :class="['badge', `badge-${size}`, `badge-${color}`]">
    {{ label }}
  </span>
</template>
<script>
export default {
  name: "BadgeSituacao",
  props: {
    status: {
      type: [Number],
      default: 0
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      const labels = {
        0: 'IMPLANTAÇÃO',
        1: 'FATURADO',
        2: 'BLOQUEADO',
        3: 'CONGELADO',
        4: 'CANCELADO',
      }

      return labels[this.status]
    },
    color() {
      const colors = {
        0: 'secondary',
        1: 'success',
        2: 'danger',
        3: 'info',
        4: 'dark',
      }

      return colors[this.status]
    }
  }
}
</script>
