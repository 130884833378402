<template>
  <main class="main-content main-content-bg mt-0">
    <!-- <div class="page-header min-vh-100" style="background-image: url('background.jpeg')"> -->
    <div class="page-header min-vh-100" style="background-image: url('signin-basic.jpg')">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2">Painel BITZ</h5>
              </div>
              <div class="card-body">
                <form role="form" class="text-start" @submit.prevent="onSubmit">
                  <div class="mb-3">
                    <label>Email</label>
                    <argon-input id="email" type="email" placeholder="Email" aria-label="Email" :is-required="true"
                      v-model="user.email" />
                  </div>
                  <div class="mb-3">
                    <label>Password</label>
                    <argon-input id="password" type="password" placeholder="Password" aria-label="Password"
                      :is-required="true" v-model="user.password" />
                  </div>
                  <div class="text-right text-sm">
                    <router-link :to="{ name: 'Forgot Basic' }">
                      Esqueci minha senha
                    </router-link>
                  </div>
                  <div class="text-center">
                    <argon-button color="success" full-width class="my-3 mb-2" type="submit">Entrar</argon-button>
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      ou
                    </p>
                  </div>
                  <div class="text-center">
                    <router-link :to="{ name: 'Signup' }">
                      <argon-button color="dark" variant="gradient" full-width
                        class="mt-2">Registre-se</argon-button>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import { errorToast } from './../../../utils/alerts'
import api from './../../../services/api'

export default {
  name: "SignIn",
  components: {
    ArgonInput,
    ArgonButton,
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  created() {
    this.setFullScreenLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout', 'setFullScreenLayout', 'setUser']),
    redirectToRoute(user) {
      const routes = [
        'Produto',
        'Gerencial',
        'Comercial',
        'Financeiro',
        'Marketing',
        'Dashboard Customer Success',
        'Dashboard Customer Success',
        'Implantação',
      ];

      const route = routes[user.tipo - 1] ?? 'Gerencial';

      console.log(route);

      this.$router.push({ name: route });
    },
    async onSubmit() {

      try {
        const { email, password } = this.user;

        const response = await api.post('/auth/signin', {
          email,
          password
        });

        const { user, token } = response.data;

        localStorage.setItem('access_token', token);

        this.setUser(user);

        this.redirectToRoute(user);
      } catch (e) {

        const { message = 'UNKNOW_ERROR' } = e.data;

        errorToast(message);
      }
    }
  },
};
</script>
