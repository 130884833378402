<template>
  <div class="py-2 container-fluid">
    <div class="row ">
      <div :class="[lancamentosFuturos ? 'col-md-12' : 'col-md-6']">
        <mini-statistics-card title="Total Previsto" :value="filters.currencyWithPrefix(totalPrevisto)" :icon="{
        component: 'fas fa-coins',
        background: 'bg-gradient-primary',
        shape: 'rounded-circle'
      }" />
      </div>
      <div class="col-md-6" v-if="!lancamentosFuturos">
        <mini-statistics-card title="Total Realizado" :value="filters.currencyWithPrefix(totalRealizado)" :icon="{
        component: 'fas fa-coins',
        background: 'bg-gradient-success',
        shape: 'rounded-circle'
      }" />
      </div>
    </div>
    <div class="row">
      <div :class="[lancamentosFuturos ? 'col-md-12' : 'col-md-6']">
        <timeline-receitas-despesas-previsto :items="previstos" :title="titlePrevistos" :categoria="categoria" />
      </div>
      <div class="col-md-6" v-if="!lancamentosFuturos">
        <timeline-receitas-despesas-realizado :items="realizados" :title="titleRealizados" :categoria="categoria" />
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import TimelineReceitasDespesasPrevisto from "@/examples/Cards/TimelineReceitasDespesasPrevisto.vue";
import TimelineReceitasDespesasRealizado from "@/examples/Cards/TimelineReceitasDespesasRealizado.vue";
import api from './../../services/api';
import moment from "moment";


export default {
  name: "ReceitasDespesasMensal",
  components: {
    MiniStatisticsCard,
    TimelineReceitasDespesasPrevisto,
    TimelineReceitasDespesasRealizado
  },
  created() {
    const { mes, categoria } = this.$route.params;

    this.mes = mes;
    this.categoria = categoria;

    const lancamentosFuturos = moment(mes).isAfter(moment().startOf('month'));

    this.lancamentosFuturos = lancamentosFuturos;

    this.fetchData();
  },
  data() {
    return {
      mes: '',
      categoria: '',
      total: 0,
      previstos: [],
      realizados: [],
      dataTable: null,
      lancamentosFuturos: false,
    }
  },
  methods: {
    fetchData() {
      const { mes, categoria } = this;

      api.get(`/dashboard-financeiro/movimentacao-${categoria}-mensal-previsto`, {
        params: {
          mes
        }
      }).then(response => {
        const { data } = response;

        this.previstos = data;

      }).catch(e => console.log(e));

      if (!this.lancamentosFuturos) {

        api.get(`/dashboard-financeiro/movimentacao-${categoria}-mensal-realizado`, {
          params: {
            mes
          }
        }).then(response => {
          const { data } = response;

          this.realizados = data;

        }).catch(e => console.log(e));
      }
    },
  },
  computed: {
    titlePrevistos() {
      return `${this.categoria} previstas por vencimento`.toUpperCase();
    },
    titleRealizados() {
      return `${this.categoria} realizadas por quitação`.toUpperCase();
    },
    totalPrevisto() {
      let total = 0;

      this.previstos.forEach(item => {
        total += item.items.filter(item => item.tipo == 'CONTA').reduce((acc, item) => acc + Number(item.valor), 0);
      });

      return total;
    },
    totalRealizado() {
      let total = 0;

      this.realizados.forEach(item => {
        total += item.items.filter(item => item.tipo == 'LANCAMENTO').reduce((acc, item) => acc + Number(item.valor), 0);
      });

      return total;
    },
  },
  watch: {}
};
</script>