<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Cancelamentos</h5>
                <p class="mb-0 text-sm">
                  Solicitações de cancelamento
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Cancelamento', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      <i class="fas fa-plus"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <clientes-counter-card v-for="item of totaisStatus" :count="item.count()" :title="item.name"
                :selected="item.isSelected()" :key="item.id" @click="item.onClick" />
            </div>
            <div class="row mt-2">
              <clientes-counter-card v-for="item of totaisVertical" :count="item.count()" :title="item.name"
                :selected="item.isSelected()" :key="item.id" @click="item.onClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div class="my-auto ms-auto">
                <div class="my-auto ms-auto">
                  <button class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                    @click="exportData()">
                    Exportar CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Pesquisar</label>
                <input class="form-control text-uppercase" v-model="search"
                  placeholder="Nome Fantasia, CPF/CNPJ, Vertical, ID Empresa">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6" v-for="cancelamento of cancelamentosFiltered" :key="cancelamento.id">
                <cancelamento-card :cancelamento="cancelamento" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive" :hidden="true">
      <table id="cancelamentos-list" class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th class="text-xs">#</th>
            <th class="text-xs">Nome</th>
            <th class="text-xs">Motivo</th>
            <th class="text-xs text-center">Vertical</th>
            <th class="text-xs text-center">Tempo Carteira</th>
            <th class="text-xs text-center">Status</th>
            <th class="text-xs text-center">Fechamento</th>
            <th class="text-xs text-center">Solic. Canc</th>
            <th class="text-xs text-center">Ultimo Contato</th>
            <th class="text-xs text-center">Faturamento</th>
            <th class="text-xs text-center">Closer</th>
            <th class="text-xs text-center">Implantador</th>
            <th class="text-xs">Valor</th>
            <th class="text-xs">Observações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cancelamento of cancelamentosFiltered" :key="cancelamento.id">
            <td>
              <span class="my-2 text-xs">
                {{ cancelamento.cliente?.id }}
              </span>
            </td>
            <td class="text-uppercase">
              <span class="my-2 text-xs">
                {{ cancelamento.cliente?.nomeFantasia ?
                    cancelamento.cliente?.nomeFantasia :
                    cancelamento.cliente?.razaoSocial
                }}
              </span>
            </td>
            <td>
              <badge-motivo-cancelamento :motivo="cancelamento.motivoCancelamento" size="xs" />
            </td>
            <td class="text-center">
              <badge-vertical :vertical="cancelamento.cliente?.vertical" size="xs" />
            </td>
            <td class="text-center text-xs">
              {{ cancelamento.tempoContrato }}
            </td>
            <td class="text-center">
              <badge-status-cancelamento :status="cancelamento.status" size="xs" />
            </td>
            <td class="text-center text-xs">
              {{ filters.formatDate(cancelamento.cliente?.dataContrato) }}
            </td>
            <td class="text-center text-xs">
              {{ filters.formatDate(cancelamento.data) }}
            </td>
            <td class="text-center text-xs">
              {{ filters.formatDate(cancelamento.dataUltimoContato) }}
            </td>
            <td class="text-center">
              <badge-tipo-faturamento :tipo-faturamento="cancelamento.cliente?.tipoFaturamento" size="xs" />
            </td>
            <td class="text-right text-dark text-bold text-xs text-uppercase">
              {{ cancelamento.cliente?.closer?.nome }}
            </td>
            <td class="text-right text-dark text-bold text-xs text-uppercase">
              {{ cancelamento.implantador?.nome }}
            </td>
            <td class="text-right text-dark text-bold text-xs">
              {{ filters.currency(cancelamento.cliente?.valor) }}
            </td>
            <td>
              {{ cancelamento.observacoes }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import { exportToCSV } from "@/utils/export";
import ClientesCounterCard from '@/views/pages/Clientes/components/ClientesCounterCard.vue';
import CancelamentoCard from '@/views/pages/Cancelamentos/components/CancelamentoCard.vue';
import BadgeVertical from '@/views/pages/components/BadgeVertical.vue';
import BadgeStatusCancelamento from '@/views/pages/Cancelamentos/components/BadgeStatusCancelamento.vue';
import BadgeMotivoCancelamento from '@/views/pages/Cancelamentos/components/BadgeMotivoCancelamento.vue';
import BadgeTipoFaturamento from '../components/BadgeTipoFaturamento.vue';

export default {
  name: "Cancelamentos",
  components: {
    ClientesCounterCard,
    CancelamentoCard,
    BadgeStatusCancelamento,
    BadgeMotivoCancelamento,
    BadgeVertical,
    BadgeTipoFaturamento,
  },
  data() {
    return {
      search: '',
      status: 0,
      vertical: 0,
      totaisStatus: [
        { id: 1, name: 'EM TRATATIVA', count: () => this.totais.tratativa, isSelected: () => this.isStatusSelected(1), onClick: () => this.filterStatus(1) },
        { id: 2, name: 'DISTRATO', count: () => this.totais.distrato, isSelected: () => this.isStatusSelected(2), onClick: () => this.filterStatus(2) },
        { id: 3, name: 'REVERTIDO', count: () => this.totais.revertidos, isSelected: () => this.isStatusSelected(3), onClick: () => this.filterStatus(3) },
        { id: 4, name: 'CANCELADO', count: () => this.totais.cancelados, isSelected: () => this.isStatusSelected(4), onClick: () => this.filterStatus(4) },
      ],
      totaisVertical: [
        { id: 1, name: 'MOTEL', count: () => this.verticais.motel, isSelected: () => this.isVerticalSelected(1), onClick: () => this.filterVertical(1) },
        { id: 2, name: 'HOTEL', count: () => this.verticais.hotel, isSelected: () => this.isVerticalSelected(2), onClick: () => this.filterVertical(2) },
        { id: 3, name: 'CONDOMÍNIO', count: () => this.verticais.condominio, isSelected: () => this.isVerticalSelected(3), onClick: () => this.filterVertical(3) },
        { id: 4, name: 'RESTAURANTE', count: () => this.verticais.restaurante, isSelected: () => this.isVerticalSelected(4), onClick: () => this.filterVertical(4) },
      ],
      totais: {
        tratativa: 0,
        distrato: 0,
        revertidos: 0,
        cancelados: 0,
      },
      verticais: {
        motel: 0,
        hotel: 0,
        condominio: 0,
        restaurante: 0,
      },
      cancelamentos: [],
      cancelamentosFiltered: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/cancelamentos/list`)
        .then(response => {
          const { data } = response;

          this.cancelamentos = data;
          this.setTotaisCancelamentos();
          this.filterCancelamentos();
          this.setTotaisVertital();
        })
        .catch(e => console.log(e));
    },
    setTotaisCancelamentos() {
      const { cancelamentos } = this;

      const fields = {
        1: 'tratativa',
        2: 'distrato',
        3: 'revertidos',
        4: 'cancelados',
      };

      this.totais = {
        tratativa: 0,
        distrato: 0,
        revertidos: 0,
        cancelados: 0,
      }

      for (const cancelamento of cancelamentos) {

        const field = fields[cancelamento.status];

        this.totais[field]++;
      }
    },
    setTotaisVertital() {
      const { cancelamentosFiltered } = this;

      const fields = {
        1: 'motel',
        2: 'hotel',
        3: 'condominio',
        4: 'restaurante',
      };

      this.verticais = {
        motel: 0,
        hotel: 0,
        condominio: 0,
        restaurante: 0,
      }

      for (const cancelamento of cancelamentosFiltered) {

        const field = fields[cancelamento.cliente?.vertical];

        this.verticais[field]++;
      }
    },
    filterCancelamentos() {
      const { status, vertical, search } = this;

      let items = [
        ...this.cancelamentos
      ];

      if (status) {
        items = items.filter(item => item.status == status);
      }

      if (vertical) {
        items = items.filter(item => item.cliente?.vertical == vertical);
      }

      if (search) {
        items = items.filter(item => (item.search ?? '').toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      this.cancelamentosFiltered = items;

    },
    filterStatus(status) {
      this.status = this.status == status ? 0 : status;
      this.vertical = 0;
      this.filterCancelamentos();

    },
    isStatusSelected(status) {
      return this.status == status;
    },
    filterVertical(vertical) {
      this.vertical = this.vertical == vertical ? 0 : vertical;
      this.filterCancelamentos();

    },
    isVerticalSelected(vertical) {
      return this.vertical == vertical;
    },
    exportData() {
      exportToCSV('cancelamentos-list', `cancelamentos.csv`);
    },
  },
  watch: {
    search: function () {
      this.filterCancelamentos();
    },
    status: function () {
      this.setTotaisVertital();
    },
  }
};
</script>