<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="mx-auto col-8">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <back-button class="me-2" />
              <div class="text-center">
                <h6 class="mb-0">{{ id == 'new' ? 'Nova meta' : 'Editar meta' }}</h6>
                <p class="mb-0 text-sm">Criar/Editar meta</p>
              </div>
              <submit-button @click="onSubmit" />
            </div>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="onSubmit">
              <div class="row mb-1">
                <div class="col-md-2">
                  <label>Data</label>
                  <datepicker v-model="v$.meta.data.$model" minimum-view="month"
                    class="form-control text-bold text-center" :locale="locale" input-format="MM/yyyy" />
                  <div class="input-errors" v-for="(error, index) of v$.meta.data.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>Total</label>
                  <money3 v-model="v$.meta.valorTotal.$model" class="form-control text-right" v-bind="config"
                    @blur="onTotalExit" />
                  <div class="input-errors" v-for="(error, index) of v$.meta.valorTotal.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>MRR</label>
                  <money3 v-model="v$.meta.valorMrr.$model" class="form-control text-right" v-bind="config" />
                  <div class="input-errors" v-for="(error, index) of v$.meta.valorMrr.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>ARR</label>
                  <money3 v-model="v$.meta.valorArr.$model" class="form-control text-right" v-bind="config" />
                  <div class="input-errors" v-for="(error, index) of v$.meta.valorArr.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>Consultoria</label>
                  <money3 v-model="v$.meta.valorConsultoria.$model" class="form-control text-right" v-bind="config" />
                  <div class="input-errors" v-for="(error, index) of v$.meta.valorConsultoria.$errors" :key="index">
                    <div class="text-danger text-xs">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label>UpSell</label>
                  <money3 v-model="v$.meta.valorUpSell.$model" class="form-control text-right" v-bind="config" />
                </div>

                <!-- <div class="col-md-2">
                  <label>Churn</label>
                  <money3 v-model="v$.meta.valorChurn.$model" class="form-control text-right" v-bind="config" />
                </div> -->
              </div>

              <hr class="mt-4 mb-2 dark">
              <h6 class="text-center">
                Selecione os Closers para criar as metas ({{ closersSelected.length }}/{{ closers.length }})
              </h6>
              <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#internos"
                    type="button" role="tab" aria-controls="home" aria-selected="true">
                    INTERNOS({{ internos.length }})
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#representantes"
                    type="button" role="tab" aria-controls="representantes" aria-selected="false">
                    REPRESENTANTES({{ representantes.length }})
                  </button>
                </li>
              </ul>

              <div class="tab-content">
                <div class="tab-pane active" id="internos" role="tabpanel" aria-labelledby="home-tab">
                  <div class="closers-container">
                    <closer-item v-for="closer of internos" :key="closer.id" :closer="closer" @select="onCloserSelect"
                      :is-selected="isCloserSelected(closer)" />
                  </div>
                  <hr class="m-2 mb-2 dark">
                  <div class="row">
                    <div class="col-md-2">
                      <label>Meta Individual</label>
                      <money3 v-model="metaCloser.valorTotal" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>% ARR</label>
                      <money3 v-model="metaCloser.percentualArr" class="form-control text-center form-control-sm"
                        v-bind="percentageConfig" />
                    </div>
                    <div class="col-md-2">
                      <label>Consultoria</label>
                      <money3 v-model="metaCloser.valorConsultoria" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>Upsell</label>
                      <money3 v-model="metaCloser.valorUpSell" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>Super Meta</label>
                      <money3 v-model="metaCloser.valorSuperMeta" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>&nbsp;</label>
                      <button type="button" class="btn btn-primary btn-sm form-control" @click="createMetaClosers()">
                        Criar Metas
                      </button>
                    </div>
                  </div>
                  <table class="table table-flush">
                    <thead>
                      <tr>
                        <th>Closer</th>
                        <th class="text-right">Meta</th>
                        <th class="text-center">% ARR</th>
                        <th class="text-right">Consultoria</th>
                        <th class="text-right">UpSell</th>
                        <th class="text-right">Super Meta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item of metasClosers" :key="item.id">
                        <td>
                          <span class="my-auto text-xs">
                            {{ filters.firstAndLastName(item.closer.nome) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorTotal) }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.percentualArr, 0) }}%
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorConsultoria) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs">
                            {{ filters.currency(item.valorUpSell) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorSuperMeta) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane" id="representantes" role="tabpanel" aria-labelledby="profile-tab">
                  <div class="closers-container">
                    <closer-item v-for="closer of representantes" :key="closer.id" :closer="closer"
                      @select="onRepresentanteSelect" :is-selected="isRepresentanteSelected(closer)" />
                  </div>
                  <hr class="m-2 mb-2 dark">
                  <div class="row">
                    <div class="col-md-2">
                      <label>Meta Individual</label>
                      <money3 v-model="metaRepresentante.valorTotal" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>% ARR</label>
                      <money3 v-model="metaRepresentante.percentualArr" class="form-control text-center form-control-sm"
                        v-bind="percentageConfig" />
                    </div>
                    <div class="col-md-2">
                      <label>Consultoria</label>
                      <money3 v-model="metaRepresentante.valorConsultoria"
                        class="form-control text-center form-control-sm" v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>Upsell</label>
                      <money3 v-model="metaRepresentante.valorUpSell" class="form-control text-center form-control-sm"
                        v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>Super Meta</label>
                      <money3 v-model="metaRepresentante.valorSuperMeta"
                        class="form-control text-center form-control-sm" v-bind="config" />
                    </div>
                    <div class="col-md-2">
                      <label>&nbsp;</label>
                      <button type="button" class="btn btn-primary btn-sm form-control"
                        @click="createMetaRepresentantes()">
                        Criar Metas
                      </button>
                    </div>
                  </div>
                  <table class="table table-flush">
                    <thead>
                      <tr>
                        <th>Closer</th>
                        <th class="text-right">Meta</th>
                        <th class="text-center">% ARR</th>
                        <th class="text-right">Consultoria</th>
                        <th class="text-right">UpSell</th>
                        <th class="text-right">Super Meta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item of metasRepresentantes" :key="item.id">
                        <td>
                          <span class="my-auto text-xs">
                            {{ filters.firstAndLastName(item.closer.nome) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorTotal) }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.percentualArr, 0) }}%
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorConsultoria) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs">
                            {{ filters.currency(item.valorUpSell) }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span class="my-auto text-xs font-weight-bolder">
                            {{ filters.currency(item.valorSuperMeta) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import { successToast, errorToast, warningToast } from '@/utils/alerts';
import Datepicker from 'vue3-datepicker'
import api from '../../../services/api'
import { ptBR } from 'date-fns/locale'
import { MONEY_CONFIG, PERCENTAGE_CONFIG } from '../../../utils/number'
import BackButton from '@/components/BackButton.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import CloserItem from './components/CloserItem.vue';
import moment from 'moment';

export default {
  name: "Meta",
  components: {
    Datepicker,
    BackButton,
    SubmitButton,
    CloserItem
  },
  created() {
    this.id = this.$route.params.id;

    this.fetchData();
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      id: null,
      locale: ptBR,
      config: MONEY_CONFIG,
      percentageConfig: PERCENTAGE_CONFIG,
      metaCloser: {
        valorTotal: 0,
        valorSuperMeta: 0,
        valorUpSell: 0,
        valorConsultoria: 0,
        percentualArr: 0,
      },
      metaRepresentante: {
        valorTotal: 0,
        valorSuperMeta: 0,
        valorUpSell: 0,
        valorConsultoria: 0,
        percentualArr: 0,
      },
      closers: [],
      internos: [],
      representantes: [],
      closersSelected: [],
      representantesSelected: [],
      meta: {
        id: null,
        data: new Date(),
        valorMrr: 0,
        valorArr: 0,
        valorConsultoria: 0,
        valorUpSell: 0,
        valorTotal: 0,
        valorChurn: 0,
      },
      metasClosers: [],
      metasRepresentantes: []
    }
  },
  validations() {
    return {
      meta: {
        data: {
          required: helpers.withMessage('Data é obrigatória', required),
        },
        valorTotal: {
          required: helpers.withMessage('Total é obrigatorio', required),
          minValue: helpers.withMessage('Total é obrigatório', minValue(0.01)),
        },
        valorMrr: {
          required: helpers.withMessage('MRR é obrigatorio', required),
          minValue: helpers.withMessage('MRR é obrigatório', minValue(0.01)),
        },
        valorArr: {
          required: helpers.withMessage('ARR é obrigatorio', required),
          minValue: helpers.withMessage('ARR é obrigatório', minValue(0.01)),
        },
        valorConsultoria: {
          required: helpers.withMessage('Consultoria é obrigatoria', required),
          minValue: helpers.withMessage('Consultoria é obrigatória', minValue(0.01)),
        },
        valorUpSell: {},
        valorChurn: {},
      },
    }
  },
  methods: {
    onTotalExit() {
      const { valorTotal } = this.meta;

      if (valorTotal > 0) {
        this.meta.valorMrr = valorTotal * 0.7;
        this.meta.valorArr = (valorTotal * 0.3) * 12;
      }
    },
    onCloserSelect(closer) {
      const index = this.closersSelected.findIndex(c => c.id === closer.id);

      if (index === -1) {
        this.closersSelected.push(closer);
      } else {
        this.closersSelected.splice(index, 1);
      }
    },
    isCloserSelected(closer) {
      return this.closersSelected.some(c => c.id === closer.id);
    },
    onRepresentanteSelect(representante) {
      const index = this.representantesSelected.findIndex(c => c.id === representante.id);

      if (index === -1) {
        this.representantesSelected.push(representante);
      } else {
        this.representantesSelected.splice(index, 1);
      }
    },
    isRepresentanteSelected(closer) {
      return this.representantesSelected.some(c => c.id === closer.id);
    },
    fetchData() {
      const { id } = this;

      this.getClosers();

      if (id != 'new') {
        api.get(`/metas/${id}`)
          .then(({ data }) => {
            const date = moment(data.data).toDate();

            this.meta = {
              ...data,
              data: date
            };

            const metasClosers = this.meta.metaClosers.filter(c => c.closer.tipo == 1);
            const metasRepresentantes = this.meta.metaClosers.filter(c => c.closer.tipo == 2);

            this.closersSelected = metasClosers.map(mc => mc.closer);
            this.representantesSelected = metasRepresentantes.map(mc => mc.closer);

            this.metasClosers = metasClosers;
            this.metasRepresentantes = metasRepresentantes;

            this.setValoresMetaClosers();
            this.setValoresMetaRepresentantes();
          })
          .catch(e => console.log(e));
      }

    },
    getClosers() {
      api.get(`/closers/active`)
        .then(({ data }) => {
          this.closers = data;

          this.internos = data.filter(closer => closer.tipo === 1);
          this.representantes = data.filter(closer => closer.tipo === 2);
        })
        .catch(e => console.log(e));
    },
    setValoresMetaClosers() {
      if (this.metasClosers.length > 0) {
        const {
          valorTotal,
          valorSuperMeta,
          valorUpSell,
          valorConsultoria,
          percentualArr
        } = this.metasClosers[0];

        this.metaCloser = {
          valorTotal,
          valorSuperMeta,
          valorUpSell,
          valorConsultoria,
          percentualArr
        }
      }
    },
    setValoresMetaRepresentantes() {
      if (this.metasRepresentantes.length > 0) {
        const {
          valorTotal,
          valorSuperMeta,
          valorUpSell,
          valorConsultoria,
          percentualArr
        } = this.metasRepresentantes[0];

        this.metaRepresentante = {
          valorTotal,
          valorSuperMeta,
          valorUpSell,
          valorConsultoria,
          percentualArr
        }
      }
    },
    createMetaClosers() {
      const { metaCloser, closersSelected } = this;

      if (closersSelected.length == 0) {
        warningToast('Nenhum closer selecionado');
        return;
      }

      const metasClosers = [];

      for (const closer of closersSelected) {
        metasClosers.push({
          closer,
          ...metaCloser
        });
      }

      this.metasClosers = metasClosers;
    },
    createMetaRepresentantes() {
      const { metaRepresentante, representantesSelected } = this;

      if (representantesSelected.length == 0) {
        warningToast('Nenhum Representante selecionado');
        return;
      }

      const metasRepresentantes = [];

      for (const closer of representantesSelected) {
        metasRepresentantes.push({
          closer,
          ...metaRepresentante
        });
      }

      this.metasRepresentantes = metasRepresentantes;
    },
    async onSubmit() {
      const isFormValid = await this.v$.$validate();

      if (!isFormValid) {
        return;
      }

      if (this.metasClosers.length == 0) {
        warningToast('Metas individuais Dos Closers devem ser criadas');
        return;
      }

      this.meta.metaClosers = [...this.metasClosers, ...this.metasRepresentantes];

      try {
        const payload = {
          ...this.meta
        }

        await (this.id == 'new' ?
          api.post('/metas', payload) :
          api.patch(`/metas/${this.id}`, payload));

        successToast('Meta salva com sucesso.');

        this.$router.push({ name: 'Metas' });

      } catch (e) {
        const { data } = e;
        const message = data ? data.message : e.message;
        errorToast(message)
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.closers-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}
</style>
