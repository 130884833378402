import { infoToast } from './alerts';

export const exportToCSV = (tableName, fileName = 'table.csv', delimiter = ';') => {
    console.log('Exporting table to CSV...');

    const tableData = Array.from(document.getElementById(tableName).rows);

    if (tableData.length <= 1) {
        infoToast('Nenhum dado para exportar.', 'Exportar');
        return;
    }

    const csvRows = [];

    // Create the data rows
    tableData.forEach((row) => {
        const rowData = row.querySelectorAll('td,th');
        const values = [];

        for (let i = 0; i < rowData.length; i++) {
            let value = (rowData[i].textContent ?? '')
                .replace(new RegExp(delimiter, 'g'), '|')
                .replace(new RegExp('\\n', 'g'), '|');

            values.push(value);
        }
        csvRows.push(values.join(delimiter));
    });

    // Join all rows with line breaks
    const csvContent = csvRows.join('\n');

    // Create a Blob object and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
}

export const exportToExcel = (tableName, fileName = 'table.xlsx') => {
    console.log('Exporting table to Excel...');

    const tableData = document.getElementById(tableName).rows;

    if (tableData.length <= 1) {
        infoToast('Nenhum dado para exportar.', 'Exportar');
        return;
    }

    const tableHeader = tableData[0].querySelectorAll('td,th');

    const headerRow = [];

    for (let i = 0; i < tableHeader.length; i++) {
        headerRow.push(tableHeader[i].innerHTML);
    }

    const dataRows = [];

    // Create the data rows
    tableData.forEach((row) => {
        const values = Object.values(row).join(',');
        dataRows.push(values);
    });

    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    document.body.removeChild(link);
}
