<template>
  <span :class="['badge', `badge-${size}`, value ? 'badge-success' : 'badge-danger']">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeSimNao",
  props: {
    value: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      return this.value ? 'SIM' : 'NÃO';
    }
  }
}
</script>
