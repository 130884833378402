<template>
  <div class="card h-100">
    <div class="p-2 pb-0 card-header">
      <div class="d-flex justify-content-center">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="pb-2 card-body">
      <nenhum-registro-encontrado :hidden="series.length > 0" />
      <div class="row" :hidden="series.length == 0">
        <div class="col-6 text-start">
          <div class="chart">
            <canvas :id="chartId" class="chart-canvas" height="140"></canvas>
          </div>
        </div>
        <div class="my-auto col-6">
          <span v-for="(serie, index) of series" class="badge badge-md badge-dot me-0 d-block text-start item-serie"
            :class="selectedItem == serie.label ? 'is-selected' : ''" :key="index" @click="onSerieClick(serie)">
            <i :class="getDotClass(index)"></i>
            <span class="text-xs text-dark">
              {{ serie.label }} - {{ serie.value }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NenhumRegistroEncontrado from "@/components/NenhumRegistroEncontrado.vue";
import { randomInt } from "@/utils/number";
import Chart from "chart.js/auto";
import * as uuid from 'uuid';

export default {
  name: "PizzaChurnCard",
  components: {
    NenhumRegistroEncontrado,
  },
  props: {
    title: {
      type: String,
      default: "Traffic Channels",
    },
    onSerieClick: {
      type: Function,
      default: () => { },
    },
    selectedItem: {
      type: String,
      default: null,
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.chartId = `chart-pie_${uuid.v4()}`;
  },
  data() {
    return {
      chartId: "chart-pie",
    };
  },
  methods: {
    creatChart() {


      const random = randomInt(100, 300);

      setTimeout(() => {
        var pieChart = document.getElementById(this.chartId).getContext("2d");

        let chartStatus = Chart.getChart(this.chartId);

        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        const labels = this.series.map((serie) => serie.label);
        const colors = this.series.map((serie, index) => this.getSerieColor(index));
        const values = this.series.map((serie) => serie.value);

        // Pie chart
        new Chart(pieChart, {
          type: "pie",
          data: {
            labels,
            datasets: [
              {
                label: 'Quantidade',
                weight: 9,
                cutout: 0,
                tension: 0.9,
                pointRadius: 2,
                borderWidth: 2,
                backgroundColor: colors,
                data: values,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }, random);
    },
    getDotClass(index) {
      const colors = ['primary', 'info', 'success', "warning", "dark", 'danger'];
      return `bg-${colors[index]}`;
    },
    getSerieColor(index) {
      const colors = ['#5e72e4', '#11cdef', '#2dce89', '#FBD38D', '#344767', '#f5365c'];

      return `${colors[index]}95`;
    },
  },
  watch: {
    series: {
      handler() {
        this.creatChart();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.item-serie {
  cursor: pointer;

  &.is-selected {
    background-color: #f8f9fe;
    padding: 8px;
    border: 1px solid #6980f2;
  }
}
</style>
