<template>
  <span :class="['badge', 'badge-sm', color]">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeTipoReceita",
  props: {
    tipo: {
      type: String,
      default: 'mrr'
    }
  },
  data() {
    return {};
  },
  computed: {
    label() {
      return this.tipo.toUpperCase();
    },
    color() {
      const colors = {
        mrr: 'badge-primary',
        arr: 'badge-secondary',
        consultoria: 'badge-dark',
        outros: 'badge-info',
        pessoas: 'badge-primary',
        fixas: 'badge-secondary',
        variaveis: 'badge-dark'
      };

      return colors[this.tipo];
    }
  }
}
</script>
