<template>
  <span :class="['badge', color, 'badge-sm']">
    {{ status }}
  </span>
</template>
<script>
export default {
  name: "BadgeStatus",
  props: {
    status: {
      type: String,
      default: 'PENDENTE'
    }
  },
  computed: {
    color() {
      const colors = {
        PENDENTE: 'badge-danger',
        PAGO: 'badge-success',
      };

      return colors[this.status];
    }
  }
}
</script>
