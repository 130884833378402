<template>
  <argon-badge :size="size" variant="gradient" :color="color">
    {{ label }}
  </argon-badge>
</template>
<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
  name: "BadgeStatusCancelamento",
  components: {
    ArgonBadge,
  },
  props: {
    status: {
      type: [Number],
      default: 1
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      const labels = ['NOVA SOLICITAÇÃO', 'EM TRATATIVA', 'AGUARD. DISTRATO', 'REVERTIDO', 'CANCELADO'];

      return labels[this.status];
    },
    color() {
      const colors = ['secondary', 'primary', 'warning', 'success', 'danger'];

      return colors[this.status];
    }
  }
}
</script>
