<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 card-body">
      <nenhum-registro-encontrado :hidden="series.length > 0" :height="200" />
      <div class="chart" :hidden="series.length == 0">
        <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import NenhumRegistroEncontrado from "@/components/NenhumRegistroEncontrado.vue";
import { randomInt } from "@/utils/number";
import Chart from "chart.js/auto";
import * as uuid from 'uuid';

export default {
  name: "VendasPorteCard",
  components: {
    NenhumRegistroEncontrado,
  },  
  props: {
    title: {
      type: String,
      default: "Sales by Age",
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.chartId = `chart-pie_${uuid.v4()}`;
  },
  data() {
    return {
      chartId: "chart-pie",
    };
  },
  mounted() {
    this.creatChart();
  },
  methods: {
    creatChart() {

      const random = randomInt(100, 300);

      setTimeout(() => {
        var ctx3 = document.getElementById(this.chartId).getContext("2d");

        let chartStatus = Chart.getChart(this.chartId);

        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

        const labels = this.series.map((serie) => serie.label);
        const colors = this.series.map((serie, index) => this.getSerieColor(index));
        const values = this.series.map((serie) => serie.value);

        new Chart(ctx3, {
          type: "bar",
          data: {
            labels,
            datasets: [
              {
                label: 'Quantidade',
                weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: colors,
                data: values,
                fill: false,
              },
            ],
          },
          options: {
            indexAxis: "y",
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: true,
                },
                ticks: {
                  display: true,
                  color: "#9ca2b7",
                  padding: 10,
                },
              },
            },
          },
        });
      }, random);
    },
    getSerieColor(index) {
      const colors = ['#5e72e4', '#11cdef', '#2dce89', '#FBD38D', '#344767', '#f5365c'];

      return `${colors[index]}95`;
    },
  },
  watch: {
    series: {
      handler() {
        this.creatChart();
      },
      deep: true,
    },
  },
};
</script>
