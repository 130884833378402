<template>
  <span :class="['badge', `badge-${size}`, `badge-${color}`]">
    {{ label }}
  </span>
</template>
<script>
export default {
  name: "BadgeTipoCloser",
  props: {
    tipo: {
      type: [Number],
      default: 1
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      return this.tipo === 1 ? 'INTERNO' : 'REPRESENTANTE';
    },
    color() {
      return this.tipo === 1 ? 'primary' : 'dark';
    }
  }
}
</script>
