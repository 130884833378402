<template>
  <span :class="['badge', `badge-${size}`, 'badge-primary']">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgePlano",
  props: {
    plano: {
      type: Object,
      default: () => { }
    },
    porte: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    label() {
      return ((this.plano ? this.plano.descricao : this.porte) ?? '').toUpperCase();
    }
  }
}
</script>
