<template>
  <div :class="['semaforo', color]">
  </div>
</template>
<script>
export default {
  name: "BadgeSemaforo",
  props: {
    cor: {
      type: String,
      default: 'VERDE'
    }
  },
  computed: {
    color() {
      const colors = {
        'VERDE': 'bg-success',
        'AMARELO': 'bg-warning',
        'VERMELHO': 'bg-danger',
        'SEM_SINAL': 'bg-dark',
      };
      return colors[this.cor];
    }
  }
}
</script>
<style lang="scss" scoped>
.semaforo {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
</style>
