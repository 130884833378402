<template>
    <div :style="style">
        <h4 class="text-center">
            {{ message }}
        </h4>
    </div>
</template>
<script>
export default {
    name: "NenhumRegistroEncontrado",
    props: {
        message: {
            type: String,
            default: "Nenhum registro encontrado",
        },
        height: {
            type: Number,
            default: 140,
        },
    },
    computed: {
        style() {
            return {
                'height': `${this.height}px !important`,
                'min-height': `${this.height}px !important`,
                'position': 'relative',
                'flex': '1',
            };
        },
    },
}
</script>
<style lang="scss" scoped>
div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #6c757d;
}
</style>