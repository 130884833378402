<template>
  <div>
    <h6 :class="[dark ? 'text-white' : '', 'text-center']">{{ title }}</h6>


    <div class="timeline timeline-one-side" :data-timeline-axis-style="dark ? 'dashed' : 'dotted'">
      <timeline-historico-cliente-item v-for="(value, key) of historico" :key="key"
        :icon="{ component: 'ni ni-bell-55', color: 'success' }" :title="'Atualização'" :date-time="key" :items="value"
        :badges="['ATUALIZAÇÃO']" />
      <!-- <timeline-historico-cliente-item :icon="{ component: 'ni ni-bell-55', color: 'success' }"
          title="$2400 Design changes" date-time="22 DEC 7:20 PM"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['design']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-html5', color: 'danger' }"
          title="New order #1832412" date-time="21 DEC 11 PM"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['order', '#1832412']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-cart', color: 'info' }"
          title="Server payments for April" date-time="21 DEC 9:34 PM"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['server', 'payments']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-credit-card', color: 'warning' }"
          title="New card added for order #4395133" date-time="20 DEC 2:20 AM"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['Card', '#4395133', 'Priority']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-key-25', color: 'primary' }"
          title="Unlock packages for development" date-time="18 DEC 4:54 AM"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['Development']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-archive-2', color: 'success' }"
          title="New message unread" date-time="16 DEC"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['Message']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-check-bold', color: 'info' }"
          title="Notifications unread" date-time="15 DEC"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of." />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-box-2', color: 'warning' }" title="New request"
          date-time="14 DEC"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['Request', 'Priority']" />
        <timeline-historico-cliente-item :icon="{ component: 'ni ni-controller', color: 'dark' }"
          title="Controller issues" date-time="13 DEC"
          description="People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of."
          :badges="['Controller']" /> -->
    </div>
  </div>
</template>

<script>
import TimelineHistoricoClienteItem from "./TimelineHistoricoClienteItem.vue";
import api from "@/services/api";

export default {
  name: "TimelineHistoricoCliente",
  components: {
    TimelineHistoricoClienteItem,
  },
  props: {
    cliente: {
      type: Object,
      required: true,
      default: () => { },
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      historico: [],
    };
  },
  methods: {
    fetchData() {
      if (!this.cliente.id) {
        return;
      }

      api.get(`/clientes/${this.cliente.id}/historico`)
        .then(({ data }) => {
          this.historico = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    cliente: {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
  },
};
</script>