<template>
  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5 class="mb-0">
              Informações de Onboarding
            </h5>
          </div>
          <div class="card-body p-3">
            <div class="row mt-2">
              <clientes-counter-card :count="totalClientes.hotel" title="Hotel" :selected="isVerticalSelected('2')"
                @click="filterVertical('2')" />
              <clientes-counter-card :count="totalClientes.motel" title="Motel" :selected="isVerticalSelected('1')"
                @click="filterVertical('1')" />
              <clientes-counter-card :count="totalClientes.restaurante" title="Restaurante"
                :selected="isVerticalSelected('4')" @click="filterVertical('4')" />
              <clientes-counter-card :count="totalClientes.condominio" title="Condominio"
                :selected="isVerticalSelected('3')" @click="filterVertical('3')" />
            </div>
            <div class="row mt-2" v-if="vertical == 2">
              <clientes-counter-card v-for="item of portes" :count="item.count()" :title="item.name"
                :selected="item.isSelected()" :key="item.id" @click="item.onClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">
                  Clientes({{ onboardingsFiltered.length }})
                </h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12">
                <label>Pesquisar Cliente</label>
                <input class="form-control text-uppercase" v-model="search" placeholder="Nome Fantasia, CPF/CNPJ">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12" v-for="onboarding of onboardingsFiltered" :key="onboarding.id">
                <onboarding-card :onboarding="onboarding" @on-log-click="onLogClick" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-info-onboaring" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              {{ onboardingData.cliente?.nomeFantasia }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-between">
              <label>Documento:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.formatDocument(onboardingData.cliente?.documento) }}
              </span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <label>Operação:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ onboardingData.title }}
              </span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <label>Status:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ onboardingData.status ? 'Concluído' : 'Pendente' }}
              </span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <label>Início:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ filters.formatDateTime(onboardingData.start, 'DD/MM/YYYY HH:mm:ss') }}
              </span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <label>Término:</label>
              <span class="font-weight-bold text-primary text-sm">
                {{ onboardingData.end ? filters.formatDateTime(onboardingData.end, 'DD/MM/YYYY HH:mm:ss') : '***' }}
              </span>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <label>Logs:</label>
            </div>
            <div class="d-flex justify-content-between mt-0 w-100">
              <pre>{{ onboardingData.logs }}</pre>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row-reverse justify-content-between">
            <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn bg-gradient-info btn-sm" data-bs-dismiss="modal"
              v-if="allowReprocessar(onboardingData)" @click="reprocessar(onboardingData)">
              Reprocessar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientesCounterCard from "./components/ClientesCounterCard.vue";
import OnboardingCard from './components/OnboardingCard.vue'
import api from '@/services/api';
import { errorToast, showLoading, successToast } from '@/utils/alerts';

export default {
  name: "Onboarding",
  components: {
    OnboardingCard,
    ClientesCounterCard,
  },
  data() {
    return {
      onboardings: [],
      onboardingsFiltered: [],
      status: 'A',
      porte: '',
      vertical: '',
      search: '',
      modulosConferidos: null,
      params: {
        nome: '',
        status: 'T',
        vertical: 'T'
      },
      portes: [
        { id: 1, name: 'Basic', count: () => this.totalClientes.basic, isSelected: () => this.isPorteSelected(1), onClick: () => this.filterPorte(1) },
        { id: 2, name: 'Starter', count: () => this.totalClientes.starter, isSelected: () => this.isPorteSelected(2), onClick: () => this.filterPorte(2) },
        { id: 3, name: 'Pro', count: () => this.totalClientes.pro, isSelected: () => this.isPorteSelected(3), onClick: () => this.filterPorte(3) },
        { id: 4, name: 'Enterprise', count: () => this.totalClientes.enterprise, isSelected: () => this.isPorteSelected(4), onClick: () => this.filterPorte(4) },
      ],
      totalClientes: {
        starter: 0,
        basic: 0,
        pro: 0,
        enterprise: 0,
        hotel: 0,
        motel: 0,
        restaurante: 0,
        condominio: 0,
        modulosConferidos: 0,
        modulosNaoConferidos: 0,
      },
      statusReprocessar: [
        'Hotel - null',
        'Zello - null',
        'Conexa Cliente - null'
      ],
      onboardingData: {}
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;

      api.get(`/onboarding`)
        .then(response => {
          const { data } = response;

          this.onboardings = data;
          this.filterOnboardings();
          this.setTotalOnboardings();
        })
        .catch(e => console.log(e));
    },
    onSetStatus(status) {
      this.status = status;

      this.filterModules();
    },
    filterOnboardings() {
      const { search, porte, vertical } = this;

      let items = [
        ...this.onboardings
      ];

      if (search) {
        items = items.filter(item => item.search.toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      if (vertical) {
        items = items.filter(item => item.cliente.vertical == vertical);
      }

      if (porte && vertical == 2) {
        items = items.filter(item => item.cliente.plano ? item.cliente.plano.id == porte : false);
      }

      this.onboardingsFiltered = items;

    },
    filterStatus(status) {
      this.status = this.status == status ? 0 : status;

      this.porte = '';
      this.vertical = '';

      this.filterOnboardings();
      this.setTotalClientes();
    },
    isStatusSelected(status) {
      return this.status == status;
    },
    filterPorte(porte) {
      this.porte = this.porte == porte ? 0 : porte;

      this.modulosConferidos = null;

      this.filterOnboardings();
    },
    isPorteSelected(porte) {
      return this.porte == porte;
    },
    filterVertical(vertical) {
      this.vertical = this.vertical == vertical ? 0 : vertical;
      this.porte = '';

      this.filterOnboardings();
    },
    isVerticalSelected(vertical) {
      return this.vertical == vertical;
    },
    setTotalOnboardings() {
      const { onboardingsFiltered } = this;

      this.totalClientes = {
        basic: 0,
        starter: 0,
        pro: 0,
        enterprise: 0,
        hotel: 0,
        motel: 0,
        restaurante: 0,
        condominio: 0,
        modulosConferidos: 0,
        modulosNaoConferidos: 0,
      }

      for (const onboarding of onboardingsFiltered) {
        const { cliente } = onboarding;

        const { plano, vertical } = cliente;

        const planoId = plano ? plano.id : 0;

        if (vertical == 2) {
          switch (planoId) {
            case 2: this.totalClientes.starter++;
              break;
            case 3: this.totalClientes.pro++;
              break;
            case 4: this.totalClientes.enterprise++;
              break;
            default:
              this.totalClientes.basic++;
              break;
          }
        }

        switch (vertical) {
          case 1:
            this.totalClientes.motel++;
            break;
          case 2:
            this.totalClientes.hotel++;
            break;
          case 3:
            this.totalClientes.condominio++;
            break;
          case 4:
            this.totalClientes.restaurante++;
            break;
        }
      }
    },
    totalConferidos(value) {
      return this.clientesFiltered.filter(item => item.modulosConferidos == value).length;
    },
    isModulosConferidos(value) {
      return this.modulosConferidos == value;
    },
    filterModulosConferidos(value) {
      this.modulosConferidos = this.modulosConferidos == value ? null : value;

      this.filterOnboardings();
    },
    onLogClick(data) {
      this.onboardingData = {
        ...data
      };
    },
    allowReprocessar(onboardingData) {
      if (onboardingData.status) {
        return false;
      }
      return this.statusReprocessar.includes(onboardingData.title);
    },
    async reprocessar(onboardingData) {
      const { id } = onboardingData;

      if (!this.statusReprocessar.includes(onboardingData.title)) {
        return;
      }

      const onboarding = this.onboardings.find(item => item.id == id);

      if (!onboarding) {
        return;
      }

      const type = onboardingData.title.split(' ')[0].toLowerCase();

      const loading = showLoading(`Aguarde, reprocessando Base "${type.toUpperCase()}"...`);

      const endpoint = `create-empresa-${type}/${onboarding.id}`;

      try {
        await api.patch(`/onboarding/${endpoint}`);
        successToast('Onboarding reprocessado com sucesso');

        loading.close();

        setTimeout(() => {
          window.location.reload();

        }, 500);
      } catch (error) {
        const { message } = error.data;
        loading.close();
        errorToast(message ?? 'Erro ao reprocessar onboarding');
      }
    }
  },
  watch: {
    search: function () {
      this.filterOnboardings();
    },
  }
};
</script>
<style lang="scss">
.btn {
  margin-bottom: 0 !important;
}

pre {
  background-color: #f6f8fa;
  background-color: var(--md-code-background, #f6f8fa);
  color: inherit;
  color: var(--md-code-text, inherit);
  border-radius: 3px;
  border-radius: var(--markdown-radius, 3px);
  border-radius: var(--md-code-radius, var(--markdown-radius, 3px));
  font-size: var(--font-size);
  line-height: 1.45;
  overflow: auto;
  padding: 1em;
  width: 100%;
}
</style>
