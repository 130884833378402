<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Módulos</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Modulo', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      +&nbsp; Novo Módulo
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <filtro-modulos :status="status" :set-status="onSetStatus" v-model="search" />
          </div>
          <div class="px-4 py-2 card-body">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="modulo in modulosFiltered" :key="modulo.id">
                <modulo-card :modulo="modulo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuloCard from './components/ModuloCard.vue'
import FiltroModulos from './components/FiltroModulos.vue'
import api from './../../services/api';

export default {
  name: "Modulos",
  components: {
    ModuloCard,
    FiltroModulos
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      modulos: [],
      modulosFiltered: [],
      status: 'T',
      search: ''
    }
  },
  methods: {
    fetchData() {
      api.get(`/modulos`).then(response => {
        const { data } = response;

        this.modulos = data;
        this.modulosFiltered = data;

      }).catch(e => console.log(e));
    },
    onSetStatus(status) {
      this.status = status;
      this.filterModules();
    },
    filterModules() {
      const { status, search } = this;

      let items = [
        ...this.modulos
      ];

      if (status != 'T') {
        items = this.modulos.filter(item => status == 'A' ? item.ativo : !item.ativo);
      }

      if (search) {
        items = items.filter(item =>
          item.descricao.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.modulo.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      }

      this.modulosFiltered = items;

    }
  },
  watch: {
    search: function () {
      this.filterModules();
    }
  }
};
</script>