<template>
  <div class="mb-3 timeline-block">
    <span class="timeline-step p-3" :class="`${$parent.dark ? 'bg-dark' : 'bg-'}${color}`">
      <i :class="`text-${icon.color} text-gradient ${icon.component}`"></i>
    </span>
    <div class="timeline-content">
      <h6 class="mb-0 font-weight-bold" :class="$parent.dark ? 'text-white' : 'text-dark'">
        <span :class="`badge bg-gradient-${icon.color}`">
          {{ title }}
        </span>
      </h6>
      <p class="mt-1 mb-0 text-xs text-secondary font-weight-normal">
        {{ dateTime }}
      </p>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table" width="100%">
              <!-- <thead>
                <tr>
                  <th style="width: 40%" class="text-left">Cliente</th>
                  <th style="width: 20%" class="text-center">Operação</th>
                  <th style="width: 20%" class="text-center" v-if="!previsto">Tipo</th>
                  <th style="width: 20%" class="text-right">Valor</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="item of items" :key="item.id">
                  <td style="width: 40%" class="text-xs">
                    {{ item.pessoa }}
                  </td>
                  <td style="width: 20%" class="text-center">
                    <badge-tipo-receita :tipo="item.operacao" />
                  </td>
                  <td style="width: 20%" class="text-center" v-if="previsto">
                    <badge-status-conta :status="item.status" />
                  </td>
                  <td style="width: 20%" class="text-center" v-else>
                    <badge-tipo-lancamento :lancamento="item" />
                  </td>
                  <td style="width: 20%" class="text-right text-bg-dark text-bold text-sm">
                    {{ filters.currencyWithPrefix(item.valor) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-right text-sm" colspan="4">
                    {{ previsto ? 'Total Previsto' : 'Total Recebido' }}: <strong>
                      {{ filters.currencyWithPrefix(totalItems) }}
                    </strong>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- <span v-for="(badge, index) of badges" :key="index" :class="`badge badge-sm bg-gradient-${icon.color} me-1`">
        {{ badge }}
      </span> -->
    </div>
  </div>
</template>
<script>
import BadgeTipoReceita from "@/views/pages/components/BadgeTipoReceita.vue";
import BadgeStatusConta from "@/views/pages/components/BadgeStatusConta.vue";
import BadgeTipoLancamento from "@/views/pages/components/BadgeTipoLancamento.vue";

export default {
  name: "TimeLineReceitas",
  components: {
    BadgeTipoReceita,
    BadgeTipoLancamento,
    BadgeStatusConta,
  },
  props: {
    color: {
      type: String,
      default: "",
    },
    previsto: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      required: true,
      component: String,
      color: String,
    },
    title: {
      type: String,
      required: true,
    },
    dateTime: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalItems() {
      return this.items.reduce((acc, item) => acc + Number(item.valor), 0);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 100%;
  }
}
</style>