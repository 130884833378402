<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Notas de Versão</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Nota de Versão', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      <i class="fas fa-plus"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <data-table :data="data" :columns="columns" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import DataTable from '@/views/pages/components/DataTable.vue';

export default {
  name: "Closers",
  components: {
    DataTable
  },
  data() {
    return {
      columns: [
        {
          name: "Versão",
          key: "versao",
          class: "text-left text-bold",
        },
        {
          name: "Descrição",
          key: "descricao",
          class: "",
        },
        {
          name: "Data Prevista",
          key: "dataPrevista",
          class: "text-center text-bold",
        },
        {
          name: "Liberada",
          key: "liberada",
          class: "text-bold",
        },
        {
          name: "Ações",
          key: "action",
          class: "text-center text-bold",
        },
      ],
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/notas-versao`)
        .then(response => {
          const { data } = response;

          this.data = data.map(notaVersao => {
            const { id, dataPrevista, liberada } = notaVersao;

            return {
              ...notaVersao,
              dataPrevista: dataPrevista ? new Date(dataPrevista).toLocaleDateString() : '',
              liberada: liberada ? 'SIM' : 'NÃO',
              action: `<a href="/admin/pages/nota-versao/${id}" class="btn btn-xs btn-primary">Editar</a>`,
            };
          });
        })
        .catch(e => console.log(e));
    }
  }

};
</script>
