<template>
  <div class="col-md-4 col-sm-6">
    <div class="card mb-2" @click="onClick()">
      <div class="p-2 text-center card-header">
        <span class="text-uppercase font-weight-bold text-dark overflow-text">
          {{ empresa.nome_fantasia }}
        </span>
        <h6 class="mt-0 mb-0 text-muted text-xs overflow-text">
          {{ empresa.cidade }}
        </h6>
      </div>
      <div class="p-2 text-center card-body">
        <div class="d-flex justify-content-between align-items-center">
          <label>ID</label>
          <h6 class="mb-0 text-xs">
            {{ empresa.id }}
          </h6>
        </div>
        <hr class="mt-2 mb-2 horizontal dark">
        <div class="d-flex justify-content-between align-items-center">
          <label>CPF/CNPJ</label>
          <h6 class="mb-0 text-xs">
            {{ filters.formatDocument(empresa.cpf_cnpj) }}
          </h6>
        </div>
        <hr class="mt-2 mb-2 horizontal dark">
        <div class="d-flex justify-content-between align-items-center">
          <label>Status</label>
          <h6 class="mb-0">
            <badge-status :ativo="empresa.ativo" size="xs" />
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeStatus from "@/views/pages/components/BadgeStatus.vue";

export default {
  name: "EmpresaHotelCard",
  components: {
    BadgeStatus,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
      default: () => { }
    },
    onSelect: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    onClick() {
      this.onSelect(this.empresa);
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  border: 0.4px solid #212529;
  cursor: pointer;

  .card-body {
    padding: 1rem;

    label {
      text-align: left;
    }
  }

  .overflow-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
