<template>
  <div :class="['mt-2', `col-lg-${size}`, 'col-md-6 col-sm-12 mt-sm-2']">
    <div class="card mb-2" :class="hasDetails ? 'has-details' : ''">
      <div class="p-3 card-body">
        <div class="d-flex" :class="rowReverse ? '' : 'justify-content-between align-items-center'">
          <div class="numbers">
            <div class="d-flex justify-content-between w-100 align-items-center">
              <p class="mb-0 text-sm text-uppercase font-weight-bold">
                {{ title }}
              </p>
              <div class="meta-percentage " :class="percentageColor">
                {{ percentage }}
              </div>
            </div>
            <h5 :class="`mb-0 font-weight-bolder`">
              {{ filters.currency(venda) }}/<span class="span-meta"> {{ filters.thousandsToInt(meta) }}</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TotaisMetaCard",
  props: {
    size: {
      type: Number,
      default: 2,
    },
    hasDetails: {
      type: Boolean,
      default: false,
    },
    rowReverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [Object, String],
      required: true,
      text: {
        type: String,
      },
      color: {
        type: String,
      },
    },
    description: {
      type: String,
      default: "",
    },
    venda: {
      type: [String, Number],
      default: 0,
    },
    meta: {
      type: [String, Number],
      default: 0,
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      shape: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
        shape: " border-radius-md",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
  },
  computed: {
    percentage() {
      if (this.meta == 0) {
        return '0%';
      }
      return ((Number(this.venda ?? 0) / Number(this.meta ?? 0)) * 100).toFixed(0) + '%';
    },
    percentageColor() {
      const percentage = Number(this.percentage.replace('%', ''));

      if (percentage >= 90) {
        return 'text-success';
      } else if (percentage >= 60) {
        return 'text-warning';
      } else {
        return 'text-danger';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.numbers {
  width: 100%;  
}

.has-details {
  cursor: pointer;
}

.span-meta {
  font-size: 12px;
}

.meta-percentage {
  font-size: 1em;
  font-weight: 700;

  &.text-success {
    color: #2dce89 !important;
  }

  &.text-danger {
    color: #f5365c !important;
  }

  &.text-warning {
    color: #f16e54 !important;
  }
}
</style>
