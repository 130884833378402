<template>
  <div class="nav-bar-cadastro">
    <button type="button" :class="getButtonClass(button.index)" v-for="button of buttons" @click="onClick(button.index)"
      :key="button.index">
      {{ button.title() }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'NavBarCadastroCliente',
  props: {
    cliente: {
      type: Object,
      default: () => { }
    },
    activeTab: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      buttons: [
        {
          title: () => 'Informações Gerais',
          index: 0,
        },
        {
          title: () => `Módulos (${this.cliente.modulos.length})`,
          index: 1,
        },
        {
          title: () => `Bases (${this.cliente.empresas.length})`,
          index: 2,
        },
        {
          title: () => 'Ficha do Cliente',
          index: 3,
        }
      ],
      title: 'Cadastro de Cliente',
      subtitle: 'Preencha os campos abaixo para cadastrar um novo cliente'
    }
  },
  methods: {
    getButtonClass(index) {
      return this.activeTab == index ? 'btn btn-dark' : 'btn btn-outline-dark';
    }
  }
}
</script>
<style lang="scss">
.nav-bar-cadastro {
  display: flex;

  button {
    margin-right: 10px;
    flex: 1;
  }
}
</style>