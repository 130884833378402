<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Agentes CS</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Customer Success', params: { id: 'new' } }">
                    <button class="mt-1 mb-0 me-2 btn btn-outline-success btn-sm mt-sm-0" type="button" name="button">
                      +&nbsp; Novo Agente
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <data-table :data="data" :columns="columns" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import DataTable from '@/views/pages/components/DataTable.vue';

export default {
  name: "CustomerSuccesses",
  components: {
    DataTable
  },
  data() {
    return {
      columns: [
        {
          name: "Nome",
          key: "nome",
          class: "text-left text-bold",
        },
        {
          name: "Ativo",
          key: "ativo",
          class: "text-center text-bold",
        },
        {
          name: "Ações",
          key: "action",
          class: "text-center text-bold",
        },
      ],
      data: [],

    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      api.get(`/customer-successes`)
        .then(response => {
          const { data } = response;

          this.data = data.map(implantador => {
            return {
              id: implantador.id,
              nome: implantador.nome,
              ativo: implantador.ativo,
              action: `<a href="/admin/pages/customer-success/${implantador.id}" class="btn btn-xs btn-primary">Editar</a>`,
            };
          });
        })
        .catch(e => console.log(e));
    },
    exportData() {
      const type = 'csv';

      let data = {
        type: type,
        filename: `agentes-${this.vertical}`,
      };

      if (type === "csv") {
        data.columnDelimiter = ";";
      }

      this.dataTable.export(data);
    },
  },
  watch: {
    search: function () {
      this.filterModules();
    }
  }
};
</script>
