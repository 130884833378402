<template>
  <span :class="['badge', 'badge-sm', color]">
    {{ label }}
  </span>
</template>

<script>
import { tipoLancamentoReceita } from '@/utils/tipo-lancamentos';

export default {
  name: "BadgeTipoLancamento",
  props: {
    lancamento: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {}
  },
  computed: {
    label() {
      return tipoLancamentoReceita(this.lancamento);
    },
    color() {
      const label = this.label;

      switch (label) {
        case 'BAIXA TÍTULO':
          return 'badge-success';
        case 'JUROS/TAXAS':
          return 'badge-primary';
        default:
          return 'bg-gradient-warning';
      }
    }
  }
}
</script>
