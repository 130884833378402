<template>
    <argon-button type="submit" color="success" variant="gradient" :size="size" class="my-2">
        SALVAR
    </argon-button>
</template>
<script>
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    name: "SubmitButton",
    props: {
        size: {
            type: String,
            default: "sm",
        },
    },
    components: {
        ArgonButton,
    },
}
</script>
