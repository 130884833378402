<template>
	<span :class="classOperacao">
		{{ operacao.toUpperCase() }}
	</span>
</template>
<script>
export default {
	name: "BadgeOperacao",
	props: {
		operacao: {
			type: String,
			default: ""
		}
	},
	computed: {
		classOperacao() {
			let type = '';
			switch (this.operacao.toLowerCase()) {
				case 'upsell':
					type = 'success';
					break;
				case 'downsell':
					type = 'danger';
					break;
				case 'reajuste_anual':
					type = 'primary';
					break;
				default:
					type = 'default';
					break;
			}
			
			return `badge badge-sm badge-${type}`;
		}
	}
}
</script>
