<template>
    <span :class="['badge', `badge-${color}`, `badge-${size}`]">
        {{ label }}
    </span>
</template>
<script>
export default {
    name: "BadgeTipoComissao",
    props: {
        tipo: {
            type: String,
            default: 'mrr'
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    computed: {
        label() {
            const labels = {
                mrr: 'MRR',
                arr: 'ARR',
                consultoria: 'Consultoria',
                upsell: 'Upsell',
                upsell_consultoria: 'Upsell Consultoria',
            };

            return labels[this.tipo];
        },
        color() {
            const colors = {
                mrr: 'success',
                arr: 'info',
                consultoria: 'primary',
                upsell: 'secondary',
                upsell_consultoria: 'secondary',
            };

            return colors[this.tipo];
        }
    }
}
</script>