<template>
    <div class="progress progress-vendas" :title="`${percentage}%`">
        <div class="progress-bar" :class="percentageColor" role="progressbar" :aria-valuenow="percentage"
            aria-valuemin="0" aria-valuemax="100" :style="{
                width: percentage + '%'
            }">
        </div>
        <div class="label-percentual" :class="fontColor">
            {{ percentage }}%
        </div>
    </div>
</template>
<script>
export default {
    name: "ProgressBar",
    props: {
        percentage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        percentageColor() {
            const percentage = this.percentage;

            if (percentage >= 90) {
                return 'bg-success';
            } else if (percentage >= 50) {
                return 'bg-info';
            } else {
                return 'bg-danger';
            }
        },
        fontColor() {
            const percentage = this.percentage;

            if (percentage < 40) {
                return 'text-dark';
            } else {
                return 'text-white';
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.progress.progress-vendas {
    position: relative;
    height: 16px !important;
    font-size: 10px !important;
    font-weight: bold;
    text-align: center;

    .label-percentual {
        position: absolute;
        width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        line-height: 16px;
    }
}
</style>