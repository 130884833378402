<template>
  <span :class="['badge', `badge-${size}`, color]">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "BadgeTipoFaturamento",
  props: {
    tipoFaturamento: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
      tiposFaturamento: [
        'MRR',
        'ARR',
        'TRR',
        'SRR',
      ]
    }
  },
  computed: {
    label() {
      return this.tiposFaturamento[this.tipoFaturamento - 1];
    },
    color() {
      return this.tipoFaturamento === 1 ? 'badge-primary' : 'badge-secondary';
    }
  }
}
</script>
